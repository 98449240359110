import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'datasource-selection-modal',
  templateUrl: './datasource-selection-modal.component.html',
  styleUrls: ['./datasource-selection-modal.component.scss'],
})
export class DatasourceSelectionModalComponent {
  @Output() onAddConnection = new EventEmitter<string>();
  @Output() close = new EventEmitter();
  
  public contentSelector: string = '';

  _onAddConnection(datasourceType: string) {
    this.onAddConnection.emit(datasourceType);
  }

  _close() {
    this.close.emit();
  }
}
