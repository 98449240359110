<div>
  <div class="modal-header">
    <h5 class="title f-16" id="defaultModalLabel">Add Query</h5>
    <button type="button" class="close" aria-label="Close" (click)="onCloseHandler()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row clearfix">
      <div class="col-sm-12">
        <div class="form-group">
          <p class="f-14 mb-0">Select</p>
          <div class="form-line">
            <textarea
              id="inputExpandFilterFormula"
              rows="10"
              class="form-control no-resize"
              placeholder="Please input your select clause.&#10;Example:&#10;column_A, lowercase(column_B) as lowercaseOfColumnB, sum(column_C) as sumOfColumnC"
              [(ngModel)]="clauseA"
              (ngModelChange)="_onChange('clause_a', $event)"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="col-sm-12">
        <div class="form-group">
          <p class="f-14 mb-0">From DB</p>
          <div class="form-line">
            <textarea
              id="inputExpandFilterFormula"
              rows="10"
              class="form-control no-resize"
              placeholder="Please input your other clauses.&#10;Example:&#10;FROM order_items A&#10;JOIN products B ON A.product_id = B.product_id&#10;JOIN orders C ON A.order_id = C.order_id&#10;WHERE YEAR(C.order_date) = YEAR(NOW()) AND MONTH(C.order_date) = MONTH(NOW())&#10;GROUP BY B.product_name&#10;ORDER BY total DESC&#10;LIMIT 10&#10;"
              [(ngModel)]="clauseB"
              (ngModelChange)="_onChange('clause_b', $event)"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>