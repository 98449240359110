<div class="modal-body">
  <div class="row text-center justify-content-center">
    <div class="col-auto align-self-center">
      <div class="w_icon green" *ngIf="['Sukses', 'Success'].includes(title)">
        <i class="zmdi zmdi-check-circle"></i>
      </div>
      <div class="w_icon orange" *ngIf="['Peringatan', 'Warning'].includes(title)">
        <i class="zmdi zmdi-alert-circle-o"></i>
      </div>
      <div class="w_icon pink" *ngIf="['Gagal', 'Failed'].includes(title)">
        <i class="zmdi zmdi-alert-circle-o"></i>
      </div>
    </div>
    <div class="col-8 align-self-center">
      <h5 class="f-15 m-auto" [innerHtml]="msg"></h5>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    class="btn btn-info btn-outline btn-sm f-12 mx-auto mt-3"
    data-dismiss="modal"
    type="button"
    (click)="activeModal.dismiss('Cross click')"
  >
    OK
  </button>
</div>
