import { CmdUISetting } from './command.model';

type SlotName = 'INPUT1' | 'INPUT2' | 'OUTPUT1' | 'OUTPUT2' | null;
type SlotType = 'IN' | 'OUT';

interface Slot {
  name: SlotName;
  type: SlotType;
  IsMulti: boolean;
  isChecked: boolean;
}

const CmdSlots: { [key: string]: Slot[] } = {
  SEARCH: [
    { name: "OUTPUT1", IsMulti: false, type: 'OUT', isChecked: false }
  ],
  VIEW: [
    {name: "INPUT1", IsMulti: false, type: 'IN', isChecked: false},
    {name: "OUTPUT1", IsMulti: false, type: 'OUT', isChecked: false}
  ],
  CORRELATEREF: [
    { name: 'INPUT1', IsMulti: false, type: 'IN', isChecked: false },
    { name: 'INPUT2', IsMulti: false, type: 'IN', isChecked: false },
    { name: 'OUTPUT1', IsMulti: false, type: 'OUT', isChecked: false },
    { name: 'OUTPUT2', IsMulti: false, type: 'OUT', isChecked: false },
  ],
  MERGE: [
    { name: 'INPUT1', IsMulti: true, type: 'IN', isChecked: false },
    { name: 'OUTPUT1', IsMulti: false, type: 'OUT', isChecked: false },
  ],
  FILTER: [
    { name: 'INPUT1', IsMulti: false, type: 'IN', isChecked: false },
    { name: 'OUTPUT1', IsMulti: false, type: 'OUT', isChecked: false },
    { name: 'OUTPUT2', IsMulti: false, type: 'OUT', isChecked: false },
  ],
  CONFIG: [],
  COPY: [
    { name: 'INPUT1', IsMulti: false, type: 'IN', isChecked: false },
    { name: 'OUTPUT1', IsMulti: false, type: 'OUT', isChecked: false },
    { name: 'OUTPUT2', IsMulti: false, type: 'OUT', isChecked: false },
  ],
  DROP: [{ name: 'INPUT1', IsMulti: false, type: 'IN', isChecked: false }],
  STORE: [{ name: 'INPUT1', IsMulti: false, type: 'IN', isChecked: false }],
  SELECT: [
    { name: 'INPUT1', IsMulti: true, type: 'IN', isChecked: false },
    { name: 'OUTPUT1', IsMulti: false, type: 'OUT', isChecked: false },
    { name: 'OUTPUT2', IsMulti: false, type: 'OUT', isChecked: false },
  ],
  AGGREGATE: [
    { name: 'INPUT1', IsMulti: false, type: 'IN', isChecked: false },
    { name: 'OUTPUT1', IsMulti: false, type: 'OUT', isChecked: false },
  ],
  SELECTDB: [
    { name: 'OUTPUT1', IsMulti: false, type: 'OUT', isChecked: false },
  ],
  EXTRACT: [
    { name: 'INPUT1', IsMulti: false, type: 'IN', isChecked: false },
    { name: 'OUTPUT1', IsMulti: false, type: 'OUT', isChecked: false },
  ],
  SORT: [
    { name: 'INPUT1', IsMulti: false, type: 'IN', isChecked: false },
    { name: 'OUTPUT1', IsMulti: false, type: 'OUT', isChecked: false },
  ],
  LIMIT: [
    { name: 'INPUT1', IsMulti: false, type: 'IN', isChecked: false },
    { name: 'OUTPUT1', IsMulti: false, type: 'OUT', isChecked: false },
  ],
  DEDUP: [
    { name: 'INPUT1', IsMulti: false, type: 'IN', isChecked: false },
    { name: 'OUTPUT1', IsMulti: false, type: 'OUT', isChecked: false },
  ],
  IMPLODE: [
    { name: 'INPUT1', IsMulti: false, type: 'IN', isChecked: false },
    { name: 'OUTPUT1', IsMulti: false, type: 'OUT', isChecked: false },
  ],
  EXPLODE: [
    { name: 'INPUT1', IsMulti: false, type: 'IN', isChecked: false },
    { name: 'OUTPUT1', IsMulti: false, type: 'OUT', isChecked: false },
  ],
};

export { Slot, CmdSlots, SlotName, SlotType };
