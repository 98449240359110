import { Injectable } from '@angular/core';
import { TreeviewItem } from 'ngx-treeview';
import { ApiService } from '../../common/api.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/libs/store/states';
import { SetToastrMessage } from 'src/app/libs/store/actions/pds/dataprocessing.actions';
import { JsonService } from '../../common/json.service';
import {
  attachChildrenToItem,
  convertDatasourceItemsToTreeviewItems,
  convertDatasourceItemToTreeviewItem,
} from 'src/app/libs/helpers/data-processing.helper';
import { BehaviorSubject, lastValueFrom, catchError, of } from 'rxjs';
import { IDataSourceItemV2, PathObject } from 'src/app/libs/types/dataprocessing';
import {
  DATAPROCESSING_API_PATH,
  DATAPROCESSING_DATASOURCE_TYPE,
  ROOT_DIR,
  STATIC_DATABASE_CONNECTION,
  STATIC_HDFS,
  STATIC_ROOT_DIR,
} from 'src/app/libs/consts';
import { helperFindParentFile } from 'src/app/components/sidebars/dataprocessing/query-list/components/query-list.helper';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class DataProcessingDataSourceService {
  private _loading$ = new BehaviorSubject<boolean>(false);
  private _loadingChild$ = new BehaviorSubject<boolean>(false);

  private _currentItem: TreeviewItem;
  private _rootTreeview: TreeviewItem[];
  private _fileTreeview: TreeviewItem;
  private _hdfsTreeview: TreeviewItem;
  private _dbTreeview: TreeviewItem;
  private _typeAct: string;
  private _hdfsDb: string;
  private _path: PathObject;

  constructor(private apiService: ApiService, private store: Store<AppState>, private translate: TranslateService) {
    this.initialTreeview();
  }

  initialTreeview() {
    this._fileTreeview = new TreeviewItem(STATIC_ROOT_DIR);
    this._hdfsTreeview = new TreeviewItem(STATIC_HDFS);
    this._dbTreeview = new TreeviewItem(STATIC_DATABASE_CONNECTION);
  }

  resetTreeview() {
    this.initialTreeview();
    this._rootTreeview = undefined;
  }

  getLoadingSubject(): BehaviorSubject<boolean> {
    return this._loading$;
  }

  getLoadingChildSubject(): BehaviorSubject<boolean> {
    return this._loadingChild$;
  }

  setLoading(isLoading: boolean): void {
    this._loading$.next(isLoading);
  }

  setCurrentItem(currentItem: TreeviewItem): void {
    this._currentItem = currentItem;
  }

  setRootTreeview(rootTreeview: TreeviewItem[]): void {
    this._rootTreeview = rootTreeview;
  }

  setTypeAct(typeAct: string): void {
    this._typeAct = typeAct;
  }

  setHdfsDb(hdfsDb: string): void {
    this._hdfsDb = hdfsDb;
  }

  setPath(path: any): void {
    this._path = path;
  }

  getCurrentItem(): TreeviewItem {
    return this._currentItem;
  }

  getRootTreeview(): TreeviewItem[] {
    return this._rootTreeview;
  }

  getFileTreeview(): TreeviewItem {
    return this._fileTreeview;
  }

  getHdfsTreeview(): TreeviewItem {
    return this._hdfsTreeview;
  }

  getDbTreeview(): TreeviewItem {
    return this._dbTreeview;
  }

  getTypeAct(): string {
    return this._typeAct;
  }

  getHdfsDb(): string {
    return this._hdfsDb;
  }

  getPath(): PathObject {
    return this._path;
  }

  isRootTreeviewNotEmpty(): boolean {
    return this._rootTreeview && this._rootTreeview.length === 0;
  }

  private _selectTreeview(treeViewType: number): TreeviewItem {
    if (treeViewType === DATAPROCESSING_DATASOURCE_TYPE.HDFS) {
      return this._hdfsTreeview;
    }

    if (treeViewType === DATAPROCESSING_DATASOURCE_TYPE.DATABASE) {
      return this._dbTreeview;
    }

    return;
  }

  async loadChild(item: any): Promise<void> {
    this._loadingChild$.next(true);

    if (item.value.isConnection) {
      if (!item.value.location && item.value.type !== 'database') {
        let findIndex = this._hdfsTreeview.children.findIndex((obj) => obj?.value?.id === item.value.id);
        this.handleHadoopConnectionTest(item.value)
          .then((result) => {
            this._hdfsTreeview.children[findIndex].value['testConnection'] = 'success';
            this._hdfsDb = item.value.name;
            this.handleOnGetHDFSList(
              `${DATAPROCESSING_API_PATH.HDFS_CONNECTION}/${this._hdfsDb}/list`,
              {
                path: '/',
                term: '',
              },
              'child'
            );
          })
          .catch((error) => {
            this._hdfsTreeview.children[findIndex].value['testConnection'] = 'failed';
            this._loadingChild$.next(false);
            this._loading$.next(false);
          });
      }

      if (item.value.type === 'database' && !item.value.location && item.value.filetype !== 'schema' && item.value.filetype !== 'table') {
        await this.handleOnGetSchema(
          DATAPROCESSING_API_PATH.DB_SHOW_SCHEMA_TABLES,
          {
            name: item.value.name,
            key: item.value.key,
            owner: item.value.owner,
          },
        );
      }

      if (item.value.type === 'child' && item.value.location && item.value.filetype !== 'schema' && item.value.filetype === 'table') {
        await this.handleOnGetTable(
          DATAPROCESSING_API_PATH.DB_SHOW_COLUMN,
          {
            key: item.value.key,
            owner: item.value.owner,
            tableName: item.value.name,
            path: `${item.value.location}/${item.value.name}`,
            schemaName: item.value.schema
          },
        );
      }

      if (item.value.location && item.value.filetype !== 'schema' && item.value.filetype !== 'table') {
        await this.handleOnGetHDFSList(
          `${DATAPROCESSING_API_PATH.HDFS_CONNECTION}/${this._hdfsDb}/list`,
          {
            path: `${item.value.location}/${item.value.name}`,
            term: '',
          },
          'child'
        );
      }
    } else {
      await this.getDataSourceList(
        DATAPROCESSING_API_PATH.EXPLORER_LIST,
        item?.value?.isRoot
          ? ''
          : item.value.location === ROOT_DIR
          ? `${item.value.location}${item.value.name}`
          : `${item.value.location}/${item.value.name}`,
        '',
        'child'
      );
    }

    this._loadingChild$.next(false);
  }

  async handleHadoopConnectionTest(value) {
    return await new Promise((resolve, reject) => {
      this.apiService
        .post(`${DATAPROCESSING_API_PATH.HDFS_CONNECTION}/test`, { node_name: value.node_name, port: value.port })
        .subscribe({
          next: (res) => {
            this._toastr('info', this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.TCS'));
            resolve(res);
          },
          error: (err) => {
            this._toastr('error', `${this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.TCF')}: ${err.message}`);
            reject(err);
          },
        });
    });
  }

  async getDataSourceList(url?: string, root?: string, filter?: string, type?: string): Promise<void> {
    this._loading$.next(true);

    const result: ApiResult = await new Promise<ApiResult>((resolve, reject) => {
      this.apiService
        .gets(url ? url : DATAPROCESSING_API_PATH.EXPLORER_LIST, {
          path: root ? root : ROOT_DIR,
          term: filter ? filter : '',
        })
        .subscribe({
          next: (res) => resolve(res),
          error: (err) => {
            this._toastr('error', err.message);
            reject(err);
          },
        });
    });

    if (result?.response) {
      if (type === 'child') {
        // condition for refreshing item on child folder
        let item: TreeviewItem;
        // allow only for type delete, cut, copy and upload
        // inside the condition divide by action
        if (['delete', 'cut_file', 'copy', 'rename'].includes(this._typeAct)) {
          // allow only for type delete and upload
          if (['delete', 'rename'].includes(this._typeAct)) {
            // for folder/ file inside the folder a.k.a after first layer
            if (!this._currentItem.value.isRoot && this._currentItem.value.location !== ROOT_DIR) {
              item = helperFindParentFile(this._fileTreeview.children, this._currentItem.value.location);
              if (item) {
                this._typeAct = '';
                this._fileTreeview.children = attachChildrenToItem(this._fileTreeview.children, item, result?.response);
              }
            }
            //  for root folder/file a.k.a first layer
            if (this._currentItem.value.isRoot || this._currentItem.value.location === ROOT_DIR) {
              this._handleOnDataSourceForRoot(result?.response ? result?.response : []);
            }
          }
          // allow only for type cut dan copy
          if (['cut_file', 'copy'].includes(this._typeAct)) {
            // remove source file first
            if (this._typeAct === 'cut_file' && !this._currentItem.value.isRoot) {
              await this._handleOnMovingCutAction(this._path.location);
            }
            // add destination file
            // for folder/ file inside the folder a.k.a after first layer
            if (!this._currentItem.value.isRoot) {
              item = helperFindParentFile(
                this._fileTreeview.children,
                `${this._currentItem.value.location}/${this._currentItem.value.name}`
              );
            }
            //  for root folder/file a.k.a first layer
            if (this._currentItem.value.isRoot) {
              this._handleOnDataSourceForRoot(result?.response ? result?.response : []);
            }

            if (item) {
              this._fileTreeview.children = attachChildrenToItem(this._fileTreeview.children, item, result?.response);
            }
            this._typeAct = '';
          }
        } else {
          this._fileTreeview.children = attachChildrenToItem(
            this._fileTreeview.children,
            this._currentItem,
            result?.response
          );
        }

        this._loadingChild$.next(false);
        this._loading$.next(false);

        let rootTreeview = [this._fileTreeview];
        if (this._hdfsTreeview.children && this._hdfsTreeview.children.length > 0) {
          rootTreeview = [...rootTreeview, this._hdfsTreeview];
        }

        if (this._dbTreeview.children && this._dbTreeview.children.length > 0) {
          rootTreeview = [...rootTreeview, this._dbTreeview];
        }

        this._rootTreeview = rootTreeview;
      } else {
        this._handleOnDataSourceForRoot(result?.response ? result?.response : [], filter);
        if (!['add_folder', 'upload', 'delete_file'].includes(type)) {
          if (!filter) {
            await this.getConnectionList(
              DATAPROCESSING_API_PATH.HDFS_CONNECTION,
              {},
              DATAPROCESSING_DATASOURCE_TYPE.HDFS
            );
            await this.getConnectionList(
              DATAPROCESSING_API_PATH.DB_CONNECTION,
              { type: 'all' },
              DATAPROCESSING_DATASOURCE_TYPE.DATABASE
            );
          }
        }
      }
    }

    this._loading$.next(false);
  }

  private async _handleOnMovingCutAction(root): Promise<any> {
    let item: TreeviewItem;
    const result: ApiResult = await new Promise<ApiResult>((resolve, reject) => {
      this.apiService.gets(DATAPROCESSING_API_PATH.EXPLORER_LIST, { path: root ? root : ROOT_DIR }).subscribe({
        next: (res) => resolve(res),
        error: (err) => {
          this._toastr('error', err.message);
          reject(err);
        },
      });
    });
    if (result?.response) {
      if (root === ROOT_DIR) {
        this._handleOnDataSourceForRoot(result?.response ? result?.response : []);
      } else {
        item = helperFindParentFile(this._fileTreeview.children, this._path.location);
        if (item) {
          this._fileTreeview.children = attachChildrenToItem(this._fileTreeview.children, item, result?.response);
        }
      }
    }
  }

  private _handleOnDataSourceForRoot(result, filter?): any {
    this._fileTreeview.children = convertDatasourceItemsToTreeviewItems(
      result as IDataSourceItemV2[],
      this._fileTreeview.children as TreeviewItem[]
    );
    this._typeAct = '';
    let rootTreeview = [];

    if (this._fileTreeview.children && this._fileTreeview.children.length > 0) {
      rootTreeview = [...rootTreeview, this._fileTreeview];
    }

    if (!filter) {
      if (this._hdfsTreeview.children && this._hdfsTreeview.children.length > 0) {
        rootTreeview = [...rootTreeview, this._hdfsTreeview];
      }

      if (this._dbTreeview.children && this._dbTreeview.children.length > 0) {
        rootTreeview = [...rootTreeview, this._dbTreeview];
      }
    }

    this._rootTreeview = rootTreeview;
  }

  async handleOnGetHDFSList(url: string, data: any, type?: string): Promise<any> {
    this._loading$.next(true);

    const result: ApiResult = await new Promise((resolve) => {
      this.apiService.gets(url, data).subscribe({
        next: (res) => {
          resolve(res);
        },
        error: (err) => {
          this.store.dispatch(
            SetToastrMessage({
              toastrMessage: {
                toastrType: 'error',
                message: err.message || this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.PF'),
              },
            })
          );

          this._loadingChild$.next(false);
          this._loading$.next(false);
        },
      });
    });

    if (result && (result.status === 'success' || result.status === 'ok')) {
      if (type === 'child') {
        this.connectionForRoot(result, DATAPROCESSING_DATASOURCE_TYPE.HDFS);
      } else {
        const hdfsDirectoryList = result.response.map((item: any) => ({
          ...item,
          isDir: true,
          isConnection: true,
        }));

        this._hdfsTreeview.children = convertDatasourceItemsToTreeviewItems(
          hdfsDirectoryList as IDataSourceItemV2[],
          this._hdfsTreeview.children as TreeviewItem[]
        );

        if (this._hdfsTreeview.children && this._hdfsTreeview.children[0]) {
          let rootTreeview = [this._fileTreeview];
          if (this._hdfsTreeview.children && this._hdfsTreeview.children.length > 0) {
            rootTreeview = [...rootTreeview, this._hdfsTreeview];
          }

          if (this._dbTreeview.children && this._dbTreeview.children.length > 0) {
            rootTreeview = [...rootTreeview, this._dbTreeview];
          }
          this._rootTreeview = rootTreeview;
        }
      }
    }

    this._loadingChild$.next(false);
    this._loading$.next(false);
  }

  async handleOnGetSchema(url: string, data: any): Promise<any> {
    this._loading$.next(true);
    let findIndex = this._dbTreeview.children.findIndex((obj) => obj?.value?.name === data.name);
    const result: ApiResult = await new Promise((resolve) => {
      this.apiService.gets(url, data).subscribe({
        next: (res) => {
          this._dbTreeview.children[findIndex].value['testConnection'] = 'success';
          resolve(res);
        },
        error: (err) => {
          this._dbTreeview.children[findIndex].value['testConnection'] = 'failed';
          this.store.dispatch(
            SetToastrMessage({
              toastrMessage: {
                toastrType: 'error',
                message: err.message || this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.PF'),
              },
            })
          );

          this._loadingChild$.next(false);
          this._loading$.next(false);
        },
      });
    });

    if (result && (result.status === 'success' || result.status === 'ok')) {
      let schemaTreeviewItems = [];
      result.response?.forEach((el) => {
        const schemaTreeviewItem = convertDatasourceItemToTreeviewItem({
          id: el?.schema,
          name: el?.schema,
          location: '/',
          isDir: true,
          filetype: 'schema',
          size: '0.00 kB',
          key: data.key,
          owner: data.owner,
          isConnection: true,
          connectionName: this._currentItem.value.connectionName || this._currentItem.value.name,
          type: 'child',
          isSchema: true,    
        });
        schemaTreeviewItem.collapsed = true;

        const schemaTablesTreeviewItems = convertDatasourceItemsToTreeviewItems(el?.tables?.map((tableName: string) => {
          return {
            id: tableName,
            name: tableName,
            location: '/',
            isDir: true,
            filetype: 'table',
            size: '0.00 kB',
            key: data.key,
            owner: data.owner,          
            isConnection: true,
            connectionName: this._currentItem.value.connectionName || this._currentItem.value.name,
            type: 'child',
            isTable: true,
            schema: el?.schema,    
          };
        }));
        schemaTreeviewItem.children = schemaTablesTreeviewItems;
        
        schemaTreeviewItems.push(schemaTreeviewItem);
      });

      const selectedTreeview = this._selectTreeview(DATAPROCESSING_DATASOURCE_TYPE.DATABASE);
      selectedTreeview.children.forEach((treeItem: any) => {
        if (treeItem?.value?.key === this._currentItem.value.key && treeItem?.value?.name === this._currentItem.value.name) {
          treeItem.children = schemaTreeviewItems;
        }
      });
    }

    this._loadingChild$.next(false);
    this._loading$.next(false);
  }

  async handleOnGetTable(url: string, data: any): Promise<any> {
    this._loading$.next(true);

    const result: ApiResult = await new Promise((resolve) => {
      this.apiService.gets(url, data).subscribe({
        next: (res) => {
          resolve(res);
        },
        error: (err) => {
          this.store.dispatch(
            SetToastrMessage({
              toastrMessage: {
                toastrType: 'error',
                message: err.message || this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.PF'),
              },
            })
          );

          this._loadingChild$.next(false);
          this._loading$.next(false);
        },
      });
    });

    if (result && (result.status === 'success' || result.status === 'ok')) {
      let response = [];

      result.response?.forEach((el) => {
        let obj = {
          id: el.column_name,
          name: `${el.column_name} (${el.data_type})`,
          data_type: el.data_type,
          location: `//${el.column_name}`,
          isDir: false,
          filetype: 'column',
          size: '0.00 kB',
          key: data.key,
          owner: data.owner,
          tableName: data.tableName,
          schema: data.schemaName,
        };
        response.push(obj);
      });

      this.connectionForColumn(
        {
          response: response,
        },
        DATAPROCESSING_DATASOURCE_TYPE.DATABASE
      );
    }

    this._loadingChild$.next(false);
    this._loading$.next(false);
  }

  connectionForColumn(result: any, treeViewType: number): any {
    const connectionDirectoryList = result.response.map((item: any) => ({
      ...item,
      isConnection: true,
      connectionName: this._currentItem.value.connectionName || this._currentItem.value.name,
      type: 'child',
      isColumn: true,
    }));

    const selectedTreeview = this._selectTreeview(treeViewType);

    selectedTreeview.children = attachChildrenToItem(
      selectedTreeview.children,
      this._currentItem,
      connectionDirectoryList
    );
    this._loadingChild$.next(false);
    this._loading$.next(false);
  }

  connectionForRoot(result: any, treeViewType: number): any {
    const connectionDirectoryList = result.response.map((item: any) => ({
      ...item,
      isConnection: true,
      connectionName: this._currentItem.value.connectionName || this._currentItem.value.name,
      type: 'child',
      isHdfsChild: true,
    }));

    const selectedTreeview = this._selectTreeview(treeViewType);
    selectedTreeview.children = attachChildrenToItem(
      selectedTreeview.children,
      this._currentItem,
      connectionDirectoryList
    );
    this._loadingChild$.next(false);
    this._loading$.next(false);
  }

  async getConnectionList(url: string, data: any, treeViewType: number, type?: string): Promise<any> {
    this._loading$.next(true);

    const result: ApiResult = await new Promise((resolve, reject) => {
      this.apiService.gets(url, data).subscribe({
        next: (res) => resolve(res),
        error: (err) => {
          this.store.dispatch(
            SetToastrMessage({
              toastrMessage: {
                toastrType: 'error',
                message: err.message || this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.PF'),
              },
            })
          );

          reject(err);
        },
      });
    });

    if (result?.response) {
      if (type === 'child') {
        this.connectionForRoot(result?.response, treeViewType);
      } else {
        const hdfsDirectoryList = result?.response.map((item: any) => ({
          ...item,
          isDir: true,
          isConnection: true,
          type: url.includes('dbconfig') ? 'database' : url.includes('hdfs') ? 'hdfs' : '',
        }));

        const selectedTreeview = this._selectTreeview(treeViewType);
        selectedTreeview.children = convertDatasourceItemsToTreeviewItems(
          hdfsDirectoryList as IDataSourceItemV2[],
          selectedTreeview.children as TreeviewItem[]
        );

        if (selectedTreeview.children && selectedTreeview.children[0]) {
          this._rootTreeview = [this._fileTreeview];
          if (this._hdfsTreeview.children && this._hdfsTreeview.children.length > 0) {
            this._rootTreeview = [...this._rootTreeview, this._hdfsTreeview];
          }

          if (this._dbTreeview.children && this._dbTreeview.children.length > 0) {
            this._rootTreeview = [...this._rootTreeview, this._dbTreeview];
          }
        }
      }
    }

    this._loadingChild$.next(false);
    this._loading$.next(false);
  }

  async copyFileFolder(treeviewItem: TreeviewItem): Promise<void> {
    await new Promise((resolve, reject) => {
      this.apiService
        .post(`/api/explorer/${this.getPath()?.type === 'copy_file' ? 'cp' : 'mv'}`, {
          src: [`${this.getPath()?.path}`],
          dst: [
            treeviewItem.value.name
              ? `${treeviewItem.value.location}/${treeviewItem.value.name}/${this.getPath()?.name}`
              : `${treeviewItem.value.location}/${this.getPath()?.name}`,
          ],
        })
        .subscribe({
          next: (res) => {
            this._toastr('info', this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.FDCCS'));
            resolve(res);
          },
          error: (err) => {
            this._toastr('error', err.message);
            reject(err);
          },
        });
    });
  }

  async createFolder(params: any): Promise<void> {
    await new Promise((resolve, reject) => {
      this.apiService.post(DATAPROCESSING_API_PATH.EXPLORER_DIRECTORY, params).subscribe({
        next: (res) => {
          this._toastr('info', this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.DCS'));
          resolve(res);
        },
        error: (err) => {
          this._toastr('error', err.message);
          reject(err);
        },
      });
    });
  }

  async renameFileFolder(params: any): Promise<void> {
    await new Promise((resolve, reject) => {
      this.apiService.post(DATAPROCESSING_API_PATH.EXPLORER_MOVE, params).subscribe({
        next: (res) => {
          this._toastr('info', this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.FFRS'));
          resolve(res);
        },
        error: (err) => {
          this._toastr('error', err.message);
          reject(err);
        },
      });
    });
  }

  deleteFiles(nodes: any , targetId: string): void {
    for (const node of nodes) {
      if (node.value.id === targetId) {
        const idx = nodes.findIndex(el => el.value.id === targetId);
        nodes.splice(idx, 1)
        break; 
      }

      if (node.internalChildren) {
        this.deleteFiles(node.internalChildren, targetId); // Recursive child nodes
      }
    }
  }

  async deleteFileFolder(params: any): Promise<void> {
    await new Promise((resolve, reject) => {
      this.apiService.post(DATAPROCESSING_API_PATH.EXPLORER_REMOVE, {paths: params.paths}).subscribe({
        next: async (res) => {
          this.deleteFiles(this._fileTreeview.children, params.id);
          this._toastr('info', this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.FFDS'));
          resolve(res);
        },
        error: (err) => {
          this._toastr('error', err.message || this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.PF'));
          reject(err);
        },
      });
    });
  }

  async deleteConnection(id: string): Promise<void> {
    await new Promise((resolve, reject) => {
      this.apiService.delete(`${DATAPROCESSING_API_PATH.DB_CONNNECTION_DELETE}/${id}`).subscribe({
        next: async (res) => {
          resolve(res);
          let findIndex = this._dbTreeview.children.findIndex((obj) => obj.value.key === id);
          this._dbTreeview.children.splice(findIndex, 1);
          this._toastr('info', this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.PC'));
        },
        error: (err) => {
          this._toastr('error', err.message || this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.PF'));
          reject(err);
        },
      });
    });
  }

  uploadFile(params: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiService.post(DATAPROCESSING_API_PATH.EXPLORER_UPLOAD, params).subscribe({
        next: async (res) => {
          this._toastr('info', this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.FUS'));
          resolve(res);
        },
        error: (err) => {
          this._toastr('error', err.message || this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.PF'));
          reject(err);
        },
      });
    });
  }

  private _toastr(toastrType: string, message: string): void {
    this.store.dispatch(
      SetToastrMessage({
        toastrMessage: {
          toastrType,
          message,
        },
      })
    );
  }

  async deleteHdfsConnection(id: string): Promise<void> {
    await new Promise((resolve, reject) => {
      this.apiService.delete(`${DATAPROCESSING_API_PATH.HDFS_CONNECTION}/${id}`).subscribe({
        next: async (res) => {
          resolve(res);
          let findIndex = this._hdfsTreeview.children.findIndex((obj) => obj.value.id === id);
          this._hdfsTreeview.children.splice(findIndex, 1);
          this._toastr('info', this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.PC'));
        },
        error: (err) => {
          this._toastr('error', err.message || this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.PF'));
          reject(err);
        },
      });
    });
  }

  async handleOnHDFSForRoot(result) {
    let findIndex = this._hdfsTreeview.children.findIndex((obj) => obj?.value?.id === result?.id);
    this._hdfsTreeview.children[findIndex].value = { ...this._hdfsTreeview.children[findIndex].value, ...result };
    let hdfsValue = this._hdfsTreeview.children.map((item) => item.value);

    this._hdfsTreeview.children = convertDatasourceItemsToTreeviewItems(
      hdfsValue as IDataSourceItemV2[],
      this._hdfsTreeview.children as TreeviewItem[]
    );
    this._typeAct = '';

    let rootTreeview = [this._fileTreeview];
    if (this._hdfsTreeview.children && this._hdfsTreeview.children.length > 0) {
      rootTreeview = [...rootTreeview, this._hdfsTreeview];
    }

    if (this._dbTreeview.children && this._dbTreeview.children.length > 0) {
      rootTreeview = [...rootTreeview, this._dbTreeview];
    }

    this._rootTreeview = rootTreeview;
  }

  insertHdfsList(result: any) {
    const hdfsDirectoryList = [
      {
        availability: false,
        id: result.id,
        isConnection: true,
        isDir: true,
        name: result.name,
        node_name: result.node_name,
        port: result.port,
        timestamp: result.created_date,
        token_string: result.token_string,
        type: 'hdfs',
      },
    ];

    let newHdfs = convertDatasourceItemsToTreeviewItems(
      hdfsDirectoryList as IDataSourceItemV2[],
      this._hdfsTreeview.children as TreeviewItem[]
    );

    this._hdfsTreeview.children = [...this._hdfsTreeview.children, ...newHdfs];
  }
}
