import { Cmd, CmdBasicInfoIface, CmdIface, CmdTypes, IntoLabelGenerator } from "./command.model";
import { ChildConnector, ChildConnectorIface, ConnectionIface, ParentConnector, ParentConnectorIface } from "./connection.model";
import { CmdSlots, Slot, SlotName } from "./query.model";

type SelectFromType = "CSV" | "GZ" | "TABLE";
type SelectJoinType = "FULL OUTER JOIN" | "LEFT OUTER JOIN" | "RIGHT OUTER JOIN" | "INNER JOIN";

interface FromTableObj {
    tables: string
}

interface FromCSVObj {
    path: string,
    alias?: string,
}

interface FromGZObj {
    path: string,
    alias?: string,
}

interface FromFileObj {
    path: string,
}

interface JoinObj {
    type: SelectJoinType,
    target: string,
    expressions: string,
}

const cmdType: CmdTypes = 'SELECT';

interface SelectCmdProps {
    selectors: string,
    from_type: SelectFromType,
    from_table?: FromTableObj,
    from_csv?: FromCSVObj,
    from_gz?: FromGZObj,
    from_file?: FromFileObj,
    join?: JoinObj[],
    where?: string,
    order_by?: string,
    has_limit: boolean,
    has_offset: boolean,
    limit?: Number,
    offset?: Number,
    group_by?: string,
    into: string,
}

interface SelectCmdState {
    node_type: CmdTypes;
    props: SelectCmdProps,

    fromSrcMap: { [key: string]: Number },
    prevFromTable: boolean;
    prevWhere: boolean;
}

class SelectCmd extends Cmd implements CmdIface {
    state: SelectCmdState;
    fromConnectors: { [key: string]: ChildConnectorIface };
    intoConnector: { [key: string]: ParentConnectorIface };
    branchConnector: ParentConnectorIface;
    public override Slots: { [key: string]: Slot } = {};
    private baseSlots: { [key: string]: Slot } = {};

    constructor(state?: SelectCmdState) {
        super(cmdType);

        for (let i = 0; i < CmdSlots[this.node_type]?.length; i++) {
          this.baseSlots[CmdSlots[this.node_type][i].name] = { ...CmdSlots[this.node_type][i] };
        }

        this.fromConnectors = {};
        this.intoConnector = {};

        this.state = {
            node_type: cmdType,
            props: {
                selectors: "*",
                from_type: "TABLE",
                from_table: {
                    tables: ""
                },
                has_limit: false,
                has_offset: false,
                into: IntoLabelGenerator(cmdType),
            },
            fromSrcMap: {},
            prevFromTable: false,
            prevWhere: false,
        }
        super.SetNewProp(this.state.props);
        this.changeConnState();

        if (state) {
            this.state = state;
            this.state.prevFromTable = true;
            this.state.prevWhere = false;
            super.SetNewProp(this.state.props);
            this.changeConnState();
        }
    }

    private newBranchConn(slot: SlotName): ParentConnector {
        return new ParentConnector(
            slot,
            "",
            () => this.state.props['into'],
            (newLabel: string) => {
                this.state.props.into = newLabel;
            },
            () => {
                this.Slots[slot].isChecked = true;
            },
            () => {
                this.Slots[slot].isChecked = false;
            },
            this
        );
    }

    private changeConnState() {
        if (this.state.props.from_type == "TABLE") {
            if (!this.state.prevFromTable) {
                this.Slots["INPUT1"] = { ...this.baseSlots["INPUT1"] };
            
                for (let key in this.intoConnector) {
                    this.intoConnector[key].Disconnect();
                    delete this.intoConnector[key];
                }

                this.Slots["OUTPUT1"] = { ...this.baseSlots["OUTPUT1"] };
                if (this.state.props.where && this.state.props.where != "") {
                    this.Slots["OUTPUT1"].IsMulti = true;
                    this.branchConnector = this.newBranchConn("OUTPUT2");
                    this.Slots["OUTPUT2"] = { ...this.baseSlots["OUTPUT2"] };
                }

                this.state.prevFromTable = true;
            } else if (this.state.prevWhere != (this.state.props.where && this.state.props.where != "")) {
                for (let key in this.intoConnector) {
                    this.intoConnector[key].Disconnect();
                    delete this.intoConnector[key];
                }
                this.Slots["OUTPUT1"] = { ...this.baseSlots["OUTPUT1"] };
                if (this.state.prevWhere) {
                    if (this.branchConnector && this.branchConnector.IsConnected()) {
                        this.branchConnector.Disconnect();
                        this.branchConnector = null;
                    }
                    delete this.Slots["OUTPUT2"];
                } else {
                    this.Slots["OUTPUT1"].IsMulti = true;
                    this.branchConnector = this.newBranchConn("OUTPUT2");
                    this.Slots["OUTPUT2"] = { ...this.baseSlots["OUTPUT2"] };
                }

                this.state.prevWhere = !this.state.prevWhere;
            }
        } else if (this.state.props.from_type) {
            if (this.state.prevFromTable) {
                delete this.Slots["INPUT1"]; 
                for (let key in this.intoConnector) {
                    if (this.intoConnector[key].IsConnected()) {
                        this.intoConnector[key].Disconnect();
                        delete this.intoConnector[key];
                    }
                }
                for (let key in this.fromConnectors) {
                    if (this.fromConnectors[key].IsConnected()) {
                        this.fromConnectors[key].Disconnect();
                        delete this.fromConnectors[key];
                        delete this.state?.fromSrcMap[key];
                    }
                }

                this.Slots["OUTPUT1"] = { ...this.baseSlots["OUTPUT1"] };
                if (this.state.props.where && this.state.props.where != "") {
                    this.branchConnector = this.newBranchConn("OUTPUT2");
                    this.Slots["OUTPUT2"] = { ...this.baseSlots["OUTPUT2"] };
                } else {
                    if (this.branchConnector && this.branchConnector.IsConnected()) {
                        this.branchConnector.Disconnect();
                        this.branchConnector = null;
                    }
                    delete this.Slots["OUTPUT2"];

                }
                this.state.prevFromTable = false;
            } else if (this.state.prevWhere != (this.state.props.where && this.state.props.where != "")) {
                for (let key in this.intoConnector) {
                    this.intoConnector[key].Disconnect();
                    delete this.intoConnector[key];
                }
                this.Slots["OUTPUT1"] = { ...this.baseSlots["OUTPUT1"] };
                if (this.state.prevWhere === true && !this.state.props.where && this.state.props.where === "") {
                    if (this.branchConnector && this.branchConnector.IsConnected()) {
                        this.branchConnector.Disconnect();
                        this.branchConnector = null;
                    }
                    delete this.Slots["OUTPUT2"];
                } else if (["GZ", "CSV"].includes(this.state.props.from_type)) {
                    if (this.branchConnector && this.branchConnector.IsConnected()) {
                        this.branchConnector.Disconnect();
                        this.branchConnector = null;
                    }
                    delete this.Slots["INPUT1"];
                    if (this.state.props.where && !this.state.prevWhere) {
                        this.branchConnector = this.newBranchConn("OUTPUT2");
                        this.Slots["OUTPUT2"] = { ...this.baseSlots["OUTPUT2"] };
                    }
                } else {
                    this.branchConnector = this.newBranchConn("OUTPUT2");
                    this.Slots["OUTPUT2"] = { ...this.baseSlots["OUTPUT2"] };
                }

                this.state.prevWhere = !this.state.prevWhere;
            }
        }
    }

    getFromConnectorLabels(): string[] {
        let labels: string[] = [];
        for (let label in this.fromConnectors) {
            labels.push(label);
        }
        return labels;
    }

    ChangeWhere(where: string): void {
        this.state.props.where = where;

        this.changeConnState();
    }

    ChangeFromType(newType: SelectFromType) {
        this.state.props.from_type = newType;
        switch (newType) {
            case "TABLE": {
                this.state.props.from_table = {
                    tables: ""
                }
                delete this.state.props.from_csv;
                delete this.state.props.from_gz;
                break
            }
            case "CSV": {
                this.state.props.from_csv = {
                    path: "",
                    alias: "",
                }
                delete this.state.props.from_table;
                delete this.state.props.from_gz;
                break
            }
            case "GZ": {
                this.state.props.from_gz = {
                    path: "",
                    alias: "",
                }
                delete this.state.props.from_csv;
                delete this.state.props.from_table;
                break
            }
        }

        this.changeConnState();
    }

    SetIntoLabel(newLabel: string) {
        if (this.branchConnector && this.branchConnector.IsConnected()) {
            this.branchConnector.SetIntoLabel(newLabel);
        } else {
            this.state.props.into = newLabel;
        }
    }

    GetOutConnector(name: SlotName, label?: string): ParentConnectorIface {
        if (name === 'OUTPUT2') {
            return this.branchConnector;
        }

        if (name === 'OUTPUT1') {
            if (this.state.props['from_type'] === 'TABLE') {
                if (!this.state.props['where'] || this.state.props['where'] === '') {
                    if (this.state.props['into'] in this.intoConnector) {
                        return this.intoConnector[this.state.props['into']];
                      }
                      
                    const conn = new ParentConnector(
                        name,
                        this.state.props['into'],
                        () => this.state.props['into'],
                        (newLabel: string) => {
                            this.state.props.into = newLabel;
                        },
                        () => {
                            this.Slots["OUTPUT1"].isChecked = true;
                        },
                        () => {
                            this.Slots["OUTPUT1"].isChecked = false;
                        },
                        this
                    );

                    this.intoConnector[this.state.props['into']] = conn;
                    
                    return conn;
                }
                if (label in this.state.fromSrcMap) {
                    if (label in this.intoConnector) {
                      return this.intoConnector[label];
                    }

                    const conn = new ParentConnector(
                        name,
                        label,
                        () => label,
                        (newLabel: string) => {
                            this.state.props.into = newLabel;
                        },
                        () => {
                            this.Slots["OUTPUT1"].isChecked = true;
                        },
                        () => {
                            this.Slots["OUTPUT1"].isChecked = false;
                        },
                        this
                    );

                    this.intoConnector[label] = conn;
                    
                    return conn;
                }
            } else {
                if (this.state.props['where']) {
                    const alias = this.state.props[`from_${this.state.props['from_type'].toLocaleLowerCase()}`]['alias'];
                    if (alias in this.intoConnector) {
                        return this.intoConnector[alias];
                    }
                    
                    const conn = new ParentConnector(
                        name,
                        alias,
                        () => alias,
                        (newLabel: string) => {
                            this.state.props.into = newLabel;
                        },
                        () => {
                            this.Slots["OUTPUT1"].isChecked = true;
                        },
                        () => {
                            this.Slots["OUTPUT1"].isChecked = false;
                        },
                        this
                    );
              
                    this.intoConnector[alias] = conn;

                    return conn;
                } else {
                    if (this.state.props['into'] in this.intoConnector) {
                        return this.intoConnector[this.state.props['into']];
                    }

                    const conn = new ParentConnector(
                        name,
                        this.state.props['into'],
                        () => this.state.props['into'],
                        (newLabel: string) => {
                            this.state.props.into = newLabel;
                        },
                        () => {
                            this.Slots["OUTPUT1"].isChecked = true;
                        },
                        () => {
                            this.Slots["OUTPUT1"].isChecked = false;
                        },
                        this
                    );
                
                    this.intoConnector[this.state.props['into']] = conn;

                    return conn;
                }
            }
        }

        return null;
    }

    GetInConnector(name: SlotName, label?: string): ChildConnectorIface {
        if (this.state.props.from_type != "TABLE") {
            return null;
        }

        if (name === 'INPUT1') {
            if (label in this.fromConnectors) {
                return this.fromConnectors[label];
            }

            const conn = new fromConnector(
                label,
                (parentLabel: string) => {
                    this.state.fromSrcMap[parentLabel] = 1;
                },
                () => {
                },
                (parentLabel: string) => {
                },
                this,
                (label: string, connector: ChildConnectorIface) => {
                    this.fromConnectors[label] = connector;
                    this.state.props.from_table.tables = Array.from(Object.keys(this.state.fromSrcMap)).join(", ");
                    if (this.state.props.from_table.tables) {
                      this.Slots["INPUT1"].isChecked = true;
                    }
                },
                (label: string) => {
                    delete this.state.fromSrcMap[label];
                    delete this.fromConnectors[label];
                    if (this.state.props.from_type === 'TABLE') {
                        this.state.props.from_table.tables = Array.from(Object.keys(this.state.fromSrcMap)).join(', ');
                    }

                    if (!this.state.props?.from_table?.tables && this.state.props.from_type === 'TABLE') {
                        this.Slots['INPUT1'].isChecked = false;
                    }
                },
                (prevLabel: string, newLabel: string) => {
                    delete this.state.fromSrcMap[prevLabel];
                    const conn = this.fromConnectors[prevLabel];
                    delete this.fromConnectors[prevLabel];

                    this.state.fromSrcMap[newLabel] = 1;
                    this.fromConnectors[newLabel] = conn;

                    if (prevLabel in this.intoConnector) {
                        const intoConn = this.intoConnector[prevLabel];
                        delete this.intoConnector[prevLabel];
                        this.intoConnector[newLabel] = intoConn;
                    }
                    this.state.props.from_table.tables = Array.from(Object.keys(this.state.fromSrcMap)).join(", ");
                },
            );

            return conn;
        }
    }

    OnCmdDelete(): void {
        for (let k in this.fromConnectors) {
            if (this.fromConnectors[k].IsConnected()) {
                this.fromConnectors[k].Disconnect();
            }
        }

        for (let k in this.intoConnector) {
            if (this.intoConnector[k].IsConnected()) {
                this.intoConnector[k].Disconnect();
            }
        }

        if (this.branchConnector && this.branchConnector.IsConnected()) {
            this.branchConnector.Disconnect();
        }
    }
}

class fromConnector extends ChildConnector implements ChildConnectorIface {
    private onConnectedCB2: (label: string, connector: ChildConnectorIface) => void
    private onDisconnectedCB2: (label: string) => void
    private onSetSourcelabelCB2: (prevLabel: string, newLabel: string) => void
    constructor(
        label: string,
        onConnectedCB: (parentLabel: string) => void,
        onDisconnectedCB: () => void,
        onSetSourcelabelCB: (parentLabel: string) => void,
        basicInfo: CmdBasicInfoIface,
        onConnectedCB2: (label: string, connector: ChildConnectorIface) => void,
        onDisconnectedCB2: (label: string) => void,
        onSetSourcelabelCB2: (prevLabel: string, newLabel: string) => void,
    ) {
        super("INPUT1", label, onConnectedCB, onDisconnectedCB, onSetSourcelabelCB, basicInfo);
        this.onConnectedCB2 = onConnectedCB2;
        this.onDisconnectedCB2 = onDisconnectedCB2;
        this.onSetSourcelabelCB2 = onSetSourcelabelCB2;
    }

    override OnConnected(getParentIntoLabelCB: () => string, conn: ConnectionIface) {
        super.OnConnected(getParentIntoLabelCB, conn);
        this.onConnectedCB2(getParentIntoLabelCB(), this);
        this.label = getParentIntoLabelCB();
    }

    override OnDisconnect(): void {
        this.onDisconnectedCB2(this.label);
        super.OnDisconnect();
    }

    override SetLabelByParent(newLabel: string): void {
        this.onSetSourcelabelCB2(this.label, newLabel);
        this.label = newLabel;
        super.SetLabelByParent(newLabel);
    }
}

export {
    SelectFromType,
    SelectJoinType,
    FromCSVObj,
    FromGZObj,
    FromFileObj,
    FromTableObj,
    SelectCmdState,
    SelectCmd,
    fromConnector,
}