import { DataVisualizationState } from 'src/app/libs/store/states/datavisualization.state';
import { createReducer, on } from '@ngrx/store';
import {
  SetExtraFilter,
  setSelectedValueChart,
  setApplicationById,
  SetActiveChart,
} from 'src/app/libs/store/actions/pds/datavisualization/datavisualization.actions';

export const initialState: DataVisualizationState = {
  dashboardList: null,
  dashboardWithLimitList: null,
  extraFilter: null,
  selectedValueChart: null,
  setApplicationById: null,
  applicationWithLimitList: null,
  activeChart: null,
};
export const dataVisualizationReducer = createReducer(
  initialState,
  on(SetExtraFilter, (state, result) => ({ ...state, extraFilter: result.extraFilter })),
  on(setSelectedValueChart, (state, result) => ({ ...state, selectedValueChart: result.item })),
  on(setApplicationById, (state, result) => ({ ...state, setApplicationById: result.item })),
  on(SetActiveChart, (state, result) => ({ ...state, activeChart: result.activeChart }))
);
