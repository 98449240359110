import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DataVisualizationState } from 'src/app/libs/store/states/datavisualization.state';

const dataVisualizationSelector = createFeatureSelector<DataVisualizationState>('datavisualization');
export const dashboardListSelector = createSelector(
  dataVisualizationSelector,
  (datavisualization) => datavisualization.dashboardList
);

export const extraFilterSelector = createSelector(
  dataVisualizationSelector,
  (datavisualization) => datavisualization.extraFilter
);

export const selectedValueChartSelector = createSelector(
  dataVisualizationSelector,
  (datavisualization) => datavisualization.selectedValueChart
);

export const setApplicationByIdSelector = createSelector(
  dataVisualizationSelector,
  (datavisualization) => datavisualization?.setApplicationById
);

export const activeChartSelector = createSelector(
  dataVisualizationSelector,
  (datavisualization) => datavisualization.activeChart
);
