import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SettingService {
  private _activeWorkingSessionStatusSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {}

  updateActiveWorkingSessionStatusSubject(status: boolean): void {
    this._activeWorkingSessionStatusSubject$.next(status);
  }

  getActiveWorkingSessionStatus(): boolean {
    return this._activeWorkingSessionStatusSubject$.getValue();
  }
}
