import { TreeviewItem, TreeItem } from 'ngx-treeview';
import { Explorer, ExplorerResponseList, Item } from './explorer';
import { RootType } from '../app/libs/consts';

export class TreeViewItemPaques extends TreeviewItem {
  isLoaded: boolean;
  parent: TreeViewItemPaques;
  override children: TreeViewItemPaques[];
  constructor(explorerItem: Item) {
    super({text: explorerItem.name, value: explorerItem});
    this.collapsed = true;
    // this.itemType = RootType.FILE_EXPLORER
  }

  setChildren(children: TreeViewItemPaques[]|null) {
    if(children){
      for (const item of children) {
        item.parent = this;
      }
  
    }
    this.children = children;
  }

  getParent(): TreeViewItemPaques {
    return this.parent;
  }

  sortChildren(): void {
    this.children.sort(fileTreeItemSorter)
  }

  removeChildrenByNameAndPushNewChild(item: TreeViewItemPaques): void {
    const idx = this.children.findIndex(el => el.value.name === item.value.name);
    this.children[idx].value = item.value;
  }

  popFromParent(): void {
    if (!this.parent) {
      return
    }

    const idx = this.parent.children.indexOf(this);
    this.parent.children.splice(idx, 1);
  }
  
  popChildrenByName(name: string): void {
    if (!this.children) {
      return;
    }
    let idx = this.findChildrenIndexByName(name);
    this.children.splice(idx, 1);
  }

  findChildrenIndexByName = (name: string): number => {
    for (let i = 0; i < this.children.length; i++) {
      if (this.children[i].text === name) {
        return i;
      }
    }
    return -1;
  };

  overwriteItem(item: TreeViewItemPaques) {
    if (!this.children) {
      return;
    }

    let index = this.findChildrenIndexByName(item.text);
    this.children[index] = item;
  }

  pushNewChild(child: TreeViewItemPaques) {
    if (!this.children) {
      this.setChildren([child]);
      child.parent = this;
      this.children = [child]
      return;
    }
    this.children.push(child);
    this.sortChildren();
    child.parent = this;
  }
}

export const fileTreeItemSorter = (a: TreeViewItemPaques, b: TreeViewItemPaques) => {
  if (a.value.isDir == b.value.isDir) {
    if (a.text.toLowerCase() < b.text.toLowerCase()) {
      return -1;
    }

    if (a.text.toLowerCase() > b.text.toLowerCase()) {
      return 1;
    }

    return 0;
  } else if (a.value.isDir) {
    return -1;
  }
  return 1;
}