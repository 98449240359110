import { Component, OnInit, ElementRef, Renderer2, ChangeDetectorRef } from "@angular/core";
import { ViewChild } from "@angular/core";
import { LayoutConfigModel } from "src/app/libs/models/common";
import { LayoutConfigService } from "src/app/libs/services";

@Component({
    selector: 'pq-theme',
    templateUrl: './theme.component.html'
})
export class ThemeComponent implements OnInit {
    isOpen: boolean = false;
    model: LayoutConfigModel;
    @ViewChild('toggleButton5') toggleButton: ElementRef;
    @ViewChild('menu5') menu: ElementRef;

    constructor(
        public cdRef: ChangeDetectorRef,
        private renderer: Renderer2,
        private layoutConfigService: LayoutConfigService,
      ) {
        this.renderer.listen('window', 'click', (e: Event) => {
          /**
           * Only run when toggleButton is not clicked
           * If we don't check this, all clicks (even on the toggle button) gets into this
           * section which in the result we might never see the menu open!
           * And the menu itself is checked here, and it's where we check just outside of
           * the menu and button the condition abbove must close the menu
           */
          if (!this.toggleButton.nativeElement.contains(e.target) && !this.menu.nativeElement.contains(e.target)) {
            this.isOpen = false;
            this.cdRef.detectChanges();
          }
        });
      }

    ngOnInit(): void {
      this.model = this.layoutConfigService.getConfig();
    }

    handleDialog = () => {
      this.isOpen = !this.isOpen;
    };

    changeSkin(skinText: string): void {
      this.model.login.self.skin = skinText;
      this.layoutConfigService.setConfig(this.model, true);
      document.body.className = '';
      document.body.className =
        skinText == 'dark'
          ? 'theme-light-pqs theme-pds theme-dark theme-cyan kt-page--loaded'
          : skinText === 'navy' 
          ? 'theme-light-pqs theme-pds theme-navy theme-cyan kt-page--loaded' 
          : skinText === 'green' 
          ? 'theme-light-pqs theme-pds theme-green theme-cyan kt-page--loaded' 
          : skinText === 'blue' 
          ? 'theme-light-pqs theme-pds theme-blue theme-cyan kt-page--loaded' 
          : skinText === 'light-orange' 
          ? 'theme-light-pqs theme-pds theme-light-orange theme-cyan kt-page--loaded'
          : skinText === 'light-purple'
          ? 'theme-light-pqs theme-pds theme-light-purple theme-cyan kt-page--loaded'
          : 'theme-light-pqs theme-pds kt-page--loaded';

      this.handleDialog();
    }
}