import { axisFormater } from '.';
import { Gradient } from './generateGradientColors';
export const setConfigChartSunburst = async (data: any, color: any) => {
  return {
    tooltip: {
      formatter: (info) => {
        const treePathInfo = info.treePathInfo;
        let treePath: any = [];

        for (var i = 1; i < treePathInfo.length; i++) {
          let nilai = treePathInfo[i].value;
          if (data.form_data.number_format) nilai = axisFormater(nilai, data, data.form_data.number_format);
          treePath.push(treePathInfo[i].name + ' (' + nilai + ')');
        }

        return ['<div class="tooltip-title">' + treePath.join(' - ') + '</div>'].join('');
      },
    },
    series: {
      type: 'sunburst',
      data: data.data.length > 0 ? modifyDataSunbrust(data.data[0].children, color, 'parent') : [],
      radius: ['25%', '95%'],
      emphasis: {
        focus: 'ancestor',
      },
      nodeClick: false,
      label: {
        rotate: 'radial',
        color: '#f7f7f7',
        textBorderColor: '#272E2F',
        textBorderWidth: '2',
        fontWeight: '400',
        fontFamily: 'Roboto',
        fontSize: '10',
        minAngle: 15,
        // condition hide label
        show: data.form_data.hide_label ? false : true,
        // condition label outside and inside
        position: data.form_data.labels_outside ? 'outside' : 'inside',
        formatter: function (params) {
          let name = params['data'].name;
          if (!params['data']) return '0';

          if (data.form_data.number_format)
            return name + '\n' + axisFormater(params['data'].value, data, data.form_data.number_format);

          return name + '\n' + params['data'].value;
        },
      },
    },
  };
};

const modifyDataSunbrust = (data, color, type, parent?) => {
  let maping = [];
  for (let i = 0; i < data.length; i++) {
    let item = {
      ...data[i],
      parent: parent,
      children: data[i]?.children || [],
      itemStyle: {

        color: type === 'child' ? color : color[i],
      },
    };
    if (item?.children?.length) {
      // child reference to know the identity child and parent pair
      let childReference = parent !== undefined ? `${parent}-${i}` : '';
      // why + 4 because need to get more gradient color
      let colorPalette = new Gradient()
        .setColorGradient(...[type === 'child' ? color : color[i], '#FFF'])
        .setMidpoint(childReference.split(',').length + 4)
        .getColors();
      item.children = modifyDataSunbrust(
        item?.children,
        colorPalette[childReference.split(',').length], // to get color base on child position pair
        'child',
        parent !== undefined ? `${parent}-${i}` : i 
      );
    }
    maping.push(item);
  }
  return maping;
};
