import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { JsonService } from 'src/app/libs/services';
import { Captcha, ResetLoginFailed } from 'src/app/libs/store/actions/authentication.actions';
import {
  UserSelector,
  captchaSelector,
  loginFailedSelector,
} from 'src/app/libs/store/selectors/authentication.selectors';
import { AppState } from 'src/app/libs/store/states';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/libs/services';

@Component({
  selector: 'login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
})
export class LoginModalComponent implements OnInit, OnDestroy {
  @Input() public isApplication;
  @Output() submitAction: EventEmitter<any> = new EventEmitter<any>();

  public loginFormControl: FormGroup = new FormGroup({
    email: new FormControl({ value: '', disabled: true }),
    password: new FormControl(),
    captcha: new FormControl(),
  });
  public captchaBase64: SafeUrl = '';
  public fieldTextType: boolean = false;
  public username;
  private captchaId: string = '';
  private isInit: boolean = true;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    private jsonService: JsonService,
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.store.dispatch(ResetLoginFailed());

    this.setCaptcha();

    this.store
      .select(captchaSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result) {
          const url = 'data:image;base64,' + result.captcha_blob;
          this.captchaBase64 = this.sanitizer.bypassSecurityTrustUrl(url);
          this.captchaId = result.captcha_id;
          this.changeDetector.detectChanges();
        }
      });

    this.store
      .select(UserSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result) this.username = result.username;
      });

    this.store
      .select(loginFailedSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        if (!this.isInit && res) {
          this.openSnackBar(res.message);
          this.setCaptcha();
        }
      });

    this.initLoginForm();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initLoginForm() {
    this.isInit = false;

    this.loginFormControl = this.formBuilder.group({
      email: [
        {
          value: this.username,
          disabled: true,
        },
        Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(200)]),
      ],
      password: [{ value: '', disabled: false }, Validators.compose([Validators.required, Validators.minLength(3)])],
      captcha: [{ value: '', disabled: false }, Validators.compose([Validators.required, Validators.minLength(1)])],
    });
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.loginFormControl.controls[controlName];
    if (!control) {
      return false;
    }
    return control.hasError(validationType) && (control.dirty || control.touched);
  }

  submitForm() {
    const controls = this.loginFormControl.controls;

    if (this.loginFormControl.invalid) {
      Object.keys(controls).forEach((controlName) => {
        controls[controlName].markAsTouched();
      });

      return;
    }

    const userCredential: UserCredential = {
      email: controls.email.value,
      password: controls.password.value,
      captcha: controls.captcha.value,
      captcha_id: this.captchaId,
    };

    this.submitAction.emit(userCredential);
  }

  setCaptcha() {
    this.store.dispatch(Captcha());
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'x', {
      duration: 3000,
    });
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  goToAuthPage() {
    this.router.navigate(['/auth/login']);
    this.authenticationService.resetNeedRelogin();
  }

  refreshCaptcha(){
    this.store.dispatch(Captcha());
  }
}
