import { Injectable } from '@angular/core';
import { TreeviewItem } from 'ngx-treeview';
import { ApiService } from '../../../common/api.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/libs/store/states';
import { SetToastrMessage } from 'src/app/libs/store/actions/pds/dataprocessing.actions';
import { convertDatasourceItemsToTreeviewItems } from 'src/app/libs/helpers/data-processing.helper';
import { BehaviorSubject } from 'rxjs';
import { IDataSourceItemV2, itemsAction, PathObject, SelectedItem } from 'src/app/libs/types/v2/dataprocessing';
import {
  DATAPROCESSING_API_PATH,
  DATAPROCESSING_DATASOURCE_TYPE,
  ROOT_DIR,
  STATIC_DATABASE_CONNECTION,
  STATIC_ROOT_HDFS,
  STATIC_ROOT_AWS,
  STATIC_ROOT_DIR,
  RootType,
} from 'src/app/libs/consts';

import { TranslateService } from '@ngx-translate/core';
import {
  Column,
  DeleteResponse,
  ColumnResponseList,
  DatabaseConnection,
  DatabaseConnectionResponseList,
  Explorer,
  ExplorerResponse,
  ExplorerResponseList,
  FileType,
  SchemaTables,
  SchemaTablesResponseList,
  HDFSConnection,
  HDFSConnectionResponse,
  HDFSTestResponse,
  AWSConnection,
  AWSConnectionResponse,
  AWSTestResponse,
} from 'src/vars/explorer';
import { fileTreeItemSorter, TreeViewItemPaques } from 'src/vars/explorer-tree';

@Injectable({
  providedIn: 'root',
})
export class DataProcessingDataSourceService {
  private _loading$ = new BehaviorSubject<boolean>(false);
  private _loadingChild$ = new BehaviorSubject<boolean>(false);

  private _currentItem: TreeViewItemPaques;
  private _rootTreeview: TreeViewItemPaques[];
  private _fileTreeview: TreeViewItemPaques;
  private _hdfsTreeview: TreeViewItemPaques;
  private _awsTreeview: TreeViewItemPaques;
  private _dbTreeview: TreeViewItemPaques;
  private _typeAct: string;
  private _path: PathObject;
  private _selectedItem: SelectedItem;

  constructor(private apiService: ApiService, private store: Store<AppState>, private translate: TranslateService) {
    this.initialTreeview();
  }

  initialTreeview() {
    /**
     * TODO
     * FIND OUT WHY AFTER RELOGIN OR REFRESH PAGE
     * DATASOURCE EMPTY (FILE, HDFS AND DB)
     */
    this._fileTreeview = new TreeViewItemPaques(STATIC_ROOT_DIR);
    this._hdfsTreeview = new TreeViewItemPaques(STATIC_ROOT_HDFS);
    this._awsTreeview = new TreeViewItemPaques(STATIC_ROOT_AWS);
    this._dbTreeview = new TreeViewItemPaques(STATIC_DATABASE_CONNECTION);
    this._rootTreeview = [this._fileTreeview, this._hdfsTreeview, this._awsTreeview, this._dbTreeview];
  }

  resetTreeview() {
    this.initialTreeview();
  }

  getLoadingSubject(): BehaviorSubject<boolean> {
    return this._loading$;
  }

  getLoadingChildSubject(): BehaviorSubject<boolean> {
    return this._loadingChild$;
  }

  getSelectedItem() {
    return this._selectedItem;
  }
  setLoading(isLoading: boolean): void {
    this._loading$.next(isLoading);
  }

  setCurrentItem(currentItem: TreeViewItemPaques): void {
    this._currentItem = currentItem;
  }

  setTypeAct(typeAct: string): void {
    this._typeAct = typeAct;
  }

  setPath(path: any): void {
    this._path = path;
  }

  selectItem(item: TreeViewItemPaques, action: itemsAction): void {
    this._selectedItem = { item, action };
  }

  deselectItem(): void {
    this._selectedItem = null;
  }
  pasteSelectedItem(destParent: TreeViewItemPaques) {
    if (!this._selectedItem) {
      return;
    }
    if (this._selectedItem.action === 'mv') {
      this.moveSelectedItem(destParent);
    } else {
      this.copySelectedItem(destParent);
    }
  }

  deleteItem(): void {
    this._selectedItem.item.popFromParent();
  }

  moveSelectedItem(destParent: TreeViewItemPaques) {
    if (!this._selectedItem) {
      return;
    }
    this._selectedItem.item.popFromParent();
    const newItem = this.moveItemToDestParent(this._selectedItem.item, destParent);

    destParent.pushNewChild(newItem);
  }

  copySelectedItem(destParent: TreeViewItemPaques) {
    if (!this._selectedItem) {
      return;
    }
    this.copyItemToDestParent(this._selectedItem.item, destParent, true);
  }
  moveItemToDestParent = (item: TreeViewItemPaques, destParent: TreeViewItemPaques) => {
    /**
     * TODO
     * path nya benerin
     * copy path harus ada slash diawal
     * example : /folder/folderchild/foldergrandchild
     *
     *
     */
    // Extract the location and name of the destination parent
    const parentLocation = destParent.value.location;
    const parentName = destParent.value.name;

    // updating the location
    item.value.location = `${parentLocation}/${parentName}`;
    // Recursively copy children if they exist
    if (item.children) {
      for (const child of item.children) {
        // Recursively copy each child and add to the newItem's children
        this.moveItemToDestParent(child, item);
      }
    }

    // Set the parent of the new item
    item.parent = destParent;

    return item;
  };

  copyItemToDestParent = (
    item: TreeViewItemPaques,
    destParent: TreeViewItemPaques,
    withPush: boolean
  ): TreeViewItemPaques => {
    if (!destParent.value.isDir) {
      return;
    }
    // Extract the location and name of the destination parent
    const parentLocation = destParent.value.location;
    const parentName = destParent.value.name;

    // Create a new object by copying the item’s value and updating the location
    const newItemValue = { ...item.value, location: `${parentLocation}/${parentName}` };

    // Create a new TreeViewItemPaques instance with the updated value
    const newItem = new TreeViewItemPaques(newItemValue);

    // Recursively copy children if they exist
    if (item.children) {
      for (const child of item.children) {
        this.copyItemToDestParent(child, newItem, true);
      }
      newItem.isLoaded = true; // Indicate that the item has loaded its children
    }
    if (withPush) {
      destParent.pushNewChild(newItem);
    }

    return newItem;
  };
  overwriteSelectedItem(destParent: TreeViewItemPaques) {
    if (!this._selectedItem) {
      return;
    }
    let newItemSelected;
    destParent.popChildrenByName(this._selectedItem.item.value.name);

    if (this._selectedItem.action === 'mv') {
      this._selectedItem.item.popFromParent();
      newItemSelected = this.moveItemToDestParent(this._selectedItem.item, destParent);
    } else {
      newItemSelected = this.copyItemToDestParent(this._selectedItem.item, destParent, false);
    }
    destParent.pushNewChild(newItemSelected);
  }

  getCurrentItem(): TreeViewItemPaques {
    return this._currentItem;
  }

  getRootTreeview(): TreeViewItemPaques[] {
    return this._rootTreeview;
  }

  getFileTreeview(): TreeViewItemPaques {
    return this._fileTreeview;
  }

  getPath(): PathObject {
    return this._path;
  }

  isRootTreeviewNotEmpty(): boolean {
    return this._rootTreeview && this._rootTreeview.length === 0;
  }

  private _selectTreeview(treeViewType: number): TreeViewItemPaques {
    if (treeViewType === DATAPROCESSING_DATASOURCE_TYPE.HDFS) {
      return this._hdfsTreeview;
    }

    if (treeViewType === DATAPROCESSING_DATASOURCE_TYPE.DATABASE) {
      return this._dbTreeview;
    }

    return;
  }

  convertExplorerResponseToTreeViewItem(resp: Explorer[]): TreeViewItemPaques[] | null {
    if (resp.length === 0) {
      return null;
    }

    let result: TreeViewItemPaques[] = [];
    for (const item of resp) {
      item.roottype = RootType.FILE_EXPLORER;
      result.push(new TreeViewItemPaques(item));
    }

    return result.sort(fileTreeItemSorter);
  }

  convertResponseToHdfsConTreeViewItem(resp: HDFSConnection[]): TreeViewItemPaques[] | null {
    if (resp.length === 0) {
      return null;
    }

    let result: TreeViewItemPaques[] = [];
    for (const item of resp) {
      item.roottype = RootType.HDFS;
      item.isDir = true;
      result.push(new TreeViewItemPaques(item));
    }

    return result.sort(fileTreeItemSorter);
  }

  convertResponseToHdfsListTreeViewItem(resp: Explorer[], connectionName: string): TreeViewItemPaques[] | null {
    if (resp.length === 0) {
      return null;
    }

    let result: TreeViewItemPaques[] = [];
    for (const item of resp) {
      item.roottype = RootType.HDFS;
      item.connectionName = connectionName;
      result.push(new TreeViewItemPaques(item));
    }

    return result.sort(fileTreeItemSorter);
  }

  convertResponseToAwsConTreeViewItem(resp: AWSConnection[]): TreeViewItemPaques[] | null {
    if (resp.length === 0) {
      return null;
    }

    let result: TreeViewItemPaques[] = [];
    for (const item of resp) {
      item.roottype = RootType.AWS;
      item.isDir = true;
      result.push(new TreeViewItemPaques(item));
    }

    return result.sort(fileTreeItemSorter);
  }

  convertResponseToAwsListTreeViewItem(resp: Explorer[], connectionName: string): TreeViewItemPaques[] | null {
    if (resp.length === 0) {
      return null;
    }

    let result: TreeViewItemPaques[] = [];
    for (const item of resp) {
      item.roottype = RootType.AWS;
      item.connectionName = connectionName;
      result.push(new TreeViewItemPaques(item));
    }

    return result.sort(fileTreeItemSorter);
  }

  convertDatabaseConnectionResponseToTreeViewItem(resp: DatabaseConnection[]): TreeViewItemPaques[] | null {
    if (resp.length === 0) {
      return null;
    }

    let result: TreeViewItemPaques[] = [];
    for (let item of resp) {
      item.filetype = FileType.DbConnection;
      result.push(new TreeViewItemPaques(item));
    }

    return result.sort(fileTreeItemSorter);
  }

  convertSchemaTablesResponseToTreeViewItem(resp: SchemaTables[]): TreeViewItemPaques[] | null {
    if (resp.length === 0) {
      return null;
    }

    let result: TreeViewItemPaques[] = [];
    for (const schemaTable of resp) {
      const newSchema = {
        name: schemaTable.schema,
        filetype: FileType.Schema,
      };
      const newSchemaItem = new TreeViewItemPaques(newSchema);

      let newTableItems: TreeViewItemPaques[] = [];
      for (const table of schemaTable.tables) {
        const newTable = {
          name: table,
          filetype: FileType.Table,
        };
        const newTableItem = new TreeViewItemPaques(newTable);
        newTableItems.push(newTableItem);
      }
      newSchemaItem.setChildren(newTableItems);

      result.push(newSchemaItem);
    }

    return result.sort(fileTreeItemSorter);
  }

  convertColumnResponseToTreeViewItem(resp: Column[]): TreeViewItemPaques[] | null {
    if (resp.length === 0) {
      return null;
    }

    let result: TreeViewItemPaques[] = [];
    for (const schemaTable of resp) {
      const newColumn = {
        name: `${schemaTable.column_name} (${schemaTable.data_type})`,
        columnName: schemaTable.column_name,
        filetype: FileType.Column,
      };
      const newColumnItem = new TreeViewItemPaques(newColumn);
      result.push(newColumnItem);
    }

    return result.sort(fileTreeItemSorter);
  }

  async loadChildExplorer(parent: TreeViewItemPaques, filter: string): Promise<void> {
    if (parent.isLoaded) {
      return;
    }
    this._loadingChild$.next(true);
    const result: ExplorerResponseList = await new Promise<ExplorerResponseList>((resolve, reject) => {
      this.apiService
        .gets(DATAPROCESSING_API_PATH.EXPLORER_LIST, {
          // have a ternary option when location is same with ROOT_DIR remove the slash
          // This is problemwhen  the slash to be more than 2
          path: `${parent.value.location}${
            parent.value.isRoot
              ? ''
              : parent.value.location === ROOT_DIR
              ? `${parent.value.name}`
              : `/${parent.value.name}`
          }`,
          term: filter,
        })
        .subscribe({
          next: (res) => resolve(res),
          error: (err) => {
            this._toastr('error', err.message);
            this._loadingChild$.next(false);
            reject(err);
          },
        });
    });
    this._loadingChild$.next(false);
    if (result.code === 200 && result.response.length) {
      parent.setChildren(this.convertExplorerResponseToTreeViewItem(result.response));
      parent.isLoaded = true;
    }
  }

  async searchExplorer(parent: TreeViewItemPaques, filter: string): Promise<void> {
    if (parent.isLoaded) {
      return;
    }
    this._loadingChild$.next(true);
    const result: ExplorerResponseList = await new Promise<ExplorerResponseList>((resolve, reject) => {
      this.apiService
        .gets(DATAPROCESSING_API_PATH.EXPLORER_SEARCH, {
          // have a ternary option when location is same with ROOT_DIR remove the slash
          // This is problemwhen  the slash to be more than 2
          path: `${parent.value.location}${
            parent.value.isRoot
              ? ''
              : parent.value.location === ROOT_DIR
              ? `${parent.value.name}`
              : `/${parent.value.name}`
          }`,
          term: filter,
        })
        .subscribe({
          next: (res) => resolve(res),
          error: (err) => {
            this._toastr('error', err.message);
            this._loadingChild$.next(false);
            reject(err);
          },
        });
    });
    this._loadingChild$.next(false);
    if (result.code === 200) {
      this.getFileTreeview().setChildren(this.convertExplorerResponseToTreeViewItem(result.response));
      if (filter) {
        this._rootTreeview = [this._fileTreeview];
      } else {
        this._rootTreeview = [this._fileTreeview, this._hdfsTreeview, this._dbTreeview];
      }
    }
  }

  async loadHDFSConnection(parent: TreeViewItemPaques): Promise<void> {
    if (parent.isLoaded) {
      return;
    }
    this._loadingChild$.next(true);

    const result: HDFSConnectionResponse = await new Promise<HDFSConnectionResponse>((resolve, reject) => {
      this.apiService.gets(DATAPROCESSING_API_PATH.HDFS_CONNECTION, {}).subscribe({
        next: (res) => resolve(res),
        error: (err) => {
          this._toastr('error', err.message);
          this._loadingChild$.next(false);
          reject(err);
        },
      });
    });
    this._loadingChild$.next(false);
    if (result.response.length && result.code === 200) {
      parent.setChildren(this.convertResponseToHdfsConTreeViewItem(result.response));
      parent.isLoaded = true;
    }
  }

  async loadAWSConnection(parent: TreeViewItemPaques): Promise<void> {
    if (parent.isLoaded) {
      return;
    }
    this._loadingChild$.next(true);

    const result: AWSConnectionResponse = await new Promise<AWSConnectionResponse>((resolve, reject) => {
      this.apiService.gets(DATAPROCESSING_API_PATH.AWS_CONNECTION, {}).subscribe({
        next: (res) => resolve(res),
        error: (err) => {
          this._toastr('error', err.message);
          this._loadingChild$.next(false);
          reject(err);
        },
      });
    });
    this._loadingChild$.next(false);
    if (result.response && result.code === 200) {
      parent.setChildren(this.convertResponseToAwsConTreeViewItem(result.response));
      parent.isLoaded = true;
    }
  }

  async loadChildDatabaseConnection(parent: TreeViewItemPaques, filter: string): Promise<void> {
    if (parent.isLoaded) {
      return;
    }
    this._loadingChild$.next(true);

    const result: DatabaseConnectionResponseList = await new Promise<DatabaseConnectionResponseList>(
      (resolve, reject) => {
        this.apiService
          .gets(DATAPROCESSING_API_PATH.DB_CONNECTION, {
            type: 'all',
          })
          .subscribe({
            next: (res) => resolve(res),
            error: (err) => {
              this._toastr('error', err.message);
              this._loadingChild$.next(false);
              reject(err);
            },
          });
      }
    );
    this._loadingChild$.next(false);
    if (result.response.length && result.code === 200) {
      parent.setChildren(this.convertDatabaseConnectionResponseToTreeViewItem(result.response));
      parent.isLoaded = true;
    }
  }

  async loadListHDFS(item: TreeViewItemPaques, connectionName: string) {
    if (item.isLoaded) {
      return;
    }
    this._loadingChild$.next(true);
    const result: ExplorerResponseList = await new Promise<ExplorerResponseList>((resolve, reject) => {
      this.apiService
        .gets(`${DATAPROCESSING_API_PATH.HDFS_CONNECTION}/${connectionName}/list`, {
          path: !item.value.location ? '/' : `${item.value.location}/${item.value.name}`,
          term: '',
        })
        .subscribe({
          next: (res) => resolve(res),
          error: (err) => {
            this._toastr('error', err.message);
            this._loadingChild$.next(false);
            reject(err);
          },
        });
    });
    this._loadingChild$.next(false);
    if (result.response.length && result.code === 200) {
      item.setChildren(this.convertResponseToHdfsListTreeViewItem(result.response, connectionName));
      item.isLoaded = true;
    }
  }

  async loadListAWS(item: TreeViewItemPaques, connectionName: string) {
    if (item.isLoaded) {
      return;
    }
    this._loadingChild$.next(true);
    const result: ExplorerResponseList = await new Promise<ExplorerResponseList>((resolve, reject) => {
      this.apiService
        .gets(`${DATAPROCESSING_API_PATH.AWS_CONNECTION}/${connectionName}/list`, {
          path: !item.value.location ? '/' : `${item.value.name}`,
        })
        .subscribe({
          next: (res) => resolve(res),
          error: (err) => {
            this._toastr('error', err.message);
            this._loadingChild$.next(false);
            reject(err);
          },
        });
    });
    this._loadingChild$.next(false);
    if (result.response.length && result.code === 200) {
      item.setChildren(this.convertResponseToAwsListTreeViewItem(result.response, connectionName));
      item.isLoaded = true;
    }
  }

  async loadChildSchemaTables(parent: TreeViewItemPaques, filter: string): Promise<void> {
    if (parent.isLoaded) {
      return;
    }
    this._loadingChild$.next(true);

    const result: SchemaTablesResponseList = await new Promise<SchemaTablesResponseList>((resolve, reject) => {
      this.apiService
        .gets(DATAPROCESSING_API_PATH.DB_SHOW_SCHEMA_TABLES, {
          key: parent.value.key,
          owner: parent.value.owner,
        })
        .subscribe({
          next: (res) => {
            parent.value.testConnection = 'success';
            resolve(res);
          },
          error: (err) => {
            parent.value.testConnection = 'failed';
            this._toastr('error', err.message);
            this._loadingChild$.next(false);
            reject(err);
          },
        });
    });
    this._loadingChild$.next(false);
    if (result.response.length && result.code === 200) {
      parent.setChildren(this.convertSchemaTablesResponseToTreeViewItem(result.response));
      parent.isLoaded = true;
    }
  }

  async loadChildColumns(parent: TreeViewItemPaques, filter: string): Promise<void> {
    if (parent.isLoaded) {
      return;
    }
    this._loadingChild$.next(true);

    const result: ColumnResponseList = await new Promise<ColumnResponseList>((resolve, reject) => {
      const { key, owner } = parent.getParent().getParent().value;
      this.apiService
        .gets(DATAPROCESSING_API_PATH.DB_SHOW_COLUMN, {
          key,
          owner,
          tableName: parent.value.name,
          schemaName: parent.getParent().value.name
        })
        .subscribe({
          next: (res) => resolve(res),
          error: (err) => {
            this._toastr('error', err.message);
            this._loadingChild$.next(false);
            reject(err);
          },
        });
    });
    this._loadingChild$.next(false);
    if (result.response && result.code === 200) {
      parent.setChildren(this.convertColumnResponseToTreeViewItem(result.response));
      parent.isLoaded = true;
    }
  }

  async testHDFS(item: TreeViewItemPaques): Promise<HDFSTestResponse> {
    if (item.isLoaded) {
      return;
    }
    this._loadingChild$.next(true);
    return await new Promise((resolve, reject) => {
      this.apiService
        .post(`${DATAPROCESSING_API_PATH.HDFS_CONNECTION}/test`, {
          node_name: item.value.node_name,
          port: item.value.port,
        })
        .subscribe({
          next: (res) => {
            item.value.testConnection = 'success';
            this._toastr('info', this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.TCS'));
            resolve(res);
          },
          error: (err) => {
            item.value.testConnection = 'failed';
            this._toastr('error', `${this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.TCF')}: ${err.message}`);
            this._loadingChild$.next(false);
            reject(err);
          },
        });
    });
  }

  async testAWS(item: TreeViewItemPaques): Promise<AWSTestResponse> {
    if (item.isLoaded) {
      return;
    }
    this._loadingChild$.next(true);
    return await new Promise((resolve, reject) => {
      this.apiService
        .post(`${DATAPROCESSING_API_PATH.AWS_CONNECTION}/test`, {
          bucket_name: item.value.bucket_name,
          region_name: item.value.region_name,
          access_key_id: item.value.access_key_id,
          secret_access_key: item.value.secret_access_key,
        })
        .subscribe({
          next: (res) => {
            item.value.testConnection = 'success';
            this._toastr('info', this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.TCS'));
            resolve(res);
          },
          error: (err) => {
            item.value.testConnection = 'failed';
            this._toastr('error', `${this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.TCF')}: ${err.message}`);
            this._loadingChild$.next(false);
            reject(err);
          },
        });
    });
  }

  async checkCurrentNameOnDestPath(destPath: string, name: string): Promise<boolean[]> {
    const checkResult: ApiResult = await new Promise((resolve, reject) => {
      this.apiService
        .post(
          `/api/v2/explorer/check`,
          {
            paths: [`${destPath}/${name}`],
          },
          true
        )
        .subscribe({
          next: (res: any) => {
            resolve(res);
          },
          error: (err: any) => reject(err),
        });
    });

    return checkResult.response ? checkResult.response : [false];
  }

  async cpmvFileFolder(destinationFolder: TreeviewItem): Promise<ExplorerResponseList> {
    let result = await new Promise<ExplorerResponseList>((resolve, reject) => {
      this.apiService
        .post(
          this._selectedItem.action === 'mv'
            ? DATAPROCESSING_API_PATH.EXPLORER_MOVE
            : DATAPROCESSING_API_PATH.EXPLORER_COPY,
          {
            src: [`${this._selectedItem.item.value.location}/${this._selectedItem.item.value.name}`],
            dst: [
              `${destinationFolder.value.location}/${destinationFolder.value.name}/${this._selectedItem.item.value.name}`,
            ],
          }
        )
        .subscribe({
          next: (res) => {
            this._toastr('info', this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.FDCCS'));
            resolve(res);
          },
          error: (err) => {
            this._toastr('error', err.message);
            reject(err);
          },
        });
    });
    return result;
  }

  async createFolder(folderItem: TreeViewItemPaques, name: string): Promise<ExplorerResponse> {
    let result = await new Promise<ExplorerResponse>((resolve, reject) => {
      this.apiService
        .post(`/api/v2/explorer/create-dir`, {
          path: folderItem.value.isRoot
            ? folderItem.value.location
            : `${folderItem.value.location}/${folderItem.value.name}`,
          name,
        })
        .subscribe({
          next: (res) => {
            this._toastr('info', this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.DCS'));
            resolve(res);
          },
          error: (err) => {
            this._toastr('error', err.message);
            reject(err);
          },
        });
    });

    return result;
  }

  async renameFileFolder(params: { [key: string]: string[] }): Promise<ExplorerResponseList> {
    return await new Promise<ExplorerResponseList>((resolve, reject) => {
      this.apiService.post(DATAPROCESSING_API_PATH.EXPLORER_MOVE, params).subscribe({
        next: (res) => {
          this._toastr('info', this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.FFRS'));
          resolve(res);
        },
        error: (err) => {
          this._toastr('error', err.message);
          reject(err);
        },
      });
    });
  }

  async deleteFileFolder(params: { [key: string]: string[] | string }): Promise<DeleteResponse> {
    return await new Promise((resolve, reject) => {
      this.apiService.post(DATAPROCESSING_API_PATH.EXPLORER_REMOVE, params).subscribe({
        next: async (res) => {
          this._toastr('info', this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.FFDS'));
          resolve(res);
        },
        error: (err) => {
          this._toastr('error', err.message || this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.PF'));
          reject(err);
        },
      });
    });
  }

  async deleteConnection(params: { [key: string]: string[] | string }): Promise<DeleteResponse> {
    return await new Promise<DeleteResponse>((resolve, reject) => {
      this.apiService.delete(`${DATAPROCESSING_API_PATH.DB_CONNNECTION_DELETE}/${params}`).subscribe({
        next: async (res) => {
          resolve(res);
          this._toastr('info', this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.PC'));
        },
        error: (err) => {
          this._toastr('error', err.message || this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.PF'));
          reject(err);
        },
      });
    });
  }

  async uploadFile(params: FormData): Promise<ExplorerResponseList> {
    return await new Promise<ExplorerResponseList>((resolve, reject) => {
      this.apiService.post(DATAPROCESSING_API_PATH.EXPLORER_UPLOAD, params).subscribe({
        next: async (res) => {
          resolve(res);
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  _toastr(toastrType: string, message: string): void {
    this.store.dispatch(
      SetToastrMessage({
        toastrMessage: {
          toastrType,
          message,
        },
      })
    );
  }

  async deleteHdfsConnection(params: { [key: string]: string[] | string }): Promise<HDFSConnectionResponse> {
    let result = await new Promise<HDFSConnectionResponse>((resolve, reject) => {
      this.apiService.delete(`${DATAPROCESSING_API_PATH.HDFS_CONNECTION}/${params}`).subscribe({
        next: async (res) => {
          resolve(res);
          this._toastr('info', this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.PC'));
        },
        error: (err) => {
          this._toastr('error', err.message || this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.PF'));
          reject(err);
        },
      });
    });
    return result;
  }

  async deleteAwsConnection(params: { [key: string]: string[] | string }): Promise<AWSConnectionResponse> {
    let result = await new Promise<AWSConnectionResponse>((resolve, reject) => {
      this.apiService.delete(`${DATAPROCESSING_API_PATH.AWS_CONNECTION}/${params}`).subscribe({
        next: async (res) => {
          resolve(res);
          this._toastr('info', this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.PC'));
        },
        error: (err) => {
          this._toastr('error', err.message || this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.PF'));
          reject(err);
        },
      });
    });
    return result;
  }

  insertDatabaseList(result: DatabaseConnection) {
    this._dbTreeview.pushNewChild(new TreeViewItemPaques(result));
  }

  insertHdfsList(result: HDFSConnection) {
    this._hdfsTreeview.pushNewChild(new TreeViewItemPaques(result));
  }

  insertAwsList(result: AWSConnection) {
    this._awsTreeview.pushNewChild(new TreeViewItemPaques(result));
  }

  reloadTreeview = () => {
    this._fileTreeview.isLoaded = false;
    this._dbTreeview.isLoaded = false;
    this._hdfsTreeview.isLoaded = false;
    this.loadChildExplorer(this._fileTreeview, '');
    this.loadChildDatabaseConnection(this._dbTreeview, '');
    this.loadHDFSConnection(this._hdfsTreeview);
    this._rootTreeview = [this._fileTreeview, this._hdfsTreeview, this._dbTreeview];
  };
}