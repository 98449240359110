import { TreeviewItem } from 'ngx-treeview';
import _ from 'lodash';
import { IDataSourceItemV2 } from '../types/dataprocessing';
import { CommandItemComponent } from 'src/app/components/workspaces/data-processing-workspace/components/command-item/command-item.component';
import { QueryList } from '@angular/core';

export const _sortTreeviewItems = function (treeviewItems: TreeviewItem[]) {
  // console.log('treeviewItems', [...treeviewItems]);

  const folder = treeviewItems.filter((treeviewItem: TreeviewItem) => treeviewItem.value.isDir);
  const file = treeviewItems.filter((treeviewItem: TreeviewItem) => !treeviewItem.value.isDir);

  // console.log('folder', [...folder]);
  // console.log('file', [...file]);

  folder.sort(function (a, b) {
    if (a.text.toLowerCase() < b.text.toLowerCase()) {
      return -1;
    }

    if (a.text.toLowerCase() > b.text.toLowerCase()) {
      return 1;
    }

    return 0;
  });

  file.sort(function (a, b) {
    if (a.text.toLowerCase() < b.text.toLowerCase()) {
      return -1;
    }

    if (a.text.toLowerCase() > b.text.toLowerCase()) {
      return 1;
    }

    return 0;
  });

  // treeviewItems = folder.concat(file);

  // console.log('treeviewItems', [...treeviewItems]);
  return folder.concat(file);
};

export const convertDatasourceItemsToTreeviewItems = function (
  datasourceItems: IDataSourceItemV2[],
  treeviewItems?: TreeviewItem[]
) {
  const folder = datasourceItems.filter((datasourceItem) => datasourceItem.isDir);
  const file = datasourceItems.filter((datasourceItem) => !datasourceItem.isDir);

  folder.sort(function (a, b) {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }

    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }

    return 0;
  });

  file.sort(function (a, b) {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }

    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }

    return 0;
  });

  const sortedResourceItems = folder.concat(file);

  const newTreeviewItems = sortedResourceItems.map((sortedResourceItem: IDataSourceItemV2) => {
    let collapsed = true;
    let children = null;

    if (treeviewItems) {
      const filteredTreeviewItemChildren = treeviewItems.filter(
        (item) => item && item.value && item.value.name === sortedResourceItem.name
      );
      if (filteredTreeviewItemChildren && filteredTreeviewItemChildren.length > 0) {
        collapsed = !!filteredTreeviewItemChildren[0].collapsed;
        children = filteredTreeviewItemChildren[0].children ? filteredTreeviewItemChildren[0].children : null;
      }
    }

    const newTreeviewItem = convertDatasourceItemToTreeviewItem(sortedResourceItem);
    newTreeviewItem.collapsed = collapsed;
    newTreeviewItem.children = children;

    return newTreeviewItem;
  });

  return newTreeviewItems && newTreeviewItems.length > 0 ? newTreeviewItems : null;
};

export const convertDatasourceItemToTreeviewItem = function (datasourceItem: IDataSourceItemV2) {
  let item = new TreeviewItem({
    collapsed: true,
    text: datasourceItem?.name,
    value: datasourceItem,
    checked: false,
    disabled: false,
    children: null,
  });

  return item;
};

const _attachChildrenToItem = function (
  treeViewItems: TreeviewItem[],
  currentTreeItem: TreeviewItem,
  childRawTreeItems: IDataSourceItemV2[],
) {
  let found = false;
  let i = 0;
  while (!found && treeViewItems && treeViewItems[i]) {
    const treeViewItem = treeViewItems[i];
    if (treeViewItem.value.name === currentTreeItem.value.name) {
      found = true;
      if (childRawTreeItems.length !== 0) {
        const folder = childRawTreeItems.filter((dst) => dst.isDir);
        const file = childRawTreeItems.filter((dst) => !dst.isDir);

        folder.sort(function (a, b) {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }

          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }

          return 0;
        });

        file.sort(function (a, b) {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }

          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }

          return 0;
        });

        const dataSourceChildTreeItems = folder.concat(file);

        const children = convertDatasourceItemsToTreeviewItems(dataSourceChildTreeItems);
        if (children && children.length > 0) {
          treeViewItem.children = children;
        }
      } else {
        const children = convertDatasourceItemsToTreeviewItems(childRawTreeItems);
        treeViewItem.children = children;
      }
    } else {
      const children = treeViewItem.children;
      // console.log('has children', children);
      if (children && children.length > 0) {
        _attachChildrenToItem(children, currentTreeItem, childRawTreeItems);
      }
    }

    i++;
  }
};

export const attachChildrenToItem = function (
  treeViewItems: TreeviewItem[],
  currentTreeItem: TreeviewItem,
  childRawTreeItems: IDataSourceItemV2[],
) {
  _attachChildrenToItem(treeViewItems, currentTreeItem, childRawTreeItems);

  return treeViewItems;
};

export const convStringToArr = function (arr) {
  let obj = {};
  let arr_value = [];
  arr_value = arr;
  if (arr_value.length == 0 || (arr_value.length < 2 && arr_value[0]['property'] == '' && arr_value[0]['value'] == ''))
    return null;
  for (let i = 0; i < arr_value.length; i++) {
    let prop = arr_value[i]['property'];
    let val = arr_value[i]['value'].split(',');
    obj[prop] = val;
  }
  return obj;
};

export const convObjToArr = function (obj) {
  let arr = [];
  if (obj == null || obj == undefined) return [];
  for (const [key, val] of Object.entries(obj)) {
    let value: any = [];
    value = val;
    arr.push({
      property: key,
      value: value.join(','),
    });
  }
  return arr;
};

export const _generateCommandItemPosition = function (index: number, commandItems: QueryList<CommandItemComponent>) {
  let natIndex = index - 1;
  let x = -80;
  let y = -60;

  const div = Math.floor(natIndex / 9);
  const rem = natIndex % 9;

  x += (div + 1) * 100;
  y += (rem + 1) * 70;

  commandItems.forEach((_commandItem: CommandItemComponent) => {
    if (
      (x > _commandItem?.cmdItem?.GetCoordinate().x && x < _commandItem?.cmdItem?.GetCoordinate().x + 60) ||
      (y > _commandItem?.cmdItem?.GetCoordinate().y && y < _commandItem?.cmdItem?.GetCoordinate().y + 60)
    ) {
      x += 80;
      y += 80;
    }
  });

  return { x, y };
};
