import { EventEmitter, Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';

import { rest_api } from 'src/app/libs/configs';
import { ApiService } from '../../common/api.service';
import { ModalDialogService } from '../../common/modal-dialog.service';
import { IChartNotificationApiResponse, ICommonStatusResult } from 'src/app/libs/types/datavisualization';
import { TranslateService } from '@ngx-translate/core';
import { logoPaquesBase64 } from 'src/app/libs/helpers/constant_datavisualization';

@Injectable()
export class DataVisualizationService {
  public invokeRefreshAllChart = new EventEmitter();

  private _activeWorkingSessionStatusSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loadList$ = new Subject();
  private _hasWorkingOnDataVisualizationSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _leftBarCollapsed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private _rightBarCollapsed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private _dashboardDrillThrough = {};

  constructor(
    private apiService: ApiService,
    private modalDialogService: ModalDialogService,
    private translate: TranslateService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  async initService(): Promise<void> {}

  getActiveWorkingSessionStatusSubject(): BehaviorSubject<boolean> {
    return this._activeWorkingSessionStatusSubject$;
  }

  getActiveWorkingSessionStatus(): boolean {
    return this._activeWorkingSessionStatusSubject$.getValue();
  }

  updateActiveWorkingSessionStatusSubject(status: boolean): void {
    this._activeWorkingSessionStatusSubject$.next(status);
  }

  getHasWorkingOnDataVisualization(): boolean {
    return this._hasWorkingOnDataVisualizationSubject$.getValue();
  }

  updateHasWorkingOnDataVisualizationSubject(status: boolean): void {
    this._hasWorkingOnDataVisualizationSubject$.next(status);
  }

  getLeftBarCollapsed(): BehaviorSubject<boolean> {
    return this._leftBarCollapsed$;
  }
  getDashboardDrillThrough() {
    return this._dashboardDrillThrough;
  }

  getDashboardDrillThroughByChartId(chart_id: number) {
    if (Object.keys(this._dashboardDrillThrough).length === 0) {
      return null;
    }

    return this._dashboardDrillThrough[chart_id];
  }

  setDashboardDrillThrough(value) {
    this._dashboardDrillThrough = value;
  }

  setLeftBarCollapsed(value) {
    return this._leftBarCollapsed$.next(value);
  }

  getRightBarCollapsed(): BehaviorSubject<boolean> {
    return this._rightBarCollapsed$;
  }

  setRightBarCollapsed(value) {
    return this._rightBarCollapsed$.next(value);
  }

  refrechAllChart() {
    this.invokeRefreshAllChart.emit();
  }

  private createGradientCanvas(width: number, height: number): HTMLCanvasElement {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const context = canvas.getContext('2d');

    const gradient = context.createLinearGradient(0, 0, width, 0);
    gradient.addColorStop(0, 'rgba(8, 79, 129, 0.6)');
    gradient.addColorStop(0.424, 'rgba(8, 79, 129, 0.6)');
    gradient.addColorStop(0.824, 'rgba(127, 103, 67, 0.6)');
    gradient.addColorStop(1, 'rgba(255, 128, 0, 0.36)');

    context.fillStyle = gradient;
    context.fillRect(0, 0, width, height);

    return canvas;
  }

  private addHeader(doc: jspdf, title: string) {
    const titleHeight = 20;

    doc.setFontSize(14);
    doc.setFont('helvetica', 'bold');
    doc.setTextColor(83, 77, 89);
    if (title) {
      doc.text(title, 4, titleHeight / 2 + 5, { align: 'left' });
    }
  }

  private async addFooter(doc: jspdf) {
    const footerHeight = 20;
    const pageHeight = doc.internal.pageSize.height;
    const pageWidth = doc.internal.pageSize.width;

    const gradientCanvas = this.createGradientCanvas(pageWidth, footerHeight);

    doc.addImage(gradientCanvas.toDataURL('image/png'), 'PNG', 0, pageHeight - footerHeight, pageWidth, footerHeight);

    doc.setFontSize(10);
    doc.setTextColor(255, 255, 255);
    doc.text('powered by', pageWidth / 2 - 4, pageHeight - footerHeight / 2 + 3, { align: 'right' });

    const logoWidth = 22;
    const logoHeight = 9;
    doc.addImage(logoPaquesBase64, 'PNG', pageWidth / 2 - 2, pageHeight - footerHeight + 6, logoWidth, logoHeight);
  }

  async exportGridStackToPdf(title: string, selectorItems: string, parameter?: any) {
    const doc = new jspdf('landscape', 'mm', 'a4');

    this.modalDialogService.openProgressDialog();

    const gridItems = document.querySelectorAll(selectorItems);
    const length = gridItems.length;
    const fullGridWidth = 12;
    const fullGridHeight = 10;

    const fullPageWidth = doc.internal.pageSize.width;
    const fullPageHeight = doc.internal.pageSize.height;
    const footerHeight = 20;
    const titleHeight = 20;
    const effectivePageHeight = fullPageHeight - footerHeight - titleHeight;

    let xPos = 0;
    let yPos = 0;
    let lastGsY = -1;
    let row = 0;
    let lastRow = 0;
    let lastYPos = 0;
    let biggestHeight = 0;

    this.addHeader(doc, parameter?.dashboard_title);
    await this.addFooter(doc);

    const orderedGridItems = Array.from(gridItems);
    orderedGridItems.sort((itemA, itemB) => {
      const gridItemA = itemA as HTMLDivElement;
      const gridItemB = itemB as HTMLDivElement;
      const gsXA = parseInt(gridItemA.getAttribute('data-gs-x'));
      const gsYA = parseInt(gridItemA.getAttribute('data-gs-y'));
      const gsXB = parseInt(gridItemB.getAttribute('data-gs-x'));
      const gsYB = parseInt(gridItemB.getAttribute('data-gs-y'));

      if (gsYA !== gsYB) return gsYA - gsYB;

      return gsXA - gsXB;
    });

    for (let i = 0; i < length; i++) {
      const gridItem = orderedGridItems[i] as HTMLDivElement;
      const gsWidth = parseInt(gridItem.getAttribute('data-gs-width'));
      const gsHeight = parseInt(gridItem.getAttribute('data-gs-height'));
      const gsX = parseInt(gridItem.getAttribute('data-gs-x'));
      const gsY = parseInt(gridItem.getAttribute('data-gs-y'));

      let isFrameExists = false;
      let iframeCanvas = null;
      const iframeElements = gridItem.querySelectorAll('iframe');
      if (iframeElements && iframeElements.length > 0) {
        isFrameExists = true;
        const iframeContent = iframeElements[0].contentDocument.body;
        iframeCanvas = await html2canvas(iframeContent, { scale: 1 });
      }

      if (lastGsY < gsY) {
        row++;
      }

      await html2canvas(gridItem, { scale: 1 }).then(
        async function (canvas) {
          let newCanvas = null;

          let width = (gsWidth / fullGridWidth) * fullPageWidth - 5;
          let scaleFactor = width / canvas.width;
          let height = canvas.height * scaleFactor;

          if (lastYPos + height > effectivePageHeight) {
            height = (gsHeight / fullGridHeight) * effectivePageHeight;
            scaleFactor = height / canvas.height;
            width = canvas.width * scaleFactor;
          }

          if (gsY !== 0 && lastGsY < gsY) {
            lastYPos += biggestHeight;
          }

          xPos = gsX === 0 ? 2.5 : fullPageWidth * (gsX / 12);
          yPos = lastYPos + row * 2.5 + titleHeight;

          if (yPos + height > effectivePageHeight + titleHeight) {
            doc.addPage();
            this.addHeader(doc, parameter?.dashboard_title);
            await this.addFooter(doc);
            row = 1;
            yPos = 2.5 + titleHeight;
            lastGsY = -1;
            lastYPos = 0;
            biggestHeight = 0;
          }

          if (isFrameExists) {
            newCanvas = document.createElement('canvas');
            newCanvas.width = canvas.width;
            newCanvas.height = canvas.height;

            const newContext = newCanvas.getContext('2d');
            newContext.drawImage(canvas, 0, 0, canvas.width, canvas.height);
            newContext.drawImage(iframeCanvas, 25, 40, iframeCanvas.width, iframeCanvas.height);
          }

          doc.addImage(
            isFrameExists ? newCanvas.toDataURL('image/png') : canvas.toDataURL('image/png'),
            'JPEG',
            xPos,
            yPos,
            width,
            height
          );

          lastGsY = gsY;
          lastRow = row;

          if (biggestHeight < height) {
            biggestHeight = height;
          }
        }.bind(this)
      );
    }

    await this.addFooter(doc);

    doc.save(`${title}.pdf`);

    this.modalDialogService.closeProgressDialog();
  }

  getChartListApi(): Observable<any> {
    return this.apiService.get(rest_api.API_CHART_LIST);
  }
  getChartWithTimestampApi(limit, time, search, search_field): Observable<any> {
    return this.apiService.get(
      `${rest_api.API_CHART_LIST}/timestamp?limit=${limit}&beforeTime=${time}&search=${search}&search_field=${search_field}`
    );
  }

  getChartWithTimestampNewApi(limit, time, search, search_field): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiService
        .get(
          `${rest_api.API_CHART_LIST}/timestamp/new?limit=${limit}&beforeTime=${time}&search=${search}&search_field=${search_field}`
        )
        .pipe()
        .subscribe({
          next: (res) => {
            resolve(res.response);
          },
          error: (err) => {
            reject(err);
          },
        });
    });
  }

  // implement pagination on chart list
  getChartWithLimitListApi(limit, page, search): Observable<any> {
    return this.apiService.get(`${rest_api.API_CHART_LIST}/pagination?page=${page}&limit=${limit}&search=${search}`);
  }

  getDashboardListApi(): Observable<any> {
    return this.apiService.get(rest_api.API_DASHBOARD_LIST);
  }

  getDashboardListWithLimitApi(limit, page, search, dashboardId?): Observable<any> {
    let dashboard_id = '';
    if (dashboardId) {
      dashboard_id = `&dashboardId=${dashboardId}`;
    }
    return this.apiService.get(
      `${rest_api.API_APPLICATION_DASHBOARD_LIST}?page=${page}&limit=${limit}&search=${search}${dashboard_id}`
    );
  }

  getDashboardWithLimitListApi(limit, page, search): Observable<any> {
    return this.apiService.get(
      `${rest_api.API_DASHBOARD_LIST}/pagination?page=${page}&limit=${limit}&search=${search}`
    );
  }

  getDashboardWithTimestampApi(limit, time, search, search_field): Observable<any> {
    return this.apiService.get(
      `${rest_api.API_DASHBOARD_LIST}/timestamp?limit=${limit}&beforeTime=${time}&search=${search}&search_field=${search_field}`
    );
  }

  // start chart detail api
  getChartDatasourceApi(): Observable<any> {
    return this.apiService.get(rest_api.CHART_DATASOURCE);
  }
  getChartDatasourceWithTimestampApi(limit, time, search, search_field): Observable<any> {
    return this.apiService.get(
      `${rest_api.CHART_DATASOURCE}/timestamp?limit=${limit}&beforeTime=${time}&search=${search}&search_field=${search_field}`
    );
  }

  getColorPalleteApi(): Observable<any> {
    return this.apiService.get(rest_api.COLOR_PALLETE);
  }

  getChartExploreApi(url: string, params: any): Observable<any> {
    return this.apiService.get(`${url}?${params}`);
  }

  deleteDashboardApi(id: string): Observable<any> {
    // return harus muncul modal alert
    return this.apiService.delete(`/api/dashboard/delete?id=${id}`);
  }

  deleteChartApi(data: any): Observable<any> {
    // return harus muncul modal alert
    return this.apiService.post(`/api/chart/delete`, data);
  }

  postShareChartApi(data: any): Observable<any> {
    // return harus muncul modal alert
    return this.apiService.post(`/api/chart/getshare/chart`, data);
  }

  postShareUrlChartApi(data: any): Observable<any> {
    // return harus muncul modal alert
    return this.apiService.post(`/api/chart/getshareurl`, data);
  }

  getChartExploreFormDataApi(url: string, data: any): Observable<any> {
    return this.apiService.post(`${url}`, data);
  }

  postDownloadChartApi(url: string, data: any): Observable<any> {
    return this.apiService.postDownload(`${url}`, data);
  }
  // end chart detail api

  // menu builder
  getApplicationListApi(): Observable<any> {
    return this.apiService.get(rest_api.API_APPLICATION_LIST);
  }
  getApplicationWithLimitListApi(limit, page, search): Observable<any> {
    return this.apiService.get(
      `${rest_api.API_APPLICATION_LIST}/list/pagination?page=${page}&limit=${limit}&search=${search}`
    );
  }
  getApplicationWithTimestampApi(limit, time, search): Observable<any> {
    return this.apiService.get(
      `${rest_api.API_APPLICATION_LIST}/list/timestamp?limit=${limit}&beforeTime=${time}&search=${search}`
    );
  }
  getApplicationByIdApi(id: string): Observable<any> {
    return this.apiService.get(`${rest_api.API_APPLICATION_LIST}/${id}`);
  }

  getInitialFilterList(datasourceId: string, column: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.get(`/api/initial-filter/${datasourceId}/${column}`)
      .pipe()
      .subscribe({
        next: (res) => {
          resolve(res.response);
        },
        error: (err) => {
          reject(err);
        },
      })
    }) 
  }
  // menu builder

  async saveChartNotification(url: string, param: any): Promise<ICommonStatusResult> {
    let result: IChartNotificationApiResponse;
    let defaultStatusResult: ICommonStatusResult = {
      success: false,
      message: this.translate.instant('MODULE.DATA_VISUAL.CHART.MESSAGE.MSG_ERR'),
    };

    const rest = await this.apiService.postApi(url, param);
    if (rest) {
      result = rest.result.response ? rest.result.response : rest.result;
    } else {
      if (rest.result.status !== 500) {
        if (rest.result.status === 0) {
          return {
            success: false,
            message: this.translate.instant('MODULE.DATA_VISUAL.CHART.MESSAGE.ERR_RTO'),
          };
        }

        return defaultStatusResult;
      } else {
        if (rest.result.hasOwnProperty('error')) {
          if (rest.result.error.message === this.translate.instant('MODULE.DATA_VISUAL.CHART.MESSAGE.MSG_F')) {
            this.apiService.openModal(
              this.translate.instant('MODULE.DATA_VISUAL.CHART.MESSAGE.F'),
              rest.result.error.message
            );

            return defaultStatusResult;
          }

          if (rest.result.statusText === 'Internal Server Error' && !rest.result.error.message) {
            return defaultStatusResult;
          }

          return {
            success: false,
            message: rest.result.error.message,
          };
        }
      }
    }

    return {
      success: true,
      payload: result,
    };
  }
}

export function initDataVisualizationService(dataVisualizationService: DataVisualizationService): () => Promise<void> {
  return () => dataVisualizationService.initService();
}
