import { Cmd, CmdIface, CmdTypes, CmdUISetting, IntoLabelGenerator } from './command.model';
import {
    ChildConnector,
    ChildConnectorIface,
    ParentConnector,
    ParentConnectorIface,
} from './connection.model';
import { CmdSlots, Slot, SlotName, SlotType } from './query.model';

const cmdType: CmdTypes = "CORRELATEREF";

interface CorrefSrcObj {
    table: string;
    cols: string;
}

interface CorrelateRefCmdProps {
    src: CorrefSrcObj;
    ref: CorrefSrcObj;
    into: string;
}

interface CorrelateRefCmdState {
    node_type: CmdTypes;
    props: CorrelateRefCmdProps;
}

class CorrelateRefCmd extends Cmd implements CmdIface {
    private state: CorrelateRefCmdState;
    private fromConnector: ChildConnectorIface;
    private refConnector: ChildConnectorIface;
    private out1Connector: ParentConnectorIface;
    private out2Connector: ParentConnectorIface;

    constructor(state?: CorrelateRefCmdState) {
        super(cmdType);

        if (state) {
            this.state = state;
        } else {
            this.state = {
                node_type: cmdType,
                props: {
                    src: {
                        table: '',
                        cols: '',
                    },
                    ref: {
                        table: '',
                        cols: '',
                    },
                    into: IntoLabelGenerator(cmdType),
                },
            };
        }
        super.SetNewProp(this.state.props);

        this.out1Connector = new ParentConnector(
            "OUTPUT1",
            "",
            () => this.state.props.src.table,
            (newLabel: string) => {},
            () => {
                this.Slots["OUTPUT1"].isChecked = true;
            },
            () => {
                this.Slots["OUTPUT1"].isChecked = false;
            },
            this
        );

        this.out2Connector = new ParentConnector(
            "OUTPUT2",
            "",
            () => this.state.props.into,
            (newLabel: string) => {},
            () => {
                this.Slots["OUTPUT2"].isChecked = true;
            },
            () => {
                this.Slots["OUTPUT2"].isChecked = false;
            },
            this
        );

        this.fromConnector = new ChildConnector(
            "INPUT1",
            "",
            (parentLabel: string) => {
                this.state.props.src.table = parentLabel;
                // this.state.props.src.cols = '';
                this.Slots["INPUT1"].isChecked = true;
            },
            () => {
                this.state.props.src.table = '';
                // this.state.props.src.cols = '';
                this.Slots["INPUT1"].isChecked = false;
            },
            (parentLabel: string) => {
                this.state.props.src.table = parentLabel;
            },
            this
        );

        this.refConnector = new ChildConnector(
            "INPUT2",
            "",
            (parentLabel: string) => {
                this.state.props.ref.table = parentLabel;
                // this.state.props.ref.cols = '';
                this.Slots["INPUT2"].isChecked = true;
            },
            () => {
                this.state.props.ref.table = '';
                // this.state.props.ref.cols = '';
                this.Slots["INPUT2"].isChecked = false;
            },
            (parentLabel: string) => {
                this.state.props.ref.table = parentLabel;
            },
            this
        );
    }

    SetIntoLabel(newLabel: string) {
        if (this.out1Connector.IsConnected()) {
            this.out1Connector.SetIntoLabel(newLabel);
        } else {
            this.state.props.into = newLabel;
        }
    }

    GetInConnector(name: SlotName, label?: string): ChildConnectorIface {
        switch (name) {
            case 'INPUT1': {
                return this.fromConnector;
            }
            case 'INPUT2': {
                return this.refConnector;
            }
        }
        return null;
    }

    GetOutConnector(name: SlotName, label?: string): ParentConnectorIface {
        switch (name) {
          case 'OUTPUT1': {
              return this.out1Connector;
          }
          case 'OUTPUT2': {
              return this.out2Connector;
          }
        }
        return null;
    }

    OnCmdDelete(): void {
        if (this.fromConnector.IsConnected()) {
            this.fromConnector.Disconnect();
        }

        if (this.refConnector.IsConnected()) {
            this.refConnector.Disconnect();
        }
      
        if (this.out1Connector.IsConnected()) {
            this.out1Connector.Disconnect();
        }

        if (this.out2Connector.IsConnected()) {
            this.out2Connector.Disconnect();
        }
    }
}

export { CorrefSrcObj, CorrelateRefCmdState, CorrelateRefCmd };
