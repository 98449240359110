// Angular
import { Component, HostBinding, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MenuConfigService } from 'src/app/libs/services';
@Component({
  selector: 'kt-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit, OnDestroy {
  @HostBinding('class') classList = 'navbar-nav right-nav ml-auto item-center';
  
  isAppPreview: boolean = false;
  mystyle: string;
  
  private subscriptions: Subscription[] = [];

  constructor(
    private menuConfigService: MenuConfigService,
    private router: Router
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.menuConfigService.getShowMenu.subscribe(
        (val) => {
          if (val) {
            this.mystyle = '35px';
          } else {
            this.mystyle = '0';
          }
        },
        (error) => console.log('Error :: ' + error)
      )
    );

    this.isAppPreview = this.router.url.includes('app_preview');
  }

  ngOnDestroy(): void {
    for (let _subscription of this.subscriptions) {
      _subscription.unsubscribe();
    }
  }
}
