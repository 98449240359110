import { axisFormater, yAxisObj, xAxisObj, generateLegendOrder, NULL_STRING_ID, NULL_REPLACE_STRING } from '.';
import { convert_metric_to_verbose } from 'src/app/libs/helpers/utility';
import { countWidthChar, getResponsiveOptions } from '../helper';

const formatLabel = (data: any, counts: any) => {
  return {
    show: data.form_data.show_bar_value || counts ? true : false,
    position:
      data.form_data.bar_stacked && !counts ? '' : data.form_data.viz_type === 'horizontal_bar' ? 'right' : 'top',
    formatter: (params: any) => {
      const count = counts[params.dataIndex];
      const seriesName = params.seriesName.split('::')[0];
      const obj = data.form_data?.format_number ? data.form_data?.format_number.find(item => item.value === seriesName) :
        {format_number: '.3s', value: seriesName};

      return counts
        ? axisFormater(count, data, obj?.format_number)
        : params.value === 0 && data.form_data.bar_stacked
        ? ''
        : axisFormater(params.value, data, obj?.format_number);
    },
    textStyle: {
      color: data.form_data.bar_stacked || counts ? null : 'inherit',
      fontSize: '9px',
    },
  };
};

const createSerie = (type: string, name: string, data: any, stack: any, label?: any, key?: string, color?: string) => ({
  type,
  name,
  data,
  stack,
  label,
  key,
  itemStyle: {
    color,
  },
  ...(type === 'line' ? { yAxisIndex: 1 } : {}),
});

const createLegend = (name: string, color?: string) => ({
  name,
  itemStyle: {
    color,
  },
});

const getMetricCounts = (metrics: any, data: any) => {
  return metrics.map((metric: string) => {
    let countItem = data.key.map((item: any) => 0);

    if (data.value[metric]) {
      if (!data.value[metric][0]) {
        Object.keys(data.value[metric]).map((group, index) => {
          countItem = data.value[metric][group].map((value: any, i: number) => countItem[i] + value);
        });
      } else countItem = data.value[metric].map((value: any, i: number) => countItem[i] + value);
    }

    return { metric, data: countItem };
  });
};

const helperPickColor = (raw, selectedValue, pickColor, serieName, item, index) => {
  const findIndex =
    raw.form_data.style_tooltips === 'axis' &&
    raw.data.key.findIndex((item: string) => {
      return item === selectedValue;
    });
  let forSelected = `${serieName}=${item}`;
  if (raw.form_data.style_tooltips === 'item') {
    return selectedValue !== '' && selectedValue !== forSelected ? `${pickColor}26` : pickColor;
  } else if (raw.form_data.style_tooltips === 'axis') {
    return findIndex >= 0 && index !== findIndex ? `${pickColor}26` : pickColor;
  }
};

const getSerieColor = (color, index, idxGroup?) => {
  return idxGroup ? color[idxGroup % color.length] : color[index];
};

const getSelectedColor = (colorpickers, metricName) => {
  const obj = colorpickers.find(item => item.entity === metricName);
  return obj.colorpicker;
}

const mappingDataToOptions = (raw: any, explore: any, color, selectedValue) => {
  let legend = [];
  let series = [];

  let metricList = raw.form_data
    ? raw.form_data.line_metric
      ? [...raw.form_data.metrics, raw.form_data.line_metric]
      : raw.form_data.metrics
    : [];
  const key = raw.form_data.groupby ? raw.form_data.groupby[0] : '';
  const fromRawData = raw.data;
  const xAxisData = fromRawData.key;
  const seriesName = [];
  const seriesData = [];

  metricList.map((metric: any, index: number) => {
    const { metric_values, metricName, serieType } = findMetricValue(raw, explore, metricList, metric, index);
    if (metric_values) {
      if (metric_values[0] === undefined) {
        Object.keys(metric_values).map((group, idxGroup) => {
          const serieName = `${metricName} - ${group}`;
          seriesName.push({
            name: serieName,
            type: serieType,
          });
          
          seriesData.push(metric_values[group]);
        });
      } else {
        const serieName = `${metricName}`;
        seriesName.push({
          name: serieName,
          type: serieType,
        });
        
        seriesData.push(metric_values);
      }
    }
  });

  const arrColor = seriesName.map((item, index) => ({ name: item.name, color: color[index] }))

  const transposedData = xAxisData.map((xAxisItem, xAxisIndex) =>
    seriesName.map((item, seriesIndex) => ({
      name: item.name,
      type: item.type,
      value: seriesData[seriesIndex][xAxisIndex],
      color: arrColor.find(el => item.name === el.name).color
    }))
  );

  if (raw.form_data.sort_bins) {
    transposedData.forEach((data) => {
      data.sort((a, b) => {
        if (a.type === 'bar' && b.type === 'bar') {
          return raw.form_data.sort_bins_value === 'asc'
            ? a.value - b.value
            : b.value - a.value;
        }
      });
    });
  }
  seriesName.map((seriesItem, seriesIndex) => {
    const stack = raw.form_data.bar_stacked && seriesItem.type === 'bar' ? 'bar' : false;
    const label = formatLabel(raw, false);
    const name = `${seriesItem.name}::${seriesItem.type}`;
    const data = transposedData.map((transpose, transposeIndex) => {
      const item = transpose[seriesIndex];
      const serieName = `${item.name}::${seriesItem.type}`;
      return {
        value: item.value,
        selected: `${serieName}=${item.value}`,
        itemStyle: { color: item.color }
      };
    })
    const serieData = createSerie(
      seriesItem.type,
      name,
      data,
      stack,
      label,
      key,
      raw.form_data.colorpickers[seriesIndex]?.colorpicker || '#808080'
    );
    const serieLegend = createLegend(name, arrColor.find(el => seriesItem.name === el.name).color);
    legend.push(serieLegend);
    series.push(serieData);
  });
  if (raw.data.value.CONST) {
    let selectedColor = getSelectedColor(raw.form_data.colorpickers, 'CONST');
    const data = raw.data.value.CONST.map((item, idx) => {
      return {
        value: item,
        selected: `CONST::line=${item}`,
        itemStyle: { color: selectedColor }
      }
    })
    const serieData = createSerie('line', 'CONST::line', data, false, '', key, selectedColor);
    const serieLegend = createLegend('CONST::line', selectedColor);
    legend.push(serieLegend);
    series.push(serieData);
  }

  if (raw.form_data.count_stacked && raw.form_data.bar_stacked) {
    const rawData = [];
    const totalData = [];

    getMetricCounts(raw.form_data.metrics, raw.data).map((item: any) => {
      rawData.push(item.data);
    });
    for (let i = 0; i < rawData[0].length; ++i) {
      let sum = 0;
      for (let j = 0; j < rawData.length; ++j) {
        sum += rawData[j][i];
      }
      totalData.push(sum);
    }
    series[series.length - 1].label = {
      show: true,
      position: 'top',
      fontSize: 12,
      color: '#8A8A8A',
      formatter: (params: any) => totalData[params.dataIndex].toFixed(1),
    };
  }

  return { series, legend };
};

const getTooltipItem = (seriesName: string, data: any, params: any, explore: any, style: string) => {
  const metricName = seriesName.split(' - ')[0];
  const groupName = seriesName.replace(`${metricName} - `, '');
  const tooltipStrip = data.form_data.tooltips[0] ? '' : '<br>-';
  let tooltip = style === 'item' ? `<b>${params.name}</b>${tooltipStrip}` : '';
  let formatSelected = data.form_data?.format_number ? data.form_data?.format_number.find(item => item.value === metricName) :
    {format_number: '.3s', value: seriesName};
  tooltip += `<br>${params.marker} ${seriesName}
        : ${axisFormater(params.data.value, data, formatSelected?.format_number)}`;

  return tooltip;
};

const formatTooltip = (params: any, data: any, explore: any, style: string) => {
  if (style === 'item') {
    const seriesName = params.data.selected.split('::')[0];
    return getTooltipItem(seriesName, data, params, explore, style);
  } else {
    const tooltipStrip = data.form_data.tooltips[0] ? '' : '<br>-';
    let tooltips = `<b>${params[0].name}</b>${tooltipStrip}`;

    for (const row of params) {
      const seriesName = row.data.selected.split('::')[0];

      const tooltip = getTooltipItem(seriesName, data, row, explore, style);
      tooltips += `${tooltip}`;
    }

    return tooltips;
  }
};

export const setConfigChartBar = (data: any, explore, selectedValue, colorPalette) => {
  const chartOption = mappingDataToOptions(data, explore, colorPalette, selectedValue);
  const yAxis = {
    name:   data.form_data.viz_type === 'horizontal_bar' ?  data.form_data?.x_axis_label : data.form_data?.y_axis_label ,
    axisLabel: {
      fontSize: 10,
      formatter: (value: any) => axisFormater(value, data, data.form_data.y_axis_format),
    },
      min:  data?.form_data?.min_range,
      max: data?.form_data?.max_range,
    ...yAxisObj,
  };

  const yAxisLine = {
    name: data.form_data.y_axis_line,
    min: data?.form_data?.y_axis_bounds_min || null,
    max: data?.form_data?.y_axis_bounds_max || null,
    axisLabel: {
      fontSize: 10,
      formatter: (value: number) => axisFormater(value, data, data.form_data.y_axis_2_format),
    },
    show: data.form_data.with_line,
    position: 'right',
    ...yAxisObj,
  };

  const dataKey = data.data.key.map((_key) => {
    let newKey = _key;
    if (_key === NULL_STRING_ID) {
      newKey = NULL_REPLACE_STRING;
    }
    return newKey;
  });

  const xAxis = {
    ...xAxisObj,
    data: dataKey,
    name: data.form_data.viz_type === 'horizontal_bar' ? data.form_data?.y_axis_label : data.form_data?.x_axis_label,
    axisLabel: {
      width: 300,
      overflow: 'truncate',
      rotate: Number(data.form_data.rotate_axis),
    },
    triggerEvent: true,
    inverse: data.form_data.viz_type === 'horizontal_bar',
  };
  const result: any = {
    tooltip: {
      trigger: data.form_data.style_tooltips,
      textStyle: { fontSize: 10 },
      formatter: (params: any) => formatTooltip(params, data, explore, data.form_data.style_tooltips),
    },
    xAxis: data.form_data.viz_type === 'horizontal_bar' ? [yAxis, yAxisLine] : xAxis,
    yAxis: data.form_data.viz_type === 'horizontal_bar' ? xAxis : [yAxis, yAxisLine],
    grid: { left: data.form_data.show_legend && 
                  data.form_data.legend_orient === 'vertical' && 
                  data.form_data.legend_position === 'left_center' ? 
                    '30%' : '10%', 
            top: '12%', 
            bottom: '16%', 
            right: '10%', 
            containLabel: true 
          },
    series: chartOption.series,
    legend: {
      ...matchingLegendOrder(data, chartOption.legend),
      formatter: (param: string) => {
        return param.split('::')[0];
      },
    },
    textStyle: {
      fontFamily: data.form_data.font_family || 'Roboto',
      fontWeight: 'bold'
    },
  };

  const { series, legend } = replacingNull(result);
  result.series = series;
  result.legend = legend;

  return result;
};

const replacingNull = (chartConfig: any) => {
  const series = chartConfig.series.map((item: any) => {
    if (item.name.includes(NULL_STRING_ID)) {
      return {
        ...item,
        name: `${item.name.replace(NULL_STRING_ID, NULL_REPLACE_STRING)}`,
        data: item.data.map((dataItem: any) => {
          if (dataItem.selected.includes(NULL_STRING_ID)) {
            return {
              ...dataItem,
              selected: `${dataItem.selected.replace(NULL_STRING_ID, NULL_REPLACE_STRING)}`
            };
          }

          return { ...dataItem };
        }),
      };
    }

    return { ...item };
  });

  const legend = {
    ...chartConfig.legend,
    data: chartConfig.legend.data.map((item: any) => {
      if (item.name.includes(NULL_STRING_ID)) {
        return {
          ...item,
          name: `${item.name.replace(NULL_STRING_ID, NULL_REPLACE_STRING)}`,
        };
      }

      return { ...item };
    }),
  };

  return { series, legend };
}

const matchingLegendOrder = (data, legend) => {
  let map = [];
  for (let i = 0; i < data.form_data.legend_order.length; i++) {
    let name = data.form_data.legend_order[i];
    let found = legend.find((obj) => obj.name.split('::')[0] === name);
    if (found){
      map.push(found);
    }
  }

  return generateLegendOrder(data, map);
};

export const findMetricValue = (raw, explore, metricList, metric, index) => {
  const metric_values = raw.data.value[metric];
  const metricName = convert_metric_to_verbose(metric, explore);
  const serieType = index === metricList.length - 1 ? (raw.form_data.line_metric ? 'line' : 'bar') : 'bar';
  return { metric_values, metricName, serieType };
};

export const setUpdateBarOptions = (echartsInstance, exploreJson, chartOption): any  => {
  let nameGapWidth: number = 0;
  const {width, gap, slice} = getResponsiveOptions(echartsInstance.getWidth());
  if (['horizontal_bar'].includes(exploreJson.form_data.viz_type)) {
    nameGapWidth = exploreJson.data.key.reduce((max, item) => {
      return Math.max(max, countWidthChar(item));
    }, 0);
  } else {
    const yAxis = echartsInstance.getModel().getComponent('yAxis', 0);
    nameGapWidth = countWidthChar(yAxis.axis.scale.getExtent()[1].toString());
  }
  const defaultWidth: number = width;

  return { 
    ...chartOption,
    ...(['horizontal_bar'].includes(exploreJson.form_data.viz_type) && exploreJson.form_data.y_axis_label && {
      yAxis: {
        ...chartOption.yAxis,
        nameLocation: 'middle',
        nameGap: (nameGapWidth > defaultWidth ? defaultWidth : nameGapWidth) + gap,
        axisLabel: {
          ...chartOption.yAxis.axisLabel,
          width: defaultWidth,
        }
      },
    }),
    ...(['dist_bar'].includes(exploreJson.form_data.viz_type) && exploreJson.form_data.y_axis_label && {
      yAxis: [
        {
          ...chartOption.yAxis[0],
          nameLocation: 'middle',
          nameGap: (nameGapWidth > defaultWidth ? defaultWidth : nameGapWidth) + gap,
          axisLabel: {
            ...chartOption.yAxis[0].axisLabel,
            width: defaultWidth,
          }
        }, 
        chartOption.yAxis[1]
      ]
    }),
    legend: {
      ...chartOption.legend,
      ...(echartsInstance.getWidth() >= 262 && {
        show: exploreJson.form_data.show_legend && true,
        formatter: (param: string) => {
          return param.split('::')[0].length > slice ? param.split('::')[0].slice(0, slice) + '...' : param.split('::')[0]; 
        }
      }),
      ...(echartsInstance.getWidth() < 262 && {
        show: false
      }),
    }
  };
}