// const host = process.env.REACT_APP_PQUI_HOST;
const localhost = "localhost:4200";
const hostname = "http://studio.paques.id:81";
const host_auth = "https://auth.paques.dev";
const host_pcc = "http://studio.paques.id:8585";

export enum PROGRESS_STATE {
  IDLE,
  IN_PROGRESS,
  DONE,
}

export enum SUBSCRIBER_STATE {
  UNCHECKED,
  CHECKED
}

export interface ISubscriberObject {
  name: string;
  state: number;
}

export const HOST = {
  HOSTNAME: hostname,
  HOST_LOCAL: localhost,
  HOST_AUTH: host_auth,
  HOST_PCC: host_pcc,
  API_HOST: window.location.origin,

  REST_PCC_CLAIM_TOKEN: `${host_pcc}/api/token`,
  REST_PCC_VERSION: `${host_pcc}/api/version`,

  REST_AUTH_LOGIN: `${host_auth}/api/v1/auth/provider/signin`,
  REST_AUTH_LOGOUT: `${host_auth}/api/v1/signout`,
  REST_AUTH_REDEEM_SESSION_TOKEN: `${host_auth}/api/v1/auth/provider/session/redeem`,
  REST_AUTH_REFRESH_TOKEN: `${host_auth}/api/v1/auth/provider/refresh-token`,
  LOG_STREAM_LIST: `/api/log-view/list`,
  HOST_MAP: '/map/{z}/{x}/{y}.png',
};

export const DATAPROCESSING_API_PATH = {
  QUERY_CREATE: `/api/query/create`,
  QUERY_EDIT: `/api/query/edit`,
  QUERY_DELETE: `/api/query/delete`,
  QUERY_LIST_TS: `/api/query/list/timestamp`,
  QUERY_METADATA_SAVE: `/api/query/metadata/save`,
  QUERY_SET_DATASET: `/api/query/setdataset`,
  QUERY_METADATA_GET: `/api/query/metadata`,
  QUERY_DATASET: `/api/dataset/query`,
  QUERY_INFO: `api/query/information`,
  EXPLORER_LIST: `/api/v2/explorer/list`,
  EXPLORER_SEARCH: `/api/v2/explorer/search`,
  EXPLORER_UPLOAD: `/api/v2/explorer/upload`,
  EXPLORER_DIRECTORY: `/api/explorer/dir`,
  EXPLORER_MOVE: `/api/v2/explorer/mv`,
  EXPLORER_COPY: `/api/v2/explorer/cp`,
  EXPLORER_REMOVE: `/api/v2/explorer/rm`,
  WORKSPACE_PARSE: `/api/query/parse-2`,
  WORKSPACE_RENDER: `/api/query/render-2`,
  HDFS_CONNECTION: `/api/hdfs-connection`,
  AWS_CONNECTION: `/api/s3-connection`,
  DB_CONNECTION: `/api/dbconfig/all`,
  DB_CONNNECTION_DELETE: `/api/dbconfig/delete`,
  NOTIFICATION: `/api/notification`,
  NOTIFICATION_DASHBOARD: `/api/notification-dashboard`,
  DB_SHOW_SCHEMA_TABLES : 'api/db-properties/show-tables',
  DB_SHOW_COLUMN : 'api/db-properties/show-columns'
};

export enum DATAPROCESSING_DATASOURCE_TYPE {
  FILE_FOLDER,
  HDFS,
  AWS,
  DATABASE,
  TERADATA
};

export enum DATAPROCESSING_WORKSPACE_MODE {
  DIAGRAM_MODE,
  PQL_MODE
}

export const DATAPROCESSING_WORKSPACE_MODE_TEXT = {
  DIAGRAM_MODE: 'Diagram Mode',
  PQL_MODE: 'PQL Mode'
};

export const ROOT_DIR = '√://';

export enum RootType {
  HDFS = 'HDFS',
  AWS = 'AWS',
  DATABASE = 'DATABASE',
  FILE_EXPLORER = 'FILE_EXPLORER'
}

export const STATIC_ROOT_DIR = {
  id: 'root-dir-id',
  name: 'Data',
  location: ROOT_DIR,
  isDir: true,
  roottype: RootType.FILE_EXPLORER,
  isRoot: true,
};

export const STATIC_ROOT_HDFS = {
  id: 'root-dir-hdfs',
  name: 'HDFS Connection',
  roottype: RootType.HDFS,
  isDir: true,
  isRoot: true,
};

export const STATIC_ROOT_AWS = {
  id: 'root-dir-aws',
  name: 'AWS Connection',
  roottype: RootType.AWS,
  isDir: true,
  isRoot: true,
};

export const STATIC_DATABASE_CONNECTION = {
  id: 'root-dir-db',
  name: 'Database Connection',
  isDir: true,
  roottype: RootType.DATABASE,
  isRoot: true,
};

export enum LOG_TYPE {
  ACCESS,
  MASTER,
  SCHEDULER
}

export const datasourceLabel = {
  mssql: 'MS SQL SERVER',
  oracle: 'ORACLE',
  postgresql: 'POSTGRE SQL',
  mysql: 'MySQL',
  db2: 'DB2',
  hdfs: 'HDFS',
  clickhouse: 'ClickHouse',
  aws: 'AWS S3 Bucket',
  teradata: 'Teradata',
  saphana: 'SAP Hana',
  cockroachdb: 'Cockroach DB'
}
