import { IMetadataColumnWrapper, IMetadataDatasetWrapper } from 'src/app/libs/types/dataprocessing';
import { isDateParser } from 'src/app/pages/pds/dataprocessing/helper';
import _ from 'lodash';

export const helperMergeMetadatas = function(firstMetadatas: IMetadataDatasetWrapper[], secondMetadatas: IMetadataDatasetWrapper[]) {
  const mergedMetadatas = secondMetadatas.map((secondMetadata: IMetadataDatasetWrapper) => {
    const firstMetadata: IMetadataDatasetWrapper = _.find(firstMetadatas, { dataset: secondMetadata.dataset });

    return firstMetadata ?? secondMetadata;
  });
  
  return mergedMetadatas;
};

export const helperSetupMetadatas = (viewData: any, existMetadata: Array<any>) => {
  const datasets = viewData.quid.source;
  const metadataExist = new Map(existMetadata.map((data) => [data.dataset, data.columns]));
  const tempMetadata = [];

  for (const datasetName of datasets) {
    const dataset = viewData.datasets[datasetName];
    const existingColumns = metadataExist.get(datasetName);

    // Skip processing if dataset is invalid
    if (!dataset?.columns?.length || !dataset?.rows?.length) continue;

    const columnsMetadata = dataset.columns.map((columnName, index) => {
      const metadata = {
        groupby: true,
        filterable: true,
        is_dttm: false,
        avg: false,
        min: false,
        max: false,
        sum: false,
        indexable: false,
        fast_index: false,
      };

      // Skip reprocessing existing columns
      if (existingColumns?.some((col: any) => col.columnName === columnName)) {
        return null;
      }

      const isNumber = dataset.rows.slice(0, 10).every((row) => !isNaN(row[index]));
      const isUTC = dataset.rows.slice(0, 10).some((row) => isDateParser(row[index]));

      metadata.avg = metadata.sum = metadata.max = metadata.min = isNumber;
      metadata.groupby = metadata.filterable = !isNumber;
      metadata.is_dttm = isUTC;

      return { columnName, metadata };
    }).filter(Boolean); // Remove null entries for existing columns

    const combinedColumns = existingColumns
      ? [...existingColumns, ...columnsMetadata]
      : columnsMetadata;

    tempMetadata.push({ dataset: datasetName, columns: combinedColumns });
  }

  return tempMetadata;
};


export const helperFetchDatasetView = async (apiService, tableActive, queryResult, defPageSize, pageIndex$) => {
  const result: ApiResult = await new Promise((resolve, reject) => {
    apiService
      .post('/api/dataset/view', {
        source: tableActive,
        quid: queryResult.quid.quid,
        size: defPageSize,
        skip: pageIndex$ * defPageSize,
      })
      .subscribe({
        next: (res) => resolve(res),
        error: (err) => reject(err),
      });
  });

  if (result && result.status === 'success') {
    return result.response;
  }
};

export const helperSetupMetadasColumn = (metadataColumn: IMetadataColumnWrapper) => {
  const tmpMetadata = {
    groupby: false,
    filterable: false,
    is_dttm: false,
    indexable: false,
    fast_index: false,
    min: false,
    max: false,
    sum: false,
    avg: false,
  };

  if (metadataColumn.metadata.groupby) {
    tmpMetadata.groupby = true;
  }

  if (metadataColumn.metadata.filterable) {
    tmpMetadata.filterable = true;
  }

  if (metadataColumn.metadata.is_dttm) {
    tmpMetadata.is_dttm = true;
  }

  if (
    (metadataColumn.metadata.avg ||
      metadataColumn.metadata.min ||
      metadataColumn.metadata.max ||
      metadataColumn.metadata.sum) &&
    !metadataColumn.metadata.is_dttm
  ) {
    tmpMetadata.min = true;
    tmpMetadata.max = true;
    tmpMetadata.avg = true;
    tmpMetadata.sum = true;
  }

  if (metadataColumn.metadata.indexable) {
    tmpMetadata.indexable = true;
  }

  if (metadataColumn.metadata.fast_index) {
    tmpMetadata.fast_index = true;
  }
  return tmpMetadata;
};

export const helperMetadaChangeData = (metadataElement: string, existingMetadata: any, isChecked: boolean) => {
  const newMetadata = {
    groupby: existingMetadata.groupby,
    filterable: existingMetadata.filterable,
    is_dttm: existingMetadata.is_dttm,
    avg: existingMetadata.avg,
    min: existingMetadata.min,
    max: existingMetadata.max,
    sum: existingMetadata.sum,
    indexable: existingMetadata.indexable,
    fast_index: existingMetadata.fast_index,
  };

  if (metadataElement === 'groupby') {
    newMetadata.groupby = isChecked;
  }

  if (metadataElement === 'filterable') {
    newMetadata.filterable = isChecked;
  }

  if (metadataElement === 'datetime') {
    newMetadata.is_dttm = isChecked;
  }

  if (metadataElement === 'aggregate') {
    newMetadata.avg = isChecked;
    newMetadata.min = isChecked;
    newMetadata.max = isChecked;
    newMetadata.sum = isChecked;
  }

  if (metadataElement === 'indexable') {
    newMetadata.indexable = isChecked;
  }

  if (metadataElement === 'fast_index') {
    newMetadata.fast_index = isChecked;
  }

  return newMetadata;
};

export const helperSetMetdataFromAPI = (apiResponse: object, datasetIds: Array<string>): IMetadataDatasetWrapper[] => {
  return Object.keys(apiResponse).map((dataset) => {
    return {
      dataset: dataset || datasetIds[0],
      columns: Object.keys(apiResponse[dataset]).map((key) => {
        let metadata = apiResponse[dataset][key];
        delete metadata.id;
        delete metadata.quid;
        delete metadata.table_id;
        delete metadata.column_name;
        delete metadata.count_distinct;
        delete metadata.verbose_name;
        return { columnName: key, metadata };
      }),
    };
  });
};
