import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ApiService, HtmlClassService, LayoutConfigService, MenuConfigService } from 'src/app/libs/services';
import { ToggleOptions } from 'src/app/libs/directives';
import { findTypeCheckByUrl } from 'src/app/libs/helpers/data-visualization-helper';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { setApplicationByIdSelector } from 'src/app/libs/store/selectors/datavisualization.selector';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/libs/store/states';

@Component({
  selector: 'kt-brand',
  templateUrl: './brand.component.html',
})
export class BrandComponent implements OnInit, OnDestroy {
  @Input() menuButtonDisplay: boolean;

  private subscriptions: Subscription[] = [];

  applicationList: Array<any> = [];
  headerLogo: string;
  headerStickyLogo: string;
  mycolor: string;
  isAppPreview: boolean = false;
  toggleOptions: ToggleOptions = {
    target: 'body',
    targetState: 'kt-aside--minimize',
    togglerState: 'kt-aside__brand-aside-toggler--active',
  };
  loading: boolean = true;

  constructor(
    private layoutConfigService: LayoutConfigService,
    public htmlClassService: HtmlClassService,
    private menuService: MenuConfigService,
    private activedRoute: ActivatedRoute,
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private _apicall: ApiService
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.headerStickyLogo = this.layoutConfigService.getStickyLogo();
    this.isAppPreview = false;

    this.subscriptions.push(
      this.store
        .select(setApplicationByIdSelector)
        .pipe()
        .subscribe((res) => {
          this.loading = true;
          let type = findTypeCheckByUrl();
          if (type === 'app_preview' && res) {
            this.headerLogo = res?.options?.topbar_option?.logo
              ? typeof res?.options?.topbar_option?.logo !== 'boolean'
                ? res?.options?.topbar_option?.logo
                : '/assets/images/logo_paques.svg'
              : '';
          }
          this.loading = false;
          this.changeDetector.detectChanges();
        })
    );

    this.subscriptions.push(
      this.activedRoute.queryParams.subscribe(async (params) => {
        let type = findTypeCheckByUrl();
        if ('link' in params && type === 'app_preview') {
          this.isAppPreview = true;
        } else {
          this.loading = false;
          this.headerLogo = this.layoutConfigService.getLogo();
        }
      })
    );
  }

  ngOnDestroy(): void {
    for (let _subscription of this.subscriptions) {
      _subscription.unsubscribe();
    }
  }

  showLeftMenu() {
    this.menuService.setLeftShowMenu(true);
  }
}
