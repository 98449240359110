<li class="dropdown nav-item">
  <a
    role="button"
    href="javascript:void(0);"
    class="dropdown nav-link"
    (click)="handleDialog()"
    matTooltip="{{ 'TOB_BAR.LABEL_THEME' | translate }}"
    #toggleButton5
  >
    <i class="zmdi zmdi-palette"></i>
  </a>
  <ul
  [ngClass]="{
    'dropdown-menu slideUp2': true,
    show: isOpen
  }"
  #menu5
  >
  <li class="body">
    <ul class="menu list-unstyled py-2">
      <li>
        <a href="#" (click)="changeSkin('dark')">
          <div class="">
            <img class="ml-2" src="./assets/images/themes/dark.png" alt="User" width="52" />
          </div>
          <div class="menu-info my-2">
            <h4>{{ 'TOB_BAR.LABEL_THEME_DARK' | translate }}</h4>
          </div>
        </a>
      </li>

      <li>
        <a href="#" (click)="changeSkin('light')">
          <div class="">
            <img class="ml-2" src="./assets/images/themes/light.png" alt="User" width="52" />
          </div>
          <div class="menu-info my-2">
            <h4>{{ 'TOB_BAR.LABEL_THEME_LIGHT' | translate }}</h4>
          </div>
        </a>
      </li>

      <li>
        <a href="#" (click)="changeSkin('navy')">
          <div class="">
            <img class="ml-2" src="./assets/images/themes/navy.png" alt="User" width="52" />
          </div>
          <div class="menu-info my-2">
            <h4>{{ 'TOB_BAR.LABEL_THEME_NAVY' | translate }}</h4>
          </div>
        </a>
      </li>

      <li>
        <a href="#" (click)="changeSkin('green')">
          <div class="">
            <img class="ml-2" src="./assets/images/themes/green.png" alt="User" width="52" />
          </div>
          <div class="menu-info my-2">
            <h4>{{ 'TOB_BAR.LABEL_THEME_GREEN' | translate }}</h4>
          </div>
        </a>
      </li>

      <li>
        <a href="#" (click)="changeSkin('blue')">
          <div class="">
            <img class="ml-2" src="./assets/images/themes/blue.png" alt="User" width="52" />
          </div>
          <div class="menu-info my-2">
            <h4>{{ 'TOB_BAR.LABEL_THEME_BLUE' | translate }}</h4>
          </div>
        </a>
      </li>

      <li>
        <a href="#" (click)="changeSkin('light-orange')">
          <div class="">
            <img class="ml-2" src="./assets/images/themes/light-orange.png" alt="User" width="52" />
          </div>
          <div class="menu-info my-2">
            <h4>{{ 'TOB_BAR.LABEL_THEME_LIGHT_ORANGE' | translate }}</h4>
          </div>
        </a>
      </li>

      <li>
        <a href="#" (click)="changeSkin('light-purple')">
          <div class="">
            <img class="ml-2" src="./assets/images/themes/light-purple.png" alt="User" width="52" />
          </div>
          <div class="menu-info my-2">
            <h4>{{ 'TOB_BAR.LABEL_THEME_LIGHT_PURPLE' | translate }}</h4>
          </div>
        </a>
      </li>
    </ul>
  </li>
  </ul>
</li>