<div class="modal-open">
	<div class="modal d-block" role="dialog">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<mat-progress-bar mode="indeterminate" *ngIf="viewLoading"></mat-progress-bar>
					<h3 class="title f-16">{{data.title}}</h3>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onNoClick()">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<form [formGroup]="changePasswordForm" autocomplete="off" class="auth_form signin-form" #f="ngForm" (ngSubmit)="onYesClick()">
						<div class="mx-1 pr-2">
								<div class="input-group">
									<div class="form-group w-100">
										<input type="email" class="form-control form-bottomline" formControlName="email" placeholder="Email" autocomplete="off">
									</div>
								</div>
								<p class="mat-error" style="font-size: 75%;" role="alert"><strong>{{getErrorText(changePasswordForm.get('email').errors)}}</strong></p>
						</div>
					</form>
				</div>
				<div class="modal-footer">
					<!-- <button class="btn btn-neutral btn-simple btn-sm close px-0" type="button" (click)="onNoClick()"> Cancel </button>
					<button class="btn btn-info btn-outline btn-sm f-12" type="button" (click)="onSubmit()"> Save </button>	 -->
					<button class="btn btn-neutral btn-simple btn-sm close px-0" type="button" (click)="onNoClick()"> Cancel </button>
					<button class="btn btn-info btn-outline btn-sm f-12" type="submit" (click)="onYesClick()" cdkFocusInitial [disabled]="viewLoading">Save Changes</button>	
				</div>
			</div>
		</div>
	</div>
</div>

<!-- <div class="kt-portlet">
	<div class="kt-portlet__head kt-portlet__head__custom" style="border-bottom: 0px solid #ebedf2!important;">
		<div class="kt-portlet__head-progress">
			here can place a progress bar
			<mat-progress-bar mode="indeterminate" *ngIf="viewLoading"></mat-progress-bar>
		</div>
		<div class="kt-portlet__head-label">
			<h3 class="kt-portlet__head-title">{{data.title}}</h3>
			<span class="kt-portlet__head-icon kt-hide">
				<i class="la la-gear"></i>
			</span>
		</div>
	</div>
	<div class="kt-form">
		<div class="kt-portlet__body">
			<div class="form-group row">
				<div class="col-lg-12">
					<form [formGroup]="changePasswordForm" autocomplete="off" class="card auth_form signin-form" #f="ngForm" (ngSubmit)="onYesClick()">
						<div class="body pb-5 mx-3">
								<div class="input-group">
									<div class="form-group w-100">
										<input type="email" class="form-control form-bottomline" formControlName="email" placeholder="Email/ Username" autocomplete="off">
									</div>
								</div>
								<p class="mat-error" style="font-size: 75%;" role="alert"><strong>{{getErrorText(changePasswordForm.get('email').errors)}}</strong></p>
						</div>
					</form>
				</div>
			</div>
		</div>
		<div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right" style="border-top: 0px solid #ebedf2">
			<div class="kt-form__actions kt-form__actions--sm">
				<button mat-button (click)="onNoClick()">Cancel</button>&nbsp;
				<button mat-button type="submit" (click)="onYesClick()" color="primary" cdkFocusInitial [disabled]="viewLoading">Save Changes</button>
			</div>
		</div>
	</div>
</div> -->
