<div>
  <div class="modal-header">
    <h5 class="title f-16" id="defaultModalLabel">
      <div *ngIf="loadingApi" class="loader loader--style1" title="0">
        <loading-button [loading]="loadingApi"></loading-button>
      </div>
      {{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.DB_CONN.LC' | translate }} {{ datasourceTypeLabel[datasourceType] }}
    </h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <form [formGroup]="datasourceFormControl">
      <input *ngIf="isEdit" type="hidden" class="form-control" formControlName="key" />
      <input type="hidden" class="form-control" formControlName="c_type" />

      <!-- Connection Name -->
      <div class="row clearfix" *ngIf="datasourceType !== 'hdfs'">
        <div class="col-md-12 form-control-label">
          <label>{{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.DB_CONN.CN' | translate }}</label>
        </div>
      </div>
      <div id="functionList" *ngIf="datasourceType !== 'hdfs'">
        <div id="functionListRow1" class="row clearfix">
          <div class="col-md-12">
            <div class="form-group">
              <input type="text" class="form-control" placeholder="name" [readonly]="isEdit" formControlName="c_name" />
              <div
                *ngIf="
                  datasourceFormControl.controls['c_name'].touched &&
                  datasourceFormControl.controls['c_name'].errors?.required
                "
              >
                <p class="text-danger" style="font-size: 11px">
                  {{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.DB_CONN.CN_V' | translate }}
                </p>
              </div>
              <div
                *ngIf="
                  datasourceFormControl.controls['c_name'].touched &&
                  datasourceFormControl.controls['c_name'].errors?.specialCharacters
                "
              >
                <p class="text-danger" style="font-size: 11px">
                  {{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.DB_CONN.CNS_V' | translate }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Host & Port -->
      <div class="row clearfix" *ngIf="datasourceType !== 'hdfs'">
        <div class="col-md-6 form-control-label">
          <label>{{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.DB_CONN.H' | translate }}</label>
        </div>
        <div class="col-md-6 form-control-label">
          <label>{{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.DB_CONN.PORT' | translate }}</label>
        </div>
      </div>
      <div id="functionList2" *ngIf="datasourceType !== 'hdfs'">
        <div id="functionListRow1" class="row clearfix">
          <div class="col-md-6">
            <div class="form-group">
              <input type="text" class="form-control" placeholder="host" formControlName="host" />
              <div
                *ngIf="
                  datasourceFormControl.controls['host'].touched &&
                  datasourceFormControl.controls['host'].errors?.required
                "
              >
                <p class="text-danger" style="font-size: 11px">
                  {{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.DB_CONN.H_V' | translate }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <input type="number" class="form-control" placeholder="port" formControlName="port" />
              <div
                *ngIf="
                  datasourceFormControl.controls['port'].touched &&
                  datasourceFormControl.controls['port'].errors?.required
                "
              >
                <p class="text-danger" style="font-size: 11px">
                  {{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.DB_CONN.PORT_V' | translate }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Username & Password -->
      <div class="row clearfix" *ngIf="datasourceType !== 'hdfs'">
        <div class="col-md-6 form-control-label">
          <label>{{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.DB_CONN.U' | translate }}</label>
        </div>
        <div class="col-md-6 form-control-label">
          <label>{{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.DB_CONN.P' | translate }}</label>
        </div>
      </div>
      <div id="functionList" *ngIf="datasourceType !== 'hdfs'">
        <div id="functionListRow3" class="row clearfix">
          <div class="col-md-6">
            <div class="form-group">
              <input type="text" class="form-control" placeholder="username" formControlName="username" />
              <div
                *ngIf="
                  datasourceFormControl.controls['username'].touched &&
                  datasourceFormControl.controls['username'].errors?.required
                "
              >
                <p class="text-danger" style="font-size: 11px">
                  {{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.DB_CONN.U_V' | translate }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <input type="password" class="form-control" placeholder="password" formControlName="password" />
              <div
                *ngIf="
                  datasourceFormControl.controls['password'].touched &&
                  datasourceFormControl.controls['password'].errors?.required
                "
              >
                <p class="text-danger" style="font-size: 11px">
                  {{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.DB_CONN.P_V' | translate }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- MYSQL, MSSQL, postgresql, DB2 -->
      <!-- Database Name -->
      <div class="row clearfix" *ngIf="['clickhouse', 'mysql', 'mssql', 'postgresql', 'db2'].includes(datasourceType)">
        <div class="col-md-12 form-control-label">
          <label>{{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.DB_CONN.DB' | translate }}</label>
        </div>
      </div>
      <div id="functionList" *ngIf="['clickhouse', 'mysql', 'mssql', 'postgresql', 'db2'].includes(datasourceType)">
        <div id="functionListRow3" class="row clearfix">
          <div class="col-md-12">
            <div class="form-group">
              <input type="text" class="form-control" placeholder="database" formControlName="database" />
            </div>
          </div>
        </div>
      </div>

      <!-- MSSQL -->
      <!-- Instance -->
      <div class="row clearfix" *ngIf="datasourceType === 'mssql'">
        <div class="col-md-12 form-control-label">
          <label>{{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.DB_CONN.I' | translate }}</label>
        </div>
      </div>
      <div id="functionList" *ngIf="datasourceType === 'mssql'">
        <div id="functionListRow3" class="row clearfix">
          <div class="col-md-12">
            <div class="form-group">
              <input type="text" class="form-control" placeholder="instance" formControlName="instance" />
            </div>
          </div>
        </div>
      </div>

      <!-- ORACLE -->
      <!-- SID -->
      <div class="row clearfix" *ngIf="datasourceType === 'oracle'">
        <div class="col-md-12 form-control-label">
          <label>{{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.DB_CONN.SID' | translate }}</label>
        </div>
      </div>
      <div id="functionList" *ngIf="datasourceType === 'oracle'">
        <div id="functionListRow3" class="row clearfix">
          <div class="col-md-12">
            <div class="form-group">
              <input type="text" class="form-control" placeholder="SID" formControlName="sid" />
            </div>
          </div>
        </div>
      </div>

      <!-- HADOOP ONLY -->
      <div class="row clearfix" *ngIf="datasourceType === 'hdfs'">
        <div class="col-md-12 form-control-label">
          <label>{{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.HDFS_CONN.CN' | translate }}<font color="red"> *</font></label>
        </div>
      </div>
      <div id="functionList" *ngIf="datasourceType === 'hdfs'">
        <div id="functionListRow3" class="row clearfix">
          <div class="col-md-12">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                placeholder="Type connection name"
                formControlName="connection_name"
                [ngClass]="
                  datasourceFormControl.controls['connection_name'].touched &&
                  datasourceFormControl.controls['connection_name'].errors?.required
                    ? 'require-error is-invalid'
                    : ''
                "
              />
              <div
                *ngIf="
                  datasourceFormControl.controls['connection_name'].touched &&
                  datasourceFormControl.controls['connection_name'].errors?.required
                "
              >
                <p class="text-danger" style="font-size: 11px">
                  {{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.HDFS_CONN.CN_V' | translate }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row clearfix" *ngIf="datasourceType === 'hdfs'">
        <div class="col-md-12 form-control-label">
          <label>{{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.HDFS_CONN.NN' | translate }}<font color="red"> *</font></label>
        </div>
      </div>
      <div id="functionList" *ngIf="datasourceType === 'hdfs'">
        <div id="functionListRow3" class="row clearfix">
          <div class="col-md-12">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                placeholder="Type node name"
                formControlName="node_name"
                [ngClass]="
                  datasourceFormControl.controls['node_name'].touched &&
                  datasourceFormControl.controls['node_name'].errors?.required
                    ? 'require-error is-invalid'
                    : ''
                "
              />
              <div
                *ngIf="
                  datasourceFormControl.controls['node_name'].touched &&
                  datasourceFormControl.controls['node_name'].errors?.required
                "
              >
                <p class="text-danger" style="font-size: 11px">
                  {{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.HDFS_CONN.NN_V' | translate }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row clearfix" *ngIf="datasourceType === 'hdfs'">
        <div class="col-md-12 form-control-label">
          <label
            >{{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.HDFS_CONN.PORT' | translate }}<font color="red"> *</font></label
          >
        </div>
      </div>
      <div id="functionList" *ngIf="datasourceType === 'hdfs'">
        <div id="functionListRow3" class="row clearfix">
          <div class="col-md-12">
            <div class="form-group">
              <input
                type="number"
                class="form-control"
                placeholder="Type port"
                formControlName="port"
                [ngClass]="
                  datasourceFormControl.controls['port'].touched &&
                  datasourceFormControl.controls['port'].errors?.required
                    ? 'require-error is-invalid'
                    : ''
                "
              />
              <div
                *ngIf="
                  datasourceFormControl.controls['port'].touched &&
                  datasourceFormControl.controls['port'].errors?.required
                "
              >
                <p class="text-danger" style="font-size: 11px">
                  {{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.HDFS_CONN.PORT_V' | translate }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row clearfix" *ngIf="datasourceType === 'hdfs'">
        <div class="col-md-12 form-control-label">
          <label>{{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.HDFS_CONN.TOKEN' | translate }}</label>
        </div>
      </div>
      <div id="functionList" *ngIf="datasourceType === 'hdfs'">
        <div id="functionListRow3" class="row clearfix">
          <div class="col-md-12">
            <div class="form-group">
              <input type="text" class="form-control" placeholder="Type token" formControlName="token_string" />
            </div>
          </div>
        </div>
      </div>

      <!-- MYSQL, postgresql, DB2 -->
      <!-- Query Strings -->
      <div class="row clearfix" *ngIf="['clickhouse', 'mysql', 'postgresql', 'db2', 'oracle','mssql'].includes(datasourceType)">
        <div class="col-md-12 form-control-label">
          <label>{{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.DB_CONN.QS' | translate }}</label>
        </div>
      </div>
      <div id="functionList" *ngIf="['clickhouse', 'mysql', 'postgresql', 'db2', 'oracle','mssql'].includes(datasourceType)">
        <div id="functionListRow1" class="row clearfix">
          <div class="col-md-12">
            <div class="form-group">
              <button
                (click)="openModalQueryString()"
                class="btn btn-info btn-outline btn-icon btn-lg btn-outline-mini w-100"
                type="button"
              >
                {{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.DB_CONN.AQS' | translate
                }}<i class="zmdi zmdi-plus f-12 ml-1"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>

    <button
      (click)="handleDatasourceConnectionTest()"
      class="btn btn-info btn-outline btn-icon btn-lg btn-outline-mini w-100"
      type="button"
    >
      <loading-button [loading]="loadingTestConn"></loading-button>
      {{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.DB_CONN.TC' | translate }}
      <i class="zmdi zmdi-input-power ml-2"></i>
    </button>
  </div>

  <div class="modal-footer">
    <button class="btn btn-info btn-outline btn-sm close" type="button" data-dismiss="modal" (click)="close()">
      {{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.C' | translate }}
    </button>
    <button
      *ngIf="datasourceType !== 'hdfs'"
      class="btn btn-neutral btn-simple btn-sm"
      type="button"
      (click)="handleDatasourceConnectionSave()"
    >
      {{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.S' | translate }}
    </button>
    <button
      *ngIf="datasourceType === 'hdfs'"
      class="btn btn-info btn-outline btn-sm"
      type="button"
      (click)="handleHdfsConnect()"
    >
      {{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.S' | translate }}
    </button>
  </div>
</div>
