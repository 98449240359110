import { Cmd, CmdIface, CmdTypes, CmdUISetting, IntoLabelGenerator } from './command.model';
import {
    ChildConnector,
    ChildConnectorIface,
    ParentConnector,
    ParentConnectorIface,
} from './connection.model';
import { CmdSlots, Slot, SlotName, SlotType } from './query.model';

const cmdType: CmdTypes = 'DROP';

interface DropCmdProps {
    from: string;
}

interface DropCmdState {
    node_type: CmdTypes;
    props: DropCmdProps;
}

class DropCmd extends Cmd implements CmdIface {
    private state: DropCmdState;
    private fromConnector: ChildConnectorIface;

    constructor(state?: DropCmdState) {
        super(cmdType);

        if (state) {
            this.state = state;
        } else {
            this.state = {
                node_type: cmdType,
                props: {
                    from: ''
                },
            };
        }
        super.SetNewProp(this.state.props);


        this.fromConnector = new ChildConnector(
            "INPUT1",
            "",
            (parentLabel: string) => {
                this.state.props.from = parentLabel;
                this.Slots["INPUT1"].isChecked = true;
            },
            () => {
                this.state.props.from = '';
                this.Slots["INPUT1"].isChecked = false;
            },
            (parentLabel: string) => {
                this.state.props.from = parentLabel;
            },
            this
        );
    }

    SetIntoLabel(newLabel: string) {}

    GetInConnector(name: SlotName, label?: string): ChildConnectorIface {
        if (name === 'INPUT1') {
            return this.fromConnector;
        }
        return null;
    }

    GetOutConnector(name: SlotName, label?: string): ParentConnectorIface {
        return null;
    }

    OnCmdDelete(): void {
        if (this.fromConnector.IsConnected()) {
            this.fromConnector.Disconnect();
        }
    }
}

export { DropCmdState, DropCmd };
