import { Injectable } from '@angular/core';
import {
  IDataView,
  IMetadataColumnWrapper,
  IMetadataDatasetWrapper,
  IMultipleMetadata,
  IPropChangeEventObject,
  IQueryInfoGeneral,
  IQueryObject,
  IQueryParams,
  IQuidObject,
  ISchedulerRefreshIntervals,
  IServiceFunctionResult,
  IServiceGetViewResult,
  IViewApiResult,
} from 'src/app/libs/types/dataprocessing';
import { ApiService } from '../../common/api.service';
import { DATAPROCESSING_API_PATH } from 'src/app/libs/consts';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/libs/store/states';
import { SetToastrMessage } from 'src/app/libs/store/actions/pds/dataprocessing.actions';
import { BehaviorSubject, filter, lastValueFrom, switchMap, take, takeWhile, timer, fromEvent, map, merge, startWith, distinctUntilChanged, takeUntil, catchError, throwError, Subject } from 'rxjs';
import {
  helperSetMetdataFromAPI,
  helperMergeMetadatas,
  helperSetupMetadasColumn,
  helperSetupMetadatas,
} from 'src/app/components/workspaces/data-processing-result/helper.dataprocessing_result';
import { WorkspaceState } from 'src/app/libs/models/query/workspace.model';
import { DataProcessingDataQueryService } from './data-query.service';
import { UserSelector } from 'src/app/libs/store/selectors/authentication.selectors';
import _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class DataProcessingQueryDatasetService {
  private _dataQuery: any;
  private _isMetadatasChangedAfterMerged: boolean = false;
  private _dataViewSubject$: BehaviorSubject<IDataView> = new BehaviorSubject<IDataView>(null);
  private _metadatasSubject$: BehaviorSubject<IMetadataDatasetWrapper[]> = new BehaviorSubject<
    IMetadataDatasetWrapper[]
  >(null);
  private _multipleMetadataSubject$: BehaviorSubject<IMultipleMetadata> = new BehaviorSubject<IMultipleMetadata>(null);
  private _pqlStringSubject$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _isEditPQL$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _refreshIntervals: ISchedulerRefreshIntervals;
  private _previewStatusSubject$: BehaviorSubject<string> = new BehaviorSubject<any>('');
  private _runStatusSubject$: BehaviorSubject<string> = new BehaviorSubject<any>('');
  private _hasRunQuerySubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _propChangedSubject$: BehaviorSubject<IPropChangeEventObject> = new BehaviorSubject<IPropChangeEventObject>(
    null
  );
  private _titleSubject$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _hasSaveQuerySubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _managerCount: number = 0;
  private _filterQueryBy: string = '';
  private _queryInfoGeneralSubject$: BehaviorSubject<IQueryInfoGeneral> = new BehaviorSubject(null);

  constructor(
    private apiService: ApiService,
    private dataProcessingDataQueryService: DataProcessingDataQueryService,
    private store: Store<AppState>,
    private translate: TranslateService
  ) {}

  resetQueryDataset() {
    this._isEditPQL$.next(false);
    this._dataQuery = null;
    this._pqlStringSubject$.next('');
    this._dataViewSubject$.next(null);
    this._hasRunQuerySubject$.next(false);
    this._hasSaveQuerySubject$.next(false);
    this._refreshIntervals = null;
    this._titleSubject$.next(this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.U'));
  }

  setIsMetadatasChangedAfterMerged(status: boolean): void {
    this._isMetadatasChangedAfterMerged = status;
  }

  setDataQuery(dataQuery: any): void {
    this._dataQuery = dataQuery;
  }

  setFilterQueryBy(value: string): void {
    this._filterQueryBy = value;
  }

  setIsEditPQL(status: boolean): void {
    this._isEditPQL$.next(status);
  }

  setPqlString(pqlString: string): void {
    this._pqlStringSubject$.next(pqlString);
  }

  setRefreshIntervals(refreshIntervals: ISchedulerRefreshIntervals): void {
    this._refreshIntervals = refreshIntervals;
  }

  setPreviewStatus(status: string): void {
    this._previewStatusSubject$.next(status);
  }

  setRunStatus(status: string): void {
    this._runStatusSubject$.next(status);
  }

  setDataView(dataView: IDataView): void {
    this._dataViewSubject$.next(dataView);
  }

  setMetadatas(metadatas: IMetadataDatasetWrapper[]): void {
    this._metadatasSubject$.next(metadatas);
  }

  setMultiMetadataSubject(multipleMetadata: IMultipleMetadata) {
    this._multipleMetadataSubject$.next(multipleMetadata);
  }

  setHasRunQuery(status: boolean): void {
    this._hasRunQuerySubject$.next(status);
  }

  setHasSaveQuery(status: boolean): void {
    this._hasSaveQuerySubject$.next(status);
  }

  setPropChanged(propChanged: IPropChangeEventObject) {
    this._propChangedSubject$.next(propChanged);
  }

  setTitle(title: string): void {
    this._titleSubject$.next(title);
  }

  async setManagerCount(managerCount?: number) {
    this._managerCount = managerCount;
  }

  getManagerCount(): number {
    return this._managerCount;
  }

  getDataQuery(): any {
    return this._dataQuery;
  }

  getFilterQueryBy(): string {
    return this._filterQueryBy;
  }
  getIsEditPQLSubject(): BehaviorSubject<boolean> {
    return this._isEditPQL$;
  }

  getIsEditPQL(): boolean {
    return this._isEditPQL$.getValue();
  }

  getPqlStringSubject(): BehaviorSubject<string> {
    return this._pqlStringSubject$;
  }

  getPqlString(): string {
    return this._pqlStringSubject$.getValue();
  }

  getRefreshIntervals(): ISchedulerRefreshIntervals {
    return this._refreshIntervals;
  }

  getPreviewStatusSubject(): BehaviorSubject<string> {
    return this._previewStatusSubject$;
  }

  getPreviewStatus(): string {
    return this._previewStatusSubject$.getValue();
  }

  getRunStatusSubject(): BehaviorSubject<string> {
    return this._runStatusSubject$;
  }

  getRunStatus(): string {
    return this._runStatusSubject$.getValue();
  }

  getDataViewSubject(): BehaviorSubject<IDataView> {
    return this._dataViewSubject$;
  }

  getMetadatas(): IMetadataDatasetWrapper[] {
    return this._metadatasSubject$.getValue();
  }

  getMetadatasSubject(): BehaviorSubject<IMetadataDatasetWrapper[]> {
    return this._metadatasSubject$;
  }

  getMultiMetadataSubject(): BehaviorSubject<IMultipleMetadata> {
    return this._multipleMetadataSubject$;
  }

  getHasRunQuerySubject(): BehaviorSubject<boolean> {
    return this._hasRunQuerySubject$;
  }

  getHasRunQuery(): boolean {
    return this._hasRunQuerySubject$.getValue();
  }

  getHasSaveQuery(): boolean {
    return this._hasSaveQuerySubject$.getValue();
  }

  getPropChangedSubject(): BehaviorSubject<IPropChangeEventObject> {
    return this._propChangedSubject$;
  }

  getPropChanged(): IPropChangeEventObject {
    return this._propChangedSubject$.getValue();
  }

  getTitleSubject(): BehaviorSubject<string> {
    return this._titleSubject$;
  }

  getTitle(): string {
    return this._titleSubject$.getValue();
  }

  getQueryInfoGeneralSubject(): BehaviorSubject<IQueryInfoGeneral> {
    return this._queryInfoGeneralSubject$;
  }

  getHasQueryInfoGeneralSubject(){
    return this._queryInfoGeneralSubject$.getValue();
  }

  fetchQueryInfoGeneral(): Promise<void> {
    return new Promise<void>((resolve) => this.apiService.get(
        DATAPROCESSING_API_PATH.QUERY_INFO,
      )
        .subscribe({
          next: (res) => {
            this._queryInfoGeneralSubject$.next(res?.response?.managerCount);
            resolve();
          },
          error: (err) => {
            throw new Error(err);
          },
        })
    );
  }

  async getView(
    datasetView: any,
    isNewQuery: boolean = false,
    isDetail: boolean = false,
    isGetMetadataFromAPI: boolean = true
  ): Promise<IServiceGetViewResult> {
    try {
      const online$ = fromEvent(window, 'online').pipe(map(() => true));
      const offline$ = fromEvent(window, 'offline').pipe(map(() => false));
      const networkStatus$ = merge(online$, offline$).pipe(
        startWith(navigator.onLine),
        distinctUntilChanged()
      );

      const viewResult: IViewApiResult = await new Promise<ApiResult>((resolve, reject) => {
        let subscription;
        const timer$ = timer(0, 1000).pipe(
          takeUntil(networkStatus$.pipe(filter(online => !online))),
        );

        subscription = timer$.pipe(
            switchMap(() => this.apiService.post(`/api/dataset/view`, datasetView).pipe(
              catchError(err => {
                subscription.unsubscribe();
                return throwError(() => err);
              })
            )),
            filter((res: any) => res.response.quid.done === true || res.response.quid.state === 'error'),
            takeWhile((res: any) => res.response.quid.done === false && res.response.quid.state !== 'error', true)
          )
          .subscribe({
            next: (res) => resolve(res),
            error: (err) => reject(err),
          });
      });

      if (viewResult?.response?.quid?.state === 'error') {
        let errorMsg = 'ERROR!';
        switch (viewResult?.response?.quid?.message) {
          case 'illegal type conversion':
            errorMsg = `${this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.ITC')}`;
            break;
          case 'not matched width':
            errorMsg = `${this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.SCNM')}`;
            break;
          case 'sources having same column name and type that can be merged':
            errorMsg = `${this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.ERR_GENERAL')}`;
            break;
          default:
            errorMsg = `${this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.ERR_GENERAL')}`;
            break;
        }

        return {
          success: false,
          message: errorMsg,
        };
      }

      if (viewResult?.response) {
        if (!isNewQuery && !isDetail) {
          this._updateDatasetsOnDataQuery(viewResult?.response?.datasets);
        }
        const compiledMetadatas = await this._setMetadataField(viewResult?.response, isGetMetadataFromAPI);
        if (compiledMetadatas.success) {
          return {
            success: true,
            payload: { ...viewResult?.response, ...compiledMetadatas.payload },
          };
        }

        return compiledMetadatas;
      }
    } catch (e) {
      return {
        success: false,
        message: e.message,
      };
    }
  }

  async run(title: string, isPreview: boolean): Promise<IServiceFunctionResult> {
    try {
      const pqlString = this._pqlStringSubject$.getValue();
      const strRegex = new RegExp(/\n/gi);
      const strippedPqlString = pqlString.replace(strRegex, ' ');

      const queryParams = {
        query: strippedPqlString,
        title: title,
        query_preview: isPreview,
        managerCount: this._managerCount
      };

      if (this._dataQuery?.ID) {
        queryParams['userQueryID'] = this._dataQuery?.ID;
      }
      
      const queryDatasetResult: ApiResult = await new Promise((resolve, reject) => {
        this.apiService.post(DATAPROCESSING_API_PATH.QUERY_DATASET, queryParams).subscribe({
          next: (res) => resolve(res),
          error: (err) => reject(err),
        });
      });

      if (queryDatasetResult) {
        if (!this._dataQuery) {
          await this._setupNewDataQuery(queryDatasetResult?.response?.quid);
          this.dataProcessingDataQueryService.setIsDataQueryToList(true);
        }

        const managerCount = queryDatasetResult?.response?.quid?.managerCount;
        this._managerCount = managerCount; 

        this.setDataQuery({
          ...this._dataQuery,
          ID: queryDatasetResult?.response?.quid?.userQueryID,
          quid: queryDatasetResult?.response?.quid?.quid,
          title,
          managerCount,
        });

        const viewResult: IServiceFunctionResult = await this.getView(
          {
            quid: queryDatasetResult?.response?.quid?.quid,
            size: 25,
          },
          !!this._dataQuery?.ID,
          false,
          false
        );

        if (queryDatasetResult.status === 'warning'){
          this._multipleMetadataSubject$.next(viewResult.payload.multipleMetadata);
          return {...viewResult, message : queryDatasetResult.message };
        } else if (viewResult?.success) {
          this._multipleMetadataSubject$.next(viewResult.payload.multipleMetadata);
          return viewResult;
        } else {
          return {
            success: false,
            payload: viewResult?.message,
          } as IServiceFunctionResult;
        }
      }

      return {
        success: false,
        payload: 'EMPTY QUERY DATASET!',
      } as IServiceFunctionResult;
    } catch (e) {
      return {
        success: false,
        payload: e.message,
      } as IServiceFunctionResult;
    }
  }

  private async _setupNewDataQuery(refObject: IQueryObject | IQuidObject): Promise<void> {
    if (!refObject) return;

    const isQuidObject = !!(refObject as IQuidObject).userQueryID;
    const user = await lastValueFrom(this.store.select(UserSelector).pipe(take(1)));

    this._dataQuery = {
      ID: isQuidObject ? (refObject as IQuidObject).userQueryID : (refObject as IQueryObject).ID,
      userID: user?.uuid,
      first_quid: '',
      quid: refObject.quid,
      title: this._dataQuery?.title
        ? this._dataQuery?.title
        : this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.U'),
      refreshInterval: {
        Expr: '',
        Basic: 0,
        Advance: '0001-01-01T00:00:00Z',
      },
      lastRun: refObject.createdDate,
      createdDate: refObject.createdDate,
      updatedDate: refObject.updatedDate,
      activeDatasetAlias: {},
      datasetAlias: {},
      advanceMode: this._isEditPQL$.getValue(),
      managerCount: this._managerCount,
    } as IQueryObject;
  }

  private _updateDatasetsOnDataQuery(datasets: any) {
    if (!datasets) return;

    const datasetAlias: any = {};
    const datasetKeys = Object.keys(datasets);
    for (const datasetKey of datasetKeys) {
      datasetAlias[datasetKey] = datasets[datasetKey]?.source;
    }
    this.setDataQuery({
      ...this._dataQuery,
      activeDatasetAlias: datasetAlias,
      datasetAlias,
    })
  }

  private async _setMetadataField(viewData: any, isGetMetadataFromAPI: boolean): Promise<IServiceFunctionResult> {
    try {
      let mergedMetadatas = [];

      const generatedMetadatas = helperSetupMetadatas(viewData, this.getMetadatas());
      mergedMetadatas = helperMergeMetadatas(mergedMetadatas, generatedMetadatas);
      let fetchedMetadatas: IMetadataDatasetWrapper[] = [];
      if (isGetMetadataFromAPI) {
        const datasetIds = Object.keys(viewData.datasets);
        const userQueryID = viewData.quid.userQueryID;

        const metadataResult: ApiResult = await new Promise((resolve, reject) => {
          this.apiService.post(DATAPROCESSING_API_PATH.QUERY_METADATA_GET, { userQueryID }, true).subscribe({
            next: (res) => resolve(res),
            error: (err) => reject(err),
          });
        });

        fetchedMetadatas = helperSetMetdataFromAPI(metadataResult?.response, datasetIds);
        mergedMetadatas = helperMergeMetadatas(fetchedMetadatas, mergedMetadatas);
      }

      if (this._isMetadatasChangedAfterMerged) {
        const inputMetadatas = this.getMetadatas();
        mergedMetadatas = helperMergeMetadatas(inputMetadatas, mergedMetadatas);
        this._isMetadatasChangedAfterMerged = false;
      }

      const multiMetadata = this.convertToMultiMetadata(mergedMetadatas);

      return {
        success: true,
        payload: {
          metadatas: mergedMetadatas,
          multipleMetadata: multiMetadata,
        },
      } as IServiceFunctionResult;
    } catch (e) {
      return {
        success: false,
        payload: e.message,
      } as IServiceFunctionResult;
    }
  }

  initiateMultiMetadata(metadatas: IMetadataDatasetWrapper[]): void {
    const multipleMetadata = this.convertToMultiMetadata(metadatas);
    this._multipleMetadataSubject$.next(multipleMetadata)
  }

  convertToMultiMetadata(metadatas: IMetadataDatasetWrapper[]) {
    if (metadatas) {
      const multipleMetadata = {};

      metadatas.forEach((datasetMetadata: IMetadataDatasetWrapper) => {
        if (!multipleMetadata[datasetMetadata.dataset]) {
          multipleMetadata[datasetMetadata.dataset] = {};
        }

        datasetMetadata.columns.forEach((metadataColumn: IMetadataColumnWrapper) => {
          multipleMetadata[datasetMetadata.dataset][metadataColumn.columnName] = metadataColumn.metadata;
        });
      });

      return multipleMetadata
    }
  }

  async save(
    title: string,
    workspaceState: WorkspaceState,
    pql: string,
    refreshIntervals: ISchedulerRefreshIntervals
  ): Promise<IServiceFunctionResult> {
    try {
      const params: IQueryParams = {
        advanceMode: this._isEditPQL$.getValue(),
        model: JSON.stringify(workspaceState),
        title: title,
        pql: pql,
        managerCount: this._managerCount,
      };

      if (this._dataQuery?.ID) {
        const saveResult: ApiResult = await new Promise<ApiResult>((resolve, reject) => {
          this.apiService
            .post(
              DATAPROCESSING_API_PATH.QUERY_EDIT,
              {
                ...params,
                id: this._dataQuery.ID,
                quid: this._dataQuery.quid,
                refreshIntervals,
              },
              true
            )
            .subscribe({
              next: (res) => resolve(res),
              error: (err) => reject(err),
            });
        });

        if (this._dataQuery?.quid && saveResult?.response) {
          await this.saveDataSet(saveResult.response);
          await this.saveMetaData(this._dataQuery?.ID);
          this.setDataQuery({
            ...this._dataQuery,
            title: title,
            refreshInterval: refreshIntervals && refreshIntervals[0],
            additionalRefreshInterval: saveResult.response?.data?.additionalRefreshInterval
              ? saveResult.response?.data?.additionalRefreshInterval
              : [],
            model: saveResult.response?.data?.model,
            pql: saveResult.response?.data?.pql,
            managerCount: saveResult.response?.data?.managerCount,
            lastRun: saveResult.response?.data?.lastRun,
            createdDate: saveResult.response?.data?.createdDate,
            updatedDate: saveResult.response?.data?.updatedDate,
            advanceMode: this._isEditPQL$.getValue(),
          });
        }

        return { success: true };
      } else {
        const saveResult: ApiResult = await new Promise((resolve, reject) => {
          this.apiService.post(DATAPROCESSING_API_PATH.QUERY_CREATE, params, true).subscribe({
            next: (res) => resolve(res),
            error: (err) => reject(err),
          });
        });

        if (saveResult?.response) {
          this._dataQuery = { ...saveResult.response.data };
          this.dataProcessingDataQueryService.setIsDataQueryToList(true);

          return { success: true };
        }
      }
    } catch (e) {
      return {
        success: false,
        payload: e.message,
      };
    }
  }

  async saveDataSet(param: any): Promise<void> {
    await new Promise((resolve) => {
      this.apiService
        .post(
          DATAPROCESSING_API_PATH.QUERY_SET_DATASET,
          {
            id: param.id,
            quid: this._dataQuery.quid,
          },
          true
        )
        .subscribe({
          next: (res) => resolve(res),
          error: (err) => {
            this.store.dispatch(
              SetToastrMessage({
                toastrMessage: {
                  toastrType: 'error',
                  message: err.message,
                },
              })
            );
          },
        });
    });
  }

  async saveMetaData(userQueryID: string): Promise<void> {
    const multipleMetadata = this._multipleMetadataSubject$.getValue();
    this.setDataQuery({
      ...this._dataQuery,
      activeDatasetAlias: this.setupDataAliasesFromMultiMetadata(multipleMetadata),
      datasetAlias: this.setupDataAliasesFromMultiMetadata(multipleMetadata),
    });
    for (const dataset in multipleMetadata) {
      const columns = multipleMetadata[dataset];

      await new Promise((resolve, reject) => {
        this.apiService
          .post(
            DATAPROCESSING_API_PATH.QUERY_METADATA_SAVE,
            {
              data: columns,
              quid: this._dataQuery.quid,
              userQueryID,
              table_id: dataset,
            },
            true
          )
          .subscribe({
            next: (res) => resolve(res),
            error: (err) => {
              this.store.dispatch(
                SetToastrMessage({
                  toastrMessage: {
                    toastrType: 'error',
                    message: err.message,
                  },
                })
              );

              reject(err);
            },
          });
      });
    }
  }

  private setupDataAliasesFromMultiMetadata(datasets: any) {
    if (!datasets) return;

    const datasetAlias: any = {};
    const datasetKeys = Object.keys(datasets);
    for (const datasetKey of datasetKeys) {
      datasetAlias[datasetKey] = datasetKey;
    }
    return datasetAlias;
  }

  async getConnectionList(): Promise<ApiResult> {
    return await new Promise<ApiResult>((resolve, reject) => {
      this.apiService.gets(DATAPROCESSING_API_PATH.DB_CONNECTION, { type: 'all' }).subscribe({
        next: (res) => resolve(res),
        error: (err) => {
          this.store.dispatch(
            SetToastrMessage({
              toastrMessage: {
                toastrType: 'error',
                message: err.message || this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.PF'),
              },
            })
          );

          reject(err);
        },
      });
    });
  }

  async getShemaList(key: string, owner: string): Promise<ApiResult> {
    return await new Promise<ApiResult>((resolve, reject) => {
      this.apiService.gets(DATAPROCESSING_API_PATH.DB_SHOW_SCHEMA_TABLES, { key, owner }).subscribe({
        next: (res) => resolve(res),
        error: (err) => {
          reject(err);
        },
      });
    });
  }
}
