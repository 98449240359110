import { createAction, props } from '@ngrx/store';

export enum DataVisualizationActionTypes {
  GetDashboardList = '[GetDashboard] Action',
  GetDashboardListSuccess = '[GetDashboardSuccess] Action',
  GetDashboardListFailed = '[GetDashboardFailed] Action',
  SetExtraFilter = '[SetExtraFilter] Action',
  setSelectedValueChart = '[setSelectedValueChart] Action',
  setApplicationById = '[setApplicationById] Action',
}
export const GetDashboardList = createAction(DataVisualizationActionTypes.GetDashboardList);
export const GetDashboardListSuccess = createAction(DataVisualizationActionTypes.GetDashboardListSuccess, props<any>());
export const GetDashboardListFailed = createAction(DataVisualizationActionTypes.GetDashboardListFailed, props<any>());
export const SetExtraFilter = createAction(DataVisualizationActionTypes.SetExtraFilter, props<{ extraFilter: any }>());
export const setSelectedValueChart = createAction(
  DataVisualizationActionTypes.setSelectedValueChart,
  props<{ item: string }>()
);
export const setApplicationById = createAction(DataVisualizationActionTypes.setApplicationById, props<{ item: any }>());
export const SetActiveChart = createAction(DataVisualizationActionTypes.SetExtraFilter, props<{ activeChart: any }>());
