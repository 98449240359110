const generateDataWordcloud = (data) => {
  return data.map((data) => {
    return { value: data.size, name: data.text };
  });
};
export const setConfigChartWordcloud = (data: any, color: any) => {
  return {
    // ======================================= START WORDCLOUD ==========================================================
    tooltip: {},
    series: [
      {
        type: 'wordCloud',
        sizeRange: [12, data.form_data.font_size],
        rotationRange: [0, 0],
        gridSize: 5,
        width: '100%',
        height: '90%',
        bottom: '15%',
        top: '0',
        shape: data.form_data.rotation ? data.form_data.rotation : 'circle',
        drawOutOfBound: false,
        keepAspect: true,
        textStyle: {
          fontFamily: data.form_data.font_family || 'Roboto',
          fontWeight: 'bold',
          color: function (params) {
            return color[params.dataIndex];
          },
        },
        emphasis: {
          textStyle: {
            color: '#534D59',
          },
        },
        data: generateDataWordcloud(data.data),
      },
    ],
    // ======================================= END WORDCLOUD ==========================================================
  };
};
