import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'loading-button',
  templateUrl: './loading-button.html',
})
export class LoadingButtonComponent implements OnInit {
  @Input() public loading;

  ngOnInit() {}
}
