export const setConfigNumericalFilter = (data: any) => {	
	const form_data = data.form_data;	
	const step = getStepValue(form_data.num_max);
	const tickStep = Math.ceil(form_data.num_max / 10);

  return {
		slider: {
			value: form_data.min_value ? form_data.min_value: form_data.num_min,
			highValue: form_data.max_value ? form_data.max_value: form_data.num_max,
			options: {
				floor: form_data.default_range_min ?? form_data.num_min,
				ceil: form_data.default_range_max ?? form_data.num_max,
				step: step,
				tickStep: tickStep,
				// showTicksValues: true,
				// tickValueStep: tickStep
			}
		}
  };
};

const getStepValue = (value: number): number => {
  const decimalPlaces = value.toString().split('.')[1]?.length || 0;
  return Math.pow(10, -decimalPlaces);
}