import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { ApiService } from '../services';
import { rest_api } from '../configs';

@Injectable({
  providedIn: 'root'
})
export class AppRedirectionGuard implements CanActivate {
  constructor(private router: Router, private apiService: ApiService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const type = route.params['type'];
    const shortenedToken = route.params['shortenedToken'];
 
    if (type === 'pdsshare'){

      const theme = new URLSearchParams(window.location.hash).get('theme');
      const cleanToken = shortenedToken.split('&theme')[0]
    
      const resp = await this.apiService.getApi(`api/dashboard/shortened/${cleanToken}`);
      const urlPath = decodeURIComponent(resp?.result?.response);
      const cleanedUrl = urlPath.replace(/^\/#\//, "");

      this.router.navigateByUrl(cleanedUrl+'&theme='+theme);

    } else if (type ==='sharevisualization') {
      
      const resp = await this.apiService.getApi(`api/chart/shortened/${shortenedToken}`);
      const urlPath = decodeURIComponent(resp?.result?.response);
      const cleanedUrl = urlPath.replace(/^#\//, "");
      this.router.navigateByUrl(cleanedUrl);

    } else {
      const resp = await this.apiService.getApi(`${rest_api.API_APPLICATION_LIST}/shortened/${shortenedToken}`);
      const urlPath = decodeURIComponent(resp?.result?.response);
      
      this.router.navigateByUrl(urlPath);
    }


    return false;
  }
  
}
