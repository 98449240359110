import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { select, Store } from '@ngrx/store';

import { ApiService } from './api.service';
import { AppState } from 'src/app/libs/store/states';
import { User } from 'src/app/libs/models/common';

import { UserSelector } from '../../store/selectors/authentication.selectors';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private user: User;
  public notifData = new BehaviorSubject(null);

  constructor(
    private _apicall: ApiService,
    private store: Store<AppState>,
  ) {
    this.store.pipe(select(UserSelector)).subscribe(user => this.user = user);
  }

  async getApi(url) {
    let a = await this._apicall
      .get(url)
      .toPromise()
      .then((_) => {
        return _;
      })
      .catch((err) => {
        console.log(err);
      });
    return a;
  }

  get getNotif() {
    return this.notifData.asObservable();
  }

  async setNotif() {
    return null;
  }
}
