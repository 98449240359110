import { Cmd, CmdIface, CmdTypes, IntoLabelGenerator } from './command.model';
import { ChildConnector, ChildConnectorIface, ParentConnector, ParentConnectorIface } from './connection.model';
import { SlotName } from './query.model';

const cmdType: CmdTypes = 'CREATEINDEX';

interface CreateIndexCmdProps {
  cols: string;
  path: string;
}

interface CreateIndexCmdState {
  node_type: CmdTypes;
  props: CreateIndexCmdProps;
}

class CreateIndexCmd extends Cmd implements CmdIface {
  private state: CreateIndexCmdState;

  constructor(state?: CreateIndexCmdState) {
    super(cmdType);

    if (state) {
      this.state = state;
    } else {
      this.state = {
        node_type: cmdType,
        props: {
          cols: '',
          path: '',
        },
      };
    }
    super.SetNewProp(this.state.props);
  }

  SetIntoLabel(newLabel: string) {}

  GetInConnector(name: SlotName, label?: string): ChildConnectorIface {
    return null;
  }

  GetOutConnector(name: SlotName, label?: string): ParentConnectorIface {
    return null;
  }

  OnCmdDelete(): void {
    
  }
}

export { CreateIndexCmdState, CreateIndexCmd };
