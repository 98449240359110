import { Component, EventEmitter, OnInit, Output, Input, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'query-string-modal',
  templateUrl: './query-string-modal.component.html',
})
export class QueryStringModalComponent implements OnInit {
  @Input() list: any;

  @Output() close = new EventEmitter();
  @Output() getDatasourceList = new EventEmitter();

  api_queryStrings: Array<any> = [];
  validationErrors: Array<{ property: boolean, value: boolean }> = [];

  constructor(private cdr: ChangeDetectorRef, public activeModal: NgbActiveModal) {}

  ngOnInit() {
    if (this.list) {
      this.api_queryStrings = this.list;
    }
    this.initializeValidationErrors();
  }

  initializeValidationErrors() {
    this.validationErrors = this.api_queryStrings.map(() => ({ property: false, value: false }));
  }

  validateFields() {
    let isValid = true;
    this.validationErrors = this.api_queryStrings.map(item => {
      const propertyError = !item.property || item.property.trim() === '';
      const valueError = !item.value || item.value.trim() === '';
      if (propertyError || valueError) {
        isValid = false;
      }
      return { property: propertyError, value: valueError };
    });
    this.cdr.detectChanges();
    return isValid;
  }

  _save(){
    if (this.validateFields()) {
      this.activeModal.close(this.api_queryStrings);
    }
  }

  _close() {
    this.activeModal.close();
  }

  onAddQueryStringsApi() {
    this.api_queryStrings.push({
      property: '',
      value: '',
    });
    this.validationErrors.push({ property: false, value: false })
    this.cdr.detectChanges();
    return;
  }

  onDelQueryStringsApi(index) {
    this.api_queryStrings.splice(index, 1);
    this.validationErrors.splice(index, 1);
  }
}
