import { ChangeDetectorRef, Component, Input, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import {
  LayoutConfigService,
  TranslationService,
  JsonService,
  DataProcessingWorkspaceService,
  DataProcessingQueryDatasetService,
  DataVisualizationService,
} from 'src/app/libs/services';
import { LayoutConfigModel } from 'src/app/libs/models/common';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/libs/store/states';
import { UserSelector } from 'src/app/libs/store/selectors/authentication.selectors';
import { setApplicationByIdSelector } from 'src/app/libs/store/selectors/datavisualization.selector';
import { findTypeCheckByUrl } from 'src/app/libs/helpers/data-visualization-helper';
import { GeneralSaveTriggerActions } from 'src/app/libs/store/actions/general.actions';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmActionComponent } from 'src/app/components/modals/modalConfirmAction/modal-confirm-action..component';

@Component({
  selector: 'pq-setting',
  templateUrl: './setting.component.html',
})
export class SettingComponent implements OnInit {
  @Input() isHideUserManagement;
  @ViewChild('toggleButton2') toggleButton: ElementRef;
  @ViewChild('menu2') menu: ElementRef;

  isAppPreview: boolean = false;
  isOpen: boolean = false;
  model: LayoutConfigModel;
  user: any;
  isAdministrator: boolean = false;
  isThemeSetting: boolean = true;
  modalReference: NgbModalRef;
  constructor(
    private layoutConfigService: LayoutConfigService,
    private translationService: TranslationService,
    private jsonService: JsonService,
    private router: Router,
    public cdRef: ChangeDetectorRef,
    private store: Store<AppState>,
    private renderer: Renderer2,
    private modalService: NgbModal,
    private dataProcessingWorkspaceService: DataProcessingWorkspaceService,
    private dataProcessingQueryDatasetService: DataProcessingQueryDatasetService,
    private dataVisualizationService: DataVisualizationService
  ) {
    this.renderer.listen('window', 'click', (e: Event) => {
      /**
       * Only run when toggleButton is not clicked
       * If we don't check this, all clicks (even on the toggle button) gets into this
       * section which in the result we might never see the menu open!
       * And the menu itself is checked here, and it's where we check just outside of
       * the menu and button the condition abbove must close the menu
       */
      if (!this.toggleButton.nativeElement.contains(e.target) && !this.menu.nativeElement.contains(e.target)) {
        this.isOpen = false;
        this.cdRef.detectChanges();
      }
    });

    this.store.select(UserSelector).subscribe((res) => {
      if (res) {
        this.user = res;
        if (this.user.scopes) {
          this.isAdministrator = Object.keys(this.user.scopes).indexOf('administration') >= 0;
        }
      }
    });
    this.store
      .select(setApplicationByIdSelector)
      .pipe()
      .subscribe((res) => {
        let type = findTypeCheckByUrl();
        if (type === 'app_preview' && res) {
          this.isThemeSetting = res?.options?.topbar_option?.enable_application_setting;
          this.cdRef.detectChanges();
        }
      });
  }

  environtmentType: any;

  ngOnInit(): void {
    this.translationService.setLanguage(this.translationService.getSelectedLanguage());
    this.model = this.layoutConfigService.getConfig();
    this.environtmentType = environment.type;
    this.isAppPreview = this.router.url.includes('app_preview');
  }

  changeSkin(e): void {
    this.model.login.self.skin = e;
    this.layoutConfigService.setConfig(this.model, true);
    document.body.className = '';
    document.body.className =
      e == 'dark'
        ? 'theme-light-pqs theme-pds theme-dark theme-cyan kt-page--loaded'
        : 'theme-light-pqs theme-pds kt-page--loaded';
    this.handleDialog();
  }

  handleRedirect = (url) => {
    this.router.navigate([url], {
      queryParams: {},
    });
  };
  handleDialog = () => {
    this.isOpen = !this.isOpen;
  };

  isPqlStringNotEmpty() {
    return !!this.dataProcessingQueryDatasetService.getPqlString();
  }

  hasQueryCommand() {
    return this.dataProcessingWorkspaceService.hasQueryCommand();
  }

  hasWorkingOnChart = () => {
    return this.dataVisualizationService.getHasWorkingOnDataVisualization();
  };

  handleConfirmAction = (url) => {
    if (
      new RegExp(
        [
          'listdatavisualization',
          'applicationlist',
          'dashboardvisualization',
          'dashboard/view',
          'applicationbuilder_app',
        ].join('|')
      ).test(window.location.hash)
    ) {
      this.store.dispatch(
        GeneralSaveTriggerActions({
          saveStatus: false,
          url: '',
          location: '',
        })
      );
      return this.router.navigate([url]);
    }
    if (window.location.hash.includes('dataprocessing')) {
      if (this.dataProcessingQueryDatasetService.getHasSaveQuery()) {
        return this.router.navigate([url]);
      }
      if (!this.hasQueryCommand() && !this.isPqlStringNotEmpty()) {
        return this.router.navigate([url]);
      } else {
        this.handleOpenDialogConfirm(url);
      }
    }

    if (!window.location.hash.includes('dataprocessing')) {
      if (this.hasWorkingOnChart()) {
        this.handleOpenDialogConfirm(url);
      } else {
        return this.router.navigate([url]);
      }
    }
  };

  handleOpenDialogConfirm = (url) => {
    this.modalReference = this.modalService.open(ModalConfirmActionComponent, {
      size: 'md',
      centered: true,
    });
    this.modalReference.result.then(async (res: any) => {
      if (!res) return;
      if (res === 'continue') {
        return this.router.navigate([url]);
      }
      if (res === 'save') {
        this.store.dispatch(
          GeneralSaveTriggerActions({
            saveStatus: true,
            url,
            location: window.location.hash,
          })
        );
      }
    });
  };
}
