import { TreeviewItem } from 'ngx-treeview';
import { ROOT_DIR } from 'src/app/libs/consts';

export const helperDataSetCount = (obj: any) => {
  var result = Object.keys(obj).map(function (key) {
    return [obj[key]];
  });
  return result.length;
};

export const TabStatic = [
  {
    id: 'tab1',
    label: 'MODULE.DATA_PROCESSING.LEFT_BAR.DATA_SOURCE',
  },
  {
    id: 'tab2',
    label: 'MODULE.DATA_PROCESSING.LEFT_BAR.DATA_QUERY',
  },
];

export const ExtractFilePath = (treeviewItem: TreeviewItem, withoutRootDir: boolean = false) => {
  let newFilePath = `${treeviewItem.value.location}/${treeviewItem.value.name}`;

  if (withoutRootDir) {
    newFilePath = newFilePath.replace(ROOT_DIR, '');
    newFilePath = newFilePath.replace('√:', '');
  }

  if (treeviewItem.value.isHdfsChild && treeviewItem.value.isConnection) {
    let hdfsLocation = treeviewItem.value.location.replace('//', '/');
    if (hdfsLocation === '/') hdfsLocation = '';
    newFilePath = `hdfs://${treeviewItem.value.connectionName}${hdfsLocation}/${treeviewItem.value.name}`;
  }

  if (treeviewItem.value.type === 'hdfs' && treeviewItem.value.isConnection)
    newFilePath = `hdfs://${treeviewItem.value.name}`;

  return newFilePath;
};

export const checker = (arr: any) => arr.every((v: any) => v === true);

export const handleCheckData = async (apiService, arr, path, name) => {
  const checkResult: ApiResult = await new Promise((resolve, reject) => {
    apiService
      .post(
        `/api/v2/explorer/check`,
        {
          paths: arr.map((data) => {
            return `${path}/${data[name].replace(/\s/g, '_')}`;
          }),
        },
        true
      )
      .subscribe({
        next: (res: any) => {
          resolve(res);
        },
        error: (err: any) => reject(err),
      });
  });

  return checkResult.response ? checkResult.response : [false];
};
var match;
export const helperFindParentFile = (treeView, compare) => {
  findMatchRecursion(compare, treeView);
  return match;
};

const findMatchRecursion = (activity, activityItems) => {
  for (let i = 0; i < activityItems.length; i += 1) {
    let data = activityItems[i];
    let nameLocation = `${data.value.location}/${data.value.name}`;
    if (data.value.isDir && nameLocation === activity) {
      match = data;
      return match;
    }

    if (data.internalChildren && !match) {
      findMatchRecursion(activity, data.internalChildren);
    }
  }
};
export const formatBytes = (bytesString, decimals = 2) => {
  const bytes = parseFloat(bytesString);
  if (bytes === 0) return '0 B';

  const k = 1000;

  if (bytes < k) return bytesString;

  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};
