<div class="kt-portlet">
  <div class="modal-header">
    <h5 class="modal-title">{{ 'MODULE.DATA_VISUAL.CHART.MESSAGE.MSG_DYWSC' | translate }}</h5>
    <button id="btn-danger" type="button" class="close" (click)="onCancelClick()" style="position: unset">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row clearfix">
      <div class="col-md-12 form-control-label">
        <label> {{ 'MODULE.DATA_VISUAL.CHART.MESSAGE.MSG_YWHC' | translate }}</label>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-info btn-outline btn-sm" type="button" (click)="onSaveContinueClick()">
      {{ 'MODULE.DATA_VISUAL.CHART.COMMON.SC' | translate }}
    </button>
    <button *ngIf="!needCancel" class="btn btn-info btn-outline btn-sm" type="button" (click)="onContinueClick()">
      {{ 'MODULE.DATA_VISUAL.CHART.COMMON.CONT_ANW' | translate }}
    </button>
    <button *ngIf="needCancel" class="btn btn-info btn-outline btn-sm" type="button" (click)="onCancelClick()">
      {{ 'MODULE.DATA_VISUAL.CHART.COMMON.C' | translate }}
    </button>
  </div>
</div>
