<div>
  <div class="modal-header">
    <h5 class="title f-16">
      {{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.DSC' | translate }}
    </h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="_close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="mx-5">
        <div class="content file_manager">
            <div class="row clearfix text-center">
                <!-- MSSQL -->
                <div class="col-12 col-md-6 col-lg-3">
                  <div class="card">
                      <div class="file">
                          <a href="javascript:void(0);" (click)="_onAddConnection('mssql')">
                              <div class="file-name2 px-3 py-4">
                                  <span class="f-55"><img alt="" class="mb-2" src="assets/images/ms-sql-server.svg"></span><br>
                                  <span>{{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.MSSQL' | translate }}</span>
                              </div>
                          </a>
                      </div>
                  </div>
                </div> 
                <!-- ORACLE -->
                <div class="col-12 col-md-6 col-lg-3" (click)="_onAddConnection('oracle')">
                  <div class="card">
                      <div class="file">
                          <a href="javascript:void(0);">
                              <div class="file-name2 px-3 py-4">
                                  <span class="f-55"><img alt="" class="mb-2" src="assets/images/oracle-icon.svg"></span><br>
                                  <span>{{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.ORACLE' | translate }}</span>
                              </div>
                          </a>
                      </div>
                  </div>
                </div>
                <!-- PGSQL -->
                <div class="col-12 col-md-6 col-lg-3" (click)="_onAddConnection('postgresql')">
                  <div class="card">
                      <div class="file">
                          <a href="javascript:void(0);">
                              <div class="file-name2 px-3 py-4">
                                  <span class="f-55"><img alt="" class="mb-2" src="assets/images/postgresql.svg"></span><br>
                                  <span>{{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.PGSQL' | translate }}</span>
                              </div>
                          </a>
                      </div>
                  </div>
                </div>
                <!-- MYSQL -->
                <div class="col-12 col-md-6 col-lg-3" (click)="_onAddConnection('mysql')">
                  <div class="card">
                      <div class="file">
                          <a href="javascript:void(0);">
                              <div class="file-name2 px-3 py-4">
                                  <span class="f-55"><img alt="" class="mb-2" src="assets/images/mysql.svg"></span><br>
                                  <span>{{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.MYSQL' | translate }}</span>
                              </div>
                          </a>
                      </div>
                  </div>
                </div>
            </div>
          
            <div class="row justify-content-md-center text-center">
              <!-- DB2 -->
              <div class="col-12 col-md-6 col-lg-3" (click)="_onAddConnection('db2')">
                <div class="card">
                    <div class="file">
                        <a href="javascript:void(0);">
                            <div class="file-name2 px-3 py-4">
                                <span class="f-55"><img alt="" class="mb-2" src="assets/images/db2.svg"></span><br>
                                <span>{{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.DB2' | translate }}</span>
                            </div>
                        </a>
                    </div>
                </div>
              </div>
              <!-- HDFS -->
              <div class="col-12 col-md-6 col-lg-3" (click)="_onAddConnection('hdfs')">
                <div class="card">
                    <div class="file">
                        <a href="javascript:void(0);">
                            <div class="file-name2 px-3 py-4">
                                <span class="f-55"><img alt="" class="mb-2" src="assets/images/hdfs.svg"></span><br>
                                <span>{{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.HDFS' | translate }}</span>
                            </div>
                        </a>
                    </div>
                </div>
              </div>
              <!-- CLICKHOUSE -->
              <div class="col-12 col-md-6 col-lg-3" (click)="_onAddConnection('clickhouse')">
                <div class="card">
                    <div class="file">
                        <a href="javascript:void(0);">
                            <div class="file-name2 px-3 py-4">
                                <span class="f-55"><img alt="" class="mb-2" src="assets/images/clickhouse-icon.svg" width="30%"></span><br>
                                <span>{{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.CLICKHOUSE' | translate }}</span>
                            </div>
                        </a>
                    </div>
                </div>
              </div>
            </div>
        </div>
    </div>
  </div>
</div>
