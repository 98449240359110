import { chartGrid, axisFormater } from '.';

export const setConfigChartGauge = (data: any) => {
  const findStatus = findValueByRange(data?.form_data, data.data);
  return {
    grid: chartGrid,
    series: [
      {
        type: 'gauge',
        max: data?.form_data?.max_value,
        axisLine: {
          lineStyle: {
            width: 30,
            color: data.form_data.colorpickers.map((obj) => {
              return [obj.gauge_value, obj.colorpicker];
            }),
          },
        },
        pointer: {
          show: data.form_data.show_needle || false,
          itemStyle: { color: 'inherit' },
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        title: {
          show: data.form_data.show_label || false,
          offsetCenter: ['0%', '-40%'],
          textStyle: {
            fontWeight: 'bolder',
            fontSize: 20,
            fontStyle: 'italic',
            color: 'inherit',
            shadowColor: '#fff',
            shadowBlur: 10,
          },
        },
        axisLabel: { show: data.form_data.show_axis_label || false, color: 'auto' },
        detail: {
          valueAnimation: true,
          formatter: (value: any) => {
            const num = axisFormater(value, data, data.form_data.number_format);
            return `${num} ${data.form_data.gauge_label_type == 'percent' ? '%' : ''}`;
          },
          color: 'inherit',
        },
        data: [
          {
            value: data.data,
            name: findStatus ? findStatus.status : '',
          },
        ],
      },
    ],
  };
};

const findValueByRange = (form_data, value) => {
  for (let condition of form_data.colorpickers) {
    if (value >= condition.size_from && value < condition.size_to) {
      return condition;
    }
  }
  // In case the value doesn't match any condition
};
