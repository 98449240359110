import { getUrl } from 'src/app/libs/helpers/data-visualization-helper';
import { axisFormater } from '.';
import { convert_metric_to_verbose } from 'src/app/libs/helpers/utility';

type DataChildType = {
  name: string;
  value: number;
  itemStyle?: {
    color: string;
  };
};

type ChartDataType = {
  name: string;
  children: DataChildType[];
};

type ColorItemType = {
  entity: string;
  colorpicker: string;
};

export const helperGetDataTreemap = async (exploreJson, service) => {
  let exploreUrl = getUrl(exploreJson);
  let exploreResult = await service.loadGetData(exploreUrl);
  return exploreResult ? (exploreResult.response ? exploreResult.response : exploreResult) : exploreResult;
};

const getLevelOptionTreemap = (colors: any) => {
  return [
    {
      color: colors,
      itemStyle: {
        borderColor: '#555',
        gapWidth: 1,
      },
    },
    {
      color: colors,
      colorMappingBy: 'name',
      itemStyle: {
        borderColor: '#555',
        gapWidth: 1,
      },
    },
    {
      colorSaturation: [0.3, 0.6],
      itemStyle: {
        borderColorSaturation: 0.7,
        gapWidth: 1,
      },
    },
    {
      colorSaturation: [0.3, 0.5],
      itemStyle: {
        borderColorSaturation: 0.6,
        gapWidth: 1,
      },
    },
    {
      colorSaturation: [0.3, 0.5],
    },
  ];
};

export const setConfigChartTreemap = (data, explore, colorPalette) => {
  // dummy data when raw.data is empty
  // data = { ...data, data: [] };
  return {
    tooltip: {
      formatter: function (info: any) {
        var treePath = info.treePathInfo.map((item: any) => {
          let nilai = data.form_data.number_format
            ? axisFormater(item.value, data, data.form_data.number_format)
            : item.value;
          return convert_metric_to_verbose(item.name, explore) + ' (' + nilai + ')';
        });

        return ['<div class="tooltip-title">' + treePath.join(' - ') + '</div>'].join('');
      },
    },
    series: generateSeries(data, explore, colorPalette),
  };
};

const generateSeries = (data, explore, colorPalette) => {
  return [
    {
      name: convert_metric_to_verbose(explore.form_data.metrics[0], explore),
      roam: false,
      type: 'treemap',
      data: data.data.length && data.data[0].children.map((item: ChartDataType) => {
        const colorObj = data.form_data.colorpickers.find((color: ColorItemType) => {
          return color.entity === item.name;
        });
        return { ...item, itemStyle: { color: colorObj?.colorpicker || '#808080' } };
      }),
      squareRatio: data.form_data.treemap_ratio,
      levels: getLevelOptionTreemap(colorPalette),
      label: {
        show: true,
        formatter: (params: any) =>
          params.data.name
            ? data.form_data.number_format
              ? `${convert_metric_to_verbose(params.data.name, explore)}\n${axisFormater(
                  params.data.value,
                  data,
                  data.form_data.number_format
                )}`
              : `${convert_metric_to_verbose(params.data.name, explore)}\n${params.data.value}`
            : '',
      },
      breadcrumb: {
        show: data.form_data.show_legend,
      },
      leafDepth: 3,
    },
  ];
};
