<div>
  <div class="modal-header">
    <h5 class="title f-16">
      {{ 'HEADER_BAR.CP' | translate }}
    </h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="_close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="changePasswordFormGroup">
      <div class="row clearfix">
        <div class="col-md-12 form-control-label">
          <label>{{ 'AUTH.INPUT.OLDPASSWORD' | translate }}<font color="red"> *</font></label>
        </div>
      </div>
      <div>
        <div class="row clearfix">
          <div class="col-md-12">
            <div class="form-group">
              <input
                type="password"
                class="form-control"
                placeholder="********"
                formControlName="old_password"
                [ngClass]="
                  changePasswordFormGroup.controls['old_password'].touched &&
                  changePasswordFormGroup.controls['old_password'].errors?.additional
                    ? 'require-error is-invalid'
                    : ''
                "
              />
              <div
                *ngIf="
                  changePasswordFormGroup.controls['old_password'].touched &&
                  changePasswordFormGroup.controls['old_password'].errors?.additional
                "
              >
                <p class="text-danger" style="font-size: 11px">
                  {{ changePasswordFormGroup.controls['old_password'].errors?.message }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row clearfix">
        <div class="col-md-12 form-control-label">
          <label>{{ 'AUTH.INPUT.NEWPASSWORD' | translate }}<font color="red"> *</font></label>
        </div>
      </div>
      <div>
        <div class="row clearfix">
          <div class="col-md-12">
            <div class="form-group">
              <input
                type="password"
                id="password"
                class="form-control"
                placeholder="********"
                formControlName="password"
                [ngClass]="
                  changePasswordFormGroup.controls['password'].touched &&
                  changePasswordFormGroup.controls['password'].errors?.additional
                    ? 'require-error is-invalid'
                    : ''
                "
              />
              <div
                *ngIf="
                  changePasswordFormGroup.controls['password'].touched &&
                  changePasswordFormGroup.controls['password'].errors?.additional
                "
              >
                <p class="text-danger" style="font-size: 11px">
                  {{ changePasswordFormGroup.controls['password'].errors?.message }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row clearfix">
        <div class="col-md-12 form-control-label">
          <label>{{ 'AUTH.INPUT.CONFIRM_PASSWORD' | translate }}<font color="red"> *</font></label>
        </div>
      </div>
      <div>
        <div class="row clearfix">
          <div class="col-md-12">
            <div class="form-group">
              <input
                type="password"
                class="form-control"
                placeholder="********"
                formControlName="password_confirmation"
                [ngClass]="
                  changePasswordFormGroup.controls['password_confirmation'].touched &&
                  changePasswordFormGroup.controls['password_confirmation'].errors?.additional
                    ? 'require-error is-invalid'
                    : ''
                "
              />
              <div
                *ngIf="
                  changePasswordFormGroup.controls['password_confirmation'].touched &&
                  changePasswordFormGroup.controls['password_confirmation'].errors?.additional
                "
              >
                <p class="text-danger" style="font-size: 11px">
                  {{ changePasswordFormGroup.controls['password_confirmation'].errors?.message }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="btn btn-neutral btn-simple btn-sm close" type="button" data-dismiss="modal" (click)="_close()">
      {{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.C' | translate }}
    </button>
    <button class="btn btn-info btn-outline btn-sm" type="button" (click)="saveChangePassword()" [disabled]="loading">
      <div *ngIf="loading" class="loader loader--style1" title="0">
        <svg
          version="1.1"
          id="loader-1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="15px"
          height="15px"
          viewBox="0 0 40 40"
          enable-background="new 0 0 40 40"
          xml:space="preserve"
        >
          <path
            opacity="0.2"
            fill="#000"
            d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
							  s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
							  c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
          />
          <path
            fill="#000"
            d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
							  C22.32,8.481,24.301,9.057,26.013,10.047z"
          >
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="rotate"
              from="0 20 20"
              to="360 20 20"
              dur="0.5s"
              repeatCount="indefinite"
            />
          </path>
        </svg>
      </div>
      {{ 'MODULE.DATA_PROCESSING.COMMON.MODAL.S' | translate }}
    </button>
  </div>
</div>
