export class Topics {
	id: number;
	media: number;
	post: number;
	topic: string;

    clear() : void {
        this.id = 0;
        this.media;
        this.post;
        this.topic = "";
    }
}
