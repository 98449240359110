import { countWidthChar, getResponsiveOptions } from '../helper';

export const setConfigChartHistogram = (data: any) => {
  const color = data.form_data.colorpickers.length > 0 ? [data.form_data.colorpickers[0].colorpicker] : ['#1dab69'];
  const minYrange = data?.form_data?.min_range || null;
  const maxYrange = data?.form_data?.max_range || null;
  const groupedBins = [];
  const min = data.form_data?.start_index_histogram ? data.form_data?.start_index_histogram : (data?.data?.values?.length && data?.data?.values[0]?.start);
  const interval = data.form_data.bin_count;
  if (data.data.values) {
    for (const item of data.data.values) {
      const labelRange = `${item.start}-${item.end}`;
      const value = item.value ? getValueBar(item.value, minYrange, maxYrange) : 0;
      groupedBins.push([item.start, item.end, value, labelRange]);
    }
  }
  return {
    color: color,
    grid: { left: '10%', top: '12%', bottom: '16%', right: '10%', containLabel: true },
    tooltip: {
      show: data.form_data.chart_tooltip,
      axisPointer: {
        type: 'shadow',
      },
      formatter: (params: any) => {
        return `Range : ${params.value[3]}</br>Count : <b>${params.value[2]}</b>`;
      },
    },
    xAxis: {
      scale: true,
      splitLine: {
        show: false,
      },
      nameLocation: 'center',
      nameGap: 40,
      min,
      name: data.form_data.x_axis_label || '',
      interval,
    },
    yAxis: {
      nameLocation: 'center',
      nameGap: 40,
      min: minYrange,
      max: maxYrange,
      name: data.form_data.y_axis_label || '',
      showGrid: false,
      splitLine: {
        show: false,
      },
      axisLine: {
        onZero: false,
      },
    },
    series: [
      {
        type: 'custom',
        renderItem: function (params, api) {
          let yValue = api.value(2); // The actual y value (Count value)
          let xStart = api.coord([api.value(0), yValue])[0]; // X-coordinate start
          let xEnd = api.coord([api.value(1), yValue])[0]; // X-coordinate end
          let yBase = api.coord([api.value(0), minYrange])[1]; // Y-coordinate at y-axis minimum (10)
          // Calculate bar height from y-value to the base (start at y=10)
          let barHeight = yBase - api.coord([api.value(0), yValue])[1];

          let style = api.style();
          return {
            type: 'rect',
            shape: {
              x: xStart,
              y: yValue ? yBase - barHeight : yBase, // Bar's Y start position
              width: xEnd - xStart, // Bar width
              height: yValue ? barHeight : 0, // Bar height (starts from y=10)
            },
            style,
          };
        },
        label: {
          show: true,
          position: 'top',
        },
        encode: {
          x: [0, 1],
          y: 2,
          tooltip: [0, 1, 2],
          itemName: 3,
        },
        data: groupedBins,
      },
    ],
  };
};

const getValueBar = (value: number, minYrange: number, maxYrange: number) => {
  if ((minYrange === null || value > minYrange) && (maxYrange === null || value <= maxYrange)) {
    return value;
  } else if (value < (minYrange || -Infinity)) {
    return 0;
  } else {
    if (maxYrange !== null) {
      return maxYrange;
    }
  }
};

export const setUpdateHistogramOptions = (echartsInstance, exploreJson, chartOption): any  => {
  let nameGapWidth: number = 0;
  const {width, gap} = getResponsiveOptions(echartsInstance.getWidth());
  const yAxis = echartsInstance.getModel().getComponent('yAxis', 0);
  nameGapWidth = countWidthChar(yAxis.axis.scale.getExtent()[1].toString());
  const defaultWidth: number = width;

  return { 
    ...chartOption,
    ...(exploreJson.form_data.y_axis_label && {
      yAxis: {
        ...chartOption.yAxis,
        nameLocation: 'middle',
        nameGap: (nameGapWidth > defaultWidth ? defaultWidth : nameGapWidth) + gap,
        axisLabel: {
          ...chartOption.yAxis.axisLabel,
          width: defaultWidth,
        }
      },
    }),
  };
}