import { Cmd, CmdIface, CmdTypes, CmdUISetting, IntoLabelGenerator } from './command.model';
import {
    ChildConnector,
    ChildConnectorIface,
    ParentConnector,
    ParentConnectorIface,
} from './connection.model';
import { CmdSlots, Slot, SlotName, SlotType } from './query.model';

const cmdType: CmdTypes = 'STORE';

interface StoreCmdProps {
    connection_name?: string;
    sink_type: string;
    from: string;
    into: string;
}

interface StoreCmdState {
    node_type: CmdTypes;
    props: StoreCmdProps;
}

class StoreCmd extends Cmd implements CmdIface {
    private state: StoreCmdState;
    private fromConnector: ChildConnectorIface;

    constructor(state?: StoreCmdState) {
        super(cmdType);

        if (state) {
            this.state = state;
        } else {
            this.state = {
                node_type: cmdType,
                props: {
                    connection_name: '',
                    sink_type: '',
                    from: '',
                    into: '',
                },
            };
        }
        super.SetNewProp(this.state.props);


        this.fromConnector = new ChildConnector(
            "INPUT1",
            "",
            (parentLabel: string) => {
                this.state.props.from = parentLabel;
                this.Slots["INPUT1"].isChecked = true;
            },
            () => {
                this.state.props.from = '';
                this.Slots["INPUT1"].isChecked = false;
            },
            (parentLabel: string) => {
                this.state.props.from = parentLabel;
            },
            this
        );
    }

    SetIntoLabel(newLabel: string) {}

    GetInConnector(name: SlotName, label?: string): ChildConnectorIface {
        if (name === 'INPUT1') {
            return this.fromConnector;
        }
        return null;
    }

    GetOutConnector(name: SlotName, label?: string): ParentConnectorIface {
        return null;
    }

    OnCmdDelete(): void {
        if (this.fromConnector.IsConnected()) {
            this.fromConnector.Disconnect();
        }
    }
}

export { StoreCmdState, StoreCmd };
