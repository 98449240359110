import { IMetadataColumnWrapper, IMetadataDatasetWrapper } from 'src/app/libs/types/dataprocessing';
import { isDateParser } from 'src/app/pages/pds/dataprocessing/helper';
import _ from 'lodash';

export const helperMergeMetadatas = function(firstMetadatas: IMetadataDatasetWrapper[], secondMetadatas: IMetadataDatasetWrapper[]) {
  const mergedMetadatas = secondMetadatas.map((secondMetadata: IMetadataDatasetWrapper) => {
    const firstMetadata: IMetadataDatasetWrapper = _.find(firstMetadatas, { dataset: secondMetadata.dataset });

    return firstMetadata ?? secondMetadata;
  });
  
  return mergedMetadatas;
};

export const helperSetupMetadatas = (viewData: any) => {
  const datasets: string[] = viewData.quid.source;

  let tempMetadata = [];
  for (const datasetName of datasets) {
    const columns = viewData.datasets[datasetName]?.columns;
    const rows = viewData.datasets[datasetName]?.rows;

    if ((!columns || (columns && columns.length === 0)) || (!rows || (rows && rows.length === 0))) {
      continue;
    }

    let tempMetadataColumn = [];
    let i = 0;
    for (const columnName of columns) {
      const metadata = {
        groupby: true,
        filterable: true,
        is_dttm: false,
        avg: false,
        min: false,
        max: false,
        sum: false,
        indexable: false,
        fast_index: false,
      };

      let isNumber = false;
      let isUTC = false;

      for (let j = 0; j < rows.length && j < 10; j++) {
        const cell = typeof rows[j][i] === 'string' ? rows[j][i].trim() : rows[j][i];
        if (typeof cell === 'boolean' || typeof cell === 'object' || Array.isArray(cell)) {
          isNumber = false;
          isUTC = false;
          break;
        }

        if (isNumber && isNaN(cell)) {
          isNumber = false;
          isUTC = false;
          break;
        }

        if (isUTC) {
          if (cell == null || isDateParser(cell)) {
            continue;
          }
          isNumber = false;
          isUTC = false;
          break;
        }

        if (cell && !isNaN(cell)) {
          isNumber = true;
        } else if (isDateParser(cell)) {
          isUTC = true;
        } else {
          isNumber = false;
          isUTC = false;
          break;
        }
      }

      metadata.avg = isNumber;
      metadata.sum = isNumber;
      metadata.max = isNumber;
      metadata.min = isNumber;
      metadata.filterable = !isNumber;
      metadata.groupby = !isNumber;
      metadata.is_dttm = isUTC;
      tempMetadataColumn.push({ columnName, metadata });

      i++;
    }

    tempMetadata.push({ dataset: datasetName, columns: tempMetadataColumn });
  }

  return tempMetadata;
};

export const helperFetchDatasetView = async (apiService, tableActive, queryResult, defPageSize, pageIndex$) => {
  const result: ApiResult = await new Promise((resolve, reject) => {
    apiService
      .post('/api/dataset/view', {
        source: tableActive,
        quid: queryResult.quid.quid,
        size: defPageSize,
        skip: pageIndex$ * defPageSize,
      })
      .subscribe({
        next: (res) => resolve(res),
        error: (err) => reject(err),
      });
  });

  if (result && result.status === 'success') {
    return result.response;
  }
};

export const helperSetupMetadasColumn = (metadataColumn: IMetadataColumnWrapper) => {
  const tmpMetadata = {
    groupby: false,
    filterable: false,
    is_dttm: false,
    indexable: false,
    fast_index: false,
    min: false,
    max: false,
    sum: false,
    avg: false,
  };

  if (metadataColumn.metadata.groupby) {
    tmpMetadata.groupby = true;
  }

  if (metadataColumn.metadata.filterable) {
    tmpMetadata.filterable = true;
  }

  if (metadataColumn.metadata.is_dttm) {
    tmpMetadata.is_dttm = true;
  }

  if (
    (metadataColumn.metadata.avg ||
      metadataColumn.metadata.min ||
      metadataColumn.metadata.max ||
      metadataColumn.metadata.sum) &&
    !metadataColumn.metadata.is_dttm
  ) {
    tmpMetadata.min = true;
    tmpMetadata.max = true;
    tmpMetadata.avg = true;
    tmpMetadata.sum = true;
  }

  if (metadataColumn.metadata.indexable) {
    tmpMetadata.indexable = true;
  }

  if (metadataColumn.metadata.fast_index) {
    tmpMetadata.fast_index = true;
  }
  return tmpMetadata;
};

export const helperMetadaChangeData = (metadataElement: string, existingMetadata: any, isChecked: boolean) => {
  const newMetadata = {
    groupby: existingMetadata.groupby,
    filterable: existingMetadata.filterable,
    is_dttm: existingMetadata.is_dttm,
    avg: existingMetadata.avg,
    min: existingMetadata.min,
    max: existingMetadata.max,
    sum: existingMetadata.sum,
    indexable: existingMetadata.indexable,
    fast_index: existingMetadata.fast_index,
  };

  if (metadataElement === 'groupby') {
    newMetadata.groupby = isChecked;
  }

  if (metadataElement === 'filterable') {
    newMetadata.filterable = isChecked;
  }

  if (metadataElement === 'datetime') {
    newMetadata.is_dttm = isChecked;
  }

  if (metadataElement === 'aggregate') {
    newMetadata.avg = isChecked;
    newMetadata.min = isChecked;
    newMetadata.max = isChecked;
    newMetadata.sum = isChecked;
  }

  if (metadataElement === 'indexable') {
    newMetadata.indexable = isChecked;
  }

  if (metadataElement === 'fast_index') {
    newMetadata.fast_index = isChecked;
  }

  return newMetadata;
};

export const helperSetMetdataFromAPI = (apiResponse: object, datasetIds: Array<string>): IMetadataDatasetWrapper[] => {
  return Object.keys(apiResponse).map((dataset) => {
    return {
      dataset: dataset || datasetIds[0],
      columns: Object.keys(apiResponse[dataset]).map((key) => {
        let metadata = apiResponse[dataset][key];
        delete metadata.id;
        delete metadata.quid;
        delete metadata.table_id;
        delete metadata.column_name;
        delete metadata.count_distinct;
        delete metadata.verbose_name;
        return { columnName: key, metadata };
      }),
    };
  });
};
