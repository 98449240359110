import { Cmd, CmdIface, CmdTypes, IntoLabelGenerator } from './command.model';
import { ChildConnector, ChildConnectorIface, ParentConnector, ParentConnectorIface } from './connection.model';
import { SlotName } from './query.model';

const cmdType: CmdTypes = 'EXTRACT';

interface ExtractCmdProps {
  col_target: string;
  columns: string;
  pattern: string;
  field_delimiter: string;
  kv_delimiter: string;
  formulas: Array<string>;
  delimiter: string;
  aliases: string;
  extract_split: { col_target: string; delimiter: string; aliases: string };
  extract_json: { col_target: string; columns: string };
  extract_kv: { col_target: string; field_delimiter: string; kv_delimiter: string };
  extract_formula: { formulas: Array<string> };
  extract_rex: { col_target: string; pattern: string; columns: string };
  extract_type: string;
  from: string;
  into: string;
}

interface ExtractCmdState {
  node_type: CmdTypes;
  props: ExtractCmdProps;
}

class ExtractCmd extends Cmd implements CmdIface {
  private state: ExtractCmdState;
  private fromConnector: ChildConnectorIface;
  private intoConnector: ParentConnectorIface;

  constructor(state?: ExtractCmdState) {
    super(cmdType);

    if (state) {
      this.state = state;
    } else {
      this.state = {
        node_type: cmdType,
        props: {
          extract_split: { col_target: '', delimiter: '', aliases: '' },
          extract_json: { col_target: '', columns: '' },
          extract_kv: { col_target: '', field_delimiter: '', kv_delimiter: '' },
          extract_formula: { formulas: [] },
          extract_rex: { col_target: '', pattern: '', columns: '' },
          extract_type: '',
          col_target: '',
          columns: '',
          pattern: '',
          field_delimiter: '',
          kv_delimiter: '',
          formulas: [],
          delimiter: '',
          aliases: '',
          from: '',
          into: IntoLabelGenerator(cmdType),
        },
      };
    }
    super.SetNewProp(this.state.props);

    this.fromConnector = new ChildConnector(
      'INPUT1',
      '',
      (parentLabel: string) => {
        this.state.props.from = parentLabel;
        this.Slots['INPUT1'].isChecked = true;
      },
      () => {
        this.state.props.from = '';
        this.Slots['INPUT1'].isChecked = false;
      },
      (parentLabel: string) => {
        this.state.props.from = parentLabel;
      },
      this
    );

    this.intoConnector = new ParentConnector(
      'OUTPUT1',
      '',
      () => this.state.props.into,
      (newLabel: string) => {
        this.state.props.into = newLabel;
      },
      () => {
        this.Slots['OUTPUT1'].isChecked = true;
      },
      () => {
        this.Slots['OUTPUT1'].isChecked = false;
      },
      this
    );
  }

  SetIntoLabel(newLabel: string) {
    if (this.intoConnector.IsConnected()) {
      this.intoConnector.SetIntoLabel(newLabel);
    } else {
      this.state.props.into = newLabel;
    }
  }

  GetInConnector(name: SlotName, label?: string): ChildConnectorIface {
    if (name === 'INPUT1') {
      return this.fromConnector;
    }
    return null;
  }

  GetOutConnector(name: SlotName, label?: string): ParentConnectorIface {
    if (name === 'OUTPUT1') {
      return this.intoConnector;
    }
    return null;
  }

  OnCmdDelete(): void {
    if (this.fromConnector.IsConnected()) {
      this.fromConnector.Disconnect();
    }

    if (this.intoConnector.IsConnected()) {
      this.intoConnector.Disconnect();
    }
  }
}

export { ExtractCmdState, ExtractCmd };
