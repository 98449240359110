import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'query-db-modal',
  templateUrl: './query-db-modal.component.html',
  styleUrls: ['./query-db-modal.component.scss'],
})
export class QueryDbModalComponent {
  @Output() onChange = new EventEmitter<any>();
  @Output() onClose = new EventEmitter();

  public clauseA: string;
  public clauseB: string;

  _onChange(inputName: string, txt: string) {
    this.onChange.emit({inputName, txt})
  }

  onCloseHandler() {
    this.onClose.emit();
  }
}
