// Angular
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { JsonService } from 'src/app/libs/services';

@Component({
  selector: 'confirm-action-dialog',
  templateUrl: './modal-confirm-action.component.html',
})
export class ModalConfirmActionComponent implements OnInit {
  @Input() needCancel: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<ModalConfirmActionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public activeModal: NgbActiveModal
  ) {}

  async ngOnInit() {}

  onCancelClick(): void {
    this.activeModal.close();
  }

  onContinueClick = () => {
    this.activeModal.close('continue');
  };

  onSaveContinueClick = () => {
    this.activeModal.close('save');
  };
}
