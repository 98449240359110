import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { ModalDuplicateQueryComponent } from 'src/app/components/modals/ModalDuplicateQuery/modal-duplicate-query.component';
import { DATAPROCESSING_API_PATH } from 'src/app/libs/consts';
import { ApiService, JsonService, ModalDialogService } from 'src/app/libs/services';
import { SetToastrMessage } from 'src/app/libs/store/actions/pds/dataprocessing.actions';
import { AppState } from 'src/app/libs/store/states';

@Injectable({
  providedIn: 'root',
})
export class DataProcessingDataQueryService {
  private _loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _isTabActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _isDataQueryToListSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _needReloadDataQuerySubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _needReplaceDuplicatedFileSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private apiService: ApiService,
    private modalDialogService: ModalDialogService,
    private store: Store<AppState>,
    private translate: TranslateService
  ) {}

  getLoadingSubject(): BehaviorSubject<boolean> {
    return this._loading$;
  }

  getLoading(): boolean {
    return this._loading$.getValue();
  }

  getIsTabActive(): boolean {
    return this._isTabActive$.getValue();
  }

  getNeedReloadDataQuery(): boolean {
    return this._needReloadDataQuerySubject$.getValue();
  }

  getNeedReloadDataQuerySubject(): BehaviorSubject<boolean> {
    return this._needReloadDataQuerySubject$;
  }

  getNeedReplaceDuplicatedFile(): boolean {
    return this._needReplaceDuplicatedFileSubject$.getValue();
  }

  getNeedReplaceDuplicatedFileSubject(): BehaviorSubject<boolean> {
    return this._needReplaceDuplicatedFileSubject$;
  }

  getIsDataQueryToListSubject(): BehaviorSubject<boolean> {
    return this._isDataQueryToListSubject$;
  }

  setLoading(status: boolean): void {
    this._loading$.next(status);
  }

  setIsTabActive(status: boolean): void {
    this._isTabActive$.next(status);
  }

  setNeedReloadDataQuery(status: boolean): void {
    this._needReloadDataQuerySubject$.next(status);
  }

  setNeedReplaceDuplicatedFile(status: boolean): void {
    this._needReplaceDuplicatedFileSubject$.next(status);
  }

  setIsDataQueryToList(status: boolean): void {
    this._isDataQueryToListSubject$.next(status);
  }

  async getList(search?: { type: string; text: string }, limit?: number, filter ?: string, beforeTime?: any): Promise<any> {
    this._loading$.next(true);

    const params = {
      limit,
    };

    if (filter) {
      params['filter'] = filter;
    }

    if (beforeTime) {
      params['beforeTime'] = beforeTime;
    }

    if (search && search.text) {
      params['search_field'] = search.type;
      params['search'] = search.text;
    }

    const result = await this._handleActionHitApi(DATAPROCESSING_API_PATH.QUERY_LIST_TS, params, null, null);

    this._loading$.next(false);

    return result;
  }

  async saveDataset(params, callback) {
    const result = await this._handleActionHitApi('/api/query/dsalias/save', params, callback, null);
    return result;
  }

  duplicate(data, callback) {
    return new Promise((resolve, reject) => {
      const modalRef = this.modalDialogService.openModal(
        ModalDuplicateQueryComponent,
        { size: 'sm' },
        {
          newName: `${data.title} (Duplicate)`,
        }
      );
      modalRef.componentInstance.close.subscribe(() => modalRef.close());
      modalRef.componentInstance.save.subscribe(async (newName) => {
        modalRef.close();

        try {
          const result = await this._handleActionHitApi(
            '/api/query/duplicate',
            { id: data.ID, new_name: newName },
            callback,
            this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.QSD')
          );
          resolve(result);
        } catch (error) {
          reject(error);
        }
      });
    });
  }
  multiDelete(selectedItems: any, callback: { func: any; params: any }): void {
    this.modalDialogService.openDialogWithCallback(
      this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.C'),
      `${this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.DYWD')} ${selectedItems.length} file ? `,
      async () => {
        this._loading$.next(true);
        this.modalDialogService.openProgressDialog();

        await this._handleActionHitApi(
          '/api/v2/query/delete',
          selectedItems.map((data) => {
            return { id: data.ID, quid: data.quid, userID: data.userID };
          }),
          callback,
          `${selectedItems.length} ${this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.TQSD')}`
        );

        this._loading$.next(false);
        this.modalDialogService.closeProgressDialog();
      },
      {
        needCancel: true,
      }
    );
  }

  delete(data: any, callback: any): void {
    this.modalDialogService.openDialogWithCallback(
      this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.C'),
      `${this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.DYWD')} ${data.title}?`,
      async () => {
        this._loading$.next(true);
        this.modalDialogService.openProgressDialog();

        await this._handleActionHitApi(
          DATAPROCESSING_API_PATH.QUERY_DELETE,
          {
            id: data.ID,
            quid: data.quid,
          },
          callback,
          this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.QDSDS')
        );

        this._loading$.next(false);
        this.modalDialogService.closeProgressDialog();
      },
      {
        needCancel: true,
      }
    );
  }

  private async _handleActionHitApi(
    url: string,
    data: any,
    callback: { func: any; params: any },
    messageSuccess: string
  ): Promise<any> {
    const result: ApiResult = await new Promise((resolve, reject) => {
      this.apiService.post(url, data, true).subscribe({
        next: (res) => {
          if (messageSuccess) {
            this.store.dispatch(
              SetToastrMessage({
                toastrMessage: {
                  toastrType: 'info',
                  message: messageSuccess,
                },
              })
            );
          }

          if (callback) {
            callback.func(callback.params);
          }

          resolve(res);
        },
        error: (err) => {
          this.store.dispatch(
            SetToastrMessage({
              toastrMessage: {
                toastrType: 'error',
                message: err.message || this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.PF'),
              },
            })
          );

          reject(err);
        },
      });
    });

    if (result && result.status === 'success' && result.response) {
      return result.response;
    }
  }
}
