import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { DialogCommonComponent } from 'src/app/components/dialogs/dialog-common/dialog-common.component';
import { ModalComponent } from 'src/app/components/modals/modal/modal.component';
import { ProgressDialogComponent } from 'src/app/components/progress-dialog/progress-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ModalDialogService {
  private progressDialog: MatDialogRef<any> = null;

  constructor(private translate: TranslateService, private dialogService: MatDialog, private ngbModal: NgbModal) {}

  openModal(
    modalComponent: any,
    uiCfgs: any,
    params?: { [key: string]: any },
    subscribedFunctions?: { [key: string]: Function },
    afterModalClosedCallback?: Function
  ) {
    const modalRef = this.ngbModal.open(modalComponent, {
      centered: true,
      backdrop: 'static',
      ...uiCfgs,
    });

    for (const key in params) {
      modalRef.componentInstance[key] = params[key];
    }

    for (const key in subscribedFunctions) {
      modalRef.componentInstance[key].subscribe(subscribedFunctions[key]);
    }

    if (afterModalClosedCallback) {
      modalRef.closed.subscribe(() => afterModalClosedCallback());
    }

    return modalRef;
  }

  openDialog(dialogComponent: any, params: any) {
    const dialogRef = this.dialogService.open(dialogComponent, { ...params });

    return dialogRef;
  }

  closeModal(modalRef: NgbModalRef) {
    if (modalRef) {
      modalRef.close();
    }
  }

  closeAllModal() {
    this.ngbModal.dismissAll();
  }

  openProgressDialog() {
    this.progressDialog = this.openDialog(ProgressDialogComponent, {
      disableClose: true,
      data: { message: this.translate.instant('MODULE.DATA_PROCESSING.MESSAGE.PW') },
      width: '440px',
    });
  }

  closeProgressDialog() {
    if (this.progressDialog) {
      this.progressDialog.close();
    }
  }

  openDialogWithCallback(
    title: string,
    description: string,
    awaitedCallback?: any,
    options?: {
      needCancel: boolean;
    }
  ) {
    const dialogRef = this.dialogService.open(DialogCommonComponent, {
      disableClose: true,
      data: { title, description, hasCallback: !!awaitedCallback, config: options },
      width: '440px',
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        awaitedCallback();
      }
    });

    return dialogRef;
  }

  showNotification(title?, msg?, isFooter?, footerLeftText?, footerRightText?) {
    const modalRef = this.ngbModal.open(ModalComponent, {
      centered: true,
      size: 'md',
    });
    modalRef.componentInstance.title = title ? title : '';
    modalRef.componentInstance.msg = msg ? msg : 'Please contact administrator for this issue!';
    modalRef.componentInstance.isFooter = isFooter ? isFooter : false;
    if (footerLeftText && isFooter) {
      modalRef.componentInstance.footerLeftText = footerLeftText ? footerLeftText : 'Yes';
    }
    if (footerRightText && isFooter) {
      modalRef.componentInstance.footerRightText = footerRightText ? footerRightText : 'No';
    }

    return modalRef;
  }
}
