import { Cmd, CmdIface, CmdTypes, CmdUISetting, IntoLabelGenerator } from './command.model';
import {
    ChildConnector,
    ChildConnectorIface,
    ParentConnector,
    ParentConnectorIface,
} from './connection.model';
import { CmdSlots, Slot, SlotName, SlotType } from './query.model';
import { fromConnector } from './select.model';

const cmdType: CmdTypes = 'MERGE';

interface MergeCmdProps {
    froms: string;
    into: string;
}

interface MergeCmdState {
    node_type: CmdTypes;
    props: MergeCmdProps;
}

class MergeCmd extends Cmd implements CmdIface {
    private state: MergeCmdState;
    private fromConnectors: { [key: string]: ChildConnectorIface };
    private intoConnector: ParentConnectorIface;

    constructor(state?: MergeCmdState) {
        super(cmdType);

        if (state) {
            this.state = state;
        } else {
            this.state = {
                node_type: cmdType,
                props: {
                    froms: '',
                    into: IntoLabelGenerator(cmdType),
                },
            };
        }
        super.SetNewProp(this.state.props);

        this.fromConnectors = {};

        this.intoConnector = new ParentConnector(
            "OUTPUT1",
            "",
            () => this.state.props.into,
            (newLabel: string) => {
                this.state.props.into = newLabel;
            },
            () => {
                this.Slots["OUTPUT1"].isChecked = true;
            },
            () => {
                this.Slots["OUTPUT1"].isChecked = false;
            },
            this
        );
    }

    getFromConnectorLabels(): string[] {
        let labels: string[] = [];
        for (let label in this.fromConnectors) {
            labels.push(label);
        }
        return labels;
    }

    SetIntoLabel(newLabel: string) {
        if (this.intoConnector.IsConnected()) {
            this.intoConnector.SetIntoLabel(newLabel);
        } else {
            this.state.props.into = newLabel;
        }
    }

    GetInConnector(name: SlotName, label?: string): ChildConnectorIface {
        if (!label) return;

        if (name === 'INPUT1') {
            if (label in this.fromConnectors) {
                return this.fromConnectors[label];
            }

            const conn = new fromConnector(
                label,
                (parentLabel: string) => {
                },
                () => {
                },
                (parentLabel: string) => {
                },
                this,
                (label: string, connector: ChildConnectorIface) => {
                    this.fromConnectors[label] = connector;
                    this.state.props.froms = Array.from(Object.keys(this.fromConnectors)).join(", ");
                    if (this.state.props.froms) {
                      this.Slots["INPUT1"].isChecked = true;
                    }
                },
                (label: string) => {
                    delete this.fromConnectors[label];
                    this.state.props.froms = Array.from(Object.keys(this.fromConnectors)).join(", ");
                    if (!this.state.props.froms) {
                      this.Slots["INPUT1"].isChecked = false;
                    }
                },
                (prevLabel: string, newLabel: string) => {
                    const conn = this.fromConnectors[prevLabel];
                    delete this.fromConnectors[prevLabel];
    
                    this.fromConnectors[newLabel] = conn;
    
                    if (prevLabel in this.intoConnector) {
                        const intoConn = this.intoConnector[prevLabel];
                        delete this.intoConnector[prevLabel];
                        this.intoConnector[newLabel] = intoConn;
                    }
                    this.state.props.froms = Array.from(Object.keys(this.fromConnectors)).join(", ");
                },
            );

            this.fromConnectors[label] = conn;

            return conn;
        }
        
        return null;
    }

    GetOutConnector(name: SlotName, label?: string): ParentConnectorIface {
        if (name === 'OUTPUT1') {
            return this.intoConnector;
        }
        return null;
    }

    OnCmdDelete(): void {
        for (let k in this.fromConnectors) {
            if (this.fromConnectors[k].IsConnected()) {
                this.fromConnectors[k].Disconnect();
            }
        }

        if (this.intoConnector.IsConnected()) {
            this.intoConnector.Disconnect();
        }
    }
}

export { MergeCmdState, MergeCmd };
