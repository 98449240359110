import { createAction, props } from '@ngrx/store';
import { QueryCommandItem } from 'src/app/libs/models/common';
import { IMetadata, IMetadataDatasetWrapper, IQueryObject, IRefreshInterval, IToastrMessage } from 'src/app/libs/types/dataprocessing';

/*
 * LIBS
 */

export const GetQueryCommands = createAction('[GetQueryCommands] Action');
export const GetQueryCommandsSucceed = createAction(
  '[GetQueryCommandsSucceed] Action',
  props<{ queryCommands: any }>()
);
export const GetQueryCommandsFailed = createAction('[GetQueryCommandsFailed] Action');

export const GetTextCollections = createAction('[GetTextCollections] Action');
export const GetTextCollectionsSucceed = createAction(
  '[GetTextCollectionsSucceed] Action',
  props<{ textCollections: any }>()
);
export const GetTextCollectionsFailed = createAction('[GetTextCollectionsFailed] Action');

/*
 * WORKSPACE
 */

export const ResetWorkspace = createAction('[ResetWorkspace] Action', props<{ needToastr: boolean }>());

export const ResetWorkspaceDoUpdate = createAction('[ResetWorkspaceDoUpdate] Action');

export const ExecuteQuery = createAction('[ExecuteQuery] Action', props<{ title: string; isPreview: boolean }>());
export const ExecuteQuerySucceed = createAction('[ExecuteQuerySucceed] Action');
export const ExecuteQueryFailed = createAction('[ExecuteQueryFailed] Action');

export const GetQueryMetadata = createAction(
  '[GetQueryMetadata] Action',
  props<{ quid: string; userQueryID: number }>()
);
export const GetQueryMetadataSucceed = createAction(
  '[GetQueryMetadataSucceed] Action',
  props<{ metadatas: IMetadataDatasetWrapper[] }>()
);
export const GetQueryMetadataFailed = createAction('[GetQueryMetadataFailed] Action');

export const GetQueryResult = createAction(
  '[GetQueryResult] Action',
  props<{ quid: string; userQueryID: number; size: number; skip?: number }>()
);
export const GetQueryResultFailed = createAction('[GetQueryResultFailed] Action');

export const ResetQueryResult = createAction('[ResetQueryResult] Action');

export const SetPqlString = createAction('[SetPqlString] Action', props<{ pql: string }>());
export const ResetPqlString = createAction('[ResetPqlString] Action');

export const SetQueryObject = createAction('[SetQueryObject] Action', props<{ queryObject: IQueryObject }>());
export const UpdateQueryObject = createAction('[UpdateQueryObject] Action', props<{ queryObject: IQueryObject }>());
export const UpdateQueryObjectScheduler = createAction(
  '[UpdateQueryObjectScheduler] Action',
  props<{ interval?: IRefreshInterval; additionalIntervals?: IRefreshInterval[] }>()
);
export const SetActiveTable = createAction('[SetActiveTable] Action', props<{ activeTable: string }>());

export const ResetMetadata = createAction('[ResetMetadata] Action');
export const SetMetadata = createAction(
  '[SetMetadata] Action',
  props<{ dataset: string; columnName: string; metadata: IMetadata }>()
);
export const UpdateMetadata = createAction(
  '[UpdateMetadata] Action',
  props<{ dataset: string; columnName: string; metadata: IMetadata }>()
);

export const SetIsNodesModelToPql = createAction(
  '[SetIsNodesModelToPql] Action',
  props<{ isNodesModelToPql: boolean }>()
);

export const SetIsQueryCommandItemRemoveTriggered = createAction(
  '[SetIsQueryCommandItemRemoveTriggered] Action',
  props<{ isQueryCommandItemRemoveTriggered: boolean }>()
);

export const SetIsQueryRequestTriggeredSubscribed = createAction(
  '[SetIsQueryRequestTriggeredSubscribed] Action',
  props<{ id: string }>()
);

export const ResetIsQueryRequestTriggeredSubscribed = createAction('[ResetIsQueryRequestTriggeredSubscribed]');

export const SetIsDatasetListReloadRequestTriggered = createAction(
  '[SetIsDatasetListReloadRequestTriggered] Action',
  props<{ isDatasetListReloadRequestTriggered: boolean }>()
);

export const SetIsQueryRequestFailed = createAction(
  '[SetIsQueryRequestFailed] Action',
  props<{ isQueryRequestFailed: boolean }>()
);

export const SetIsWorkspaceResetIsTriggered = createAction(
  '[SetIsWorkspaceResetIsTriggered] Action',
  props<{ isWorkspaceResetIsTriggered: boolean }>()
);

export const SetIsLineRedrawingNeeded = createAction(
  '[SetIsLineRedrawingNeeded] Action',
  props<{ isLineRedrawingNeeded: boolean }>()
);

export const SetDrawingDelay = createAction('[SetDrawingDelay] Action', props<{ drawingDelay: number }>());

export const SetIsZoomInOutTriggered = createAction(
  '[SetIsZoomInOutTriggered] Action',
  props<{ isZoomInOutTriggered: boolean }>()
);

export const SetIsDragEnded = createAction('[SetIsDragEnded] Action', props<{ isDragEnded: boolean }>());

export const SetScaleValue = createAction('[SetScaleValue] Action', props<{ scaleValue: number }>());

export const SetIsResultExpanded = createAction('[SetIsResultExpanded] Action', props<{ isResultExpanded: boolean }>());

export const SetIsPanzoomResetTriggered = createAction('[SetIsPanzoomResetTriggered] Action', props<{ isPanzoomResetTriggered: boolean }>());

/*
 * MESSAGE
 */

export const SetMessage = createAction('[SetMessage] Action', props<{ title: string; text: string }>());
export const ResetMessage = createAction('[ResetMessage] Action');

export const SetToastrMessage = createAction('[SetToastrMessage] Action', props<{ toastrMessage: IToastrMessage }>());
export const RemoveToastrMessage = createAction('[RemoveToastrMessage] Action', props<{ index: number }>());
export const RemoveAllToastrMessage = createAction('[RemoveAllToastrMessage] Action');

export const StartTimeoutTrigger = createAction('[StartTimeoutTrigger] Action');
export const StopTimeoutTrigger = createAction('[StopTimeoutTrigger] Action');

export const SetQueryCommandItemEndpoint = createAction(
  '[SetQueryCommandItemEndpoint] Action',
  props<{ queryCommandItemsEndpoint: Array<any> }>()
);
