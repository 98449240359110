<div class="modal fade show" id="modalColumn" tabindex="-1" role="dialog" 
	style="display: block;"
	aria-modal="true">
    <div class="modal-dialog modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-body"> 
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <h5 class="f-14 text-center">Select Column Width Size</h5>
                        <div class="radio-img text-center">
                            <div class="col-md-10 offset-md-1">
                                <label class="w-100">
									<input id="cw-full" type="radio" name="radio1" value="12" checked="" 
									[(ngModel)]="meData.col"
									[ngModelOptions]="{standalone: true}">
                                    <img src="assets/images/grid_column/full_column.svg" width="80%" alt="Full Size">
                                </label>
                            </div>
                            <div class="col-md-10 offset-md-1">
                                <label class="w-100">
                                    <input id="cw-3-4" type="radio" name="radio1" value="9" [(ngModel)]="meData.col"
									[ngModelOptions]="{standalone: true}">
                                    <img src="assets/images/grid_column/3-4_column.svg" width="80%" alt="3-4 Size">
                                </label>
                            </div>
                            <div class="col-md-10 offset-md-1">
                                <label class="w-100">
                                    <input id="cw-2-3" type="radio" name="radio1" value="8" [(ngModel)]="meData.col"
									[ngModelOptions]="{standalone: true}">
                                    <img src="assets/images/grid_column/2-3_column.svg" width="80%" alt="2-3 Size">
                                </label>
                            </div>
                            <div class="col-md-10 offset-md-1">
                                <label class="w-100">
                                    <input id="cw-2" type="radio" name="radio1" value="6" [(ngModel)]="meData.col"
									[ngModelOptions]="{standalone: true}">
                                    <img src="assets/images/grid_column/2_column.svg" width="80%" alt="2 Size">
                                </label>
                            </div>
                            <div class="col-md-10 offset-md-1">
                                <label class="w-100">
                                    <input id="cw-3" type="radio" name="radio1" value="4" [(ngModel)]="meData.col"
									[ngModelOptions]="{standalone: true}">
                                    <img src="assets/images/grid_column/3_column.svg" width="80%" alt="3 Size">
                                </label>
                            </div>
                            <div class="col-md-10 offset-md-1">
                                <label class="w-100">
                                    <input id="cw-4" type="radio" name="radio1" value="3" [(ngModel)]="meData.col"
									[ngModelOptions]="{standalone: true}">
                                    <img src="assets/images/grid_column/4_column.svg" width="80%" alt="4 Size">
                                </label>
                            </div>
                            <div class="col-md-10 offset-md-1">
                                <label class="w-100">
                                    <input id="cw-6" type="radio" name="radio1" value="2" [(ngModel)]="meData.col"
									[ngModelOptions]="{standalone: true}">
                                    <img src="assets/images/grid_column/6_column.svg" width="80%" alt="6 Size">
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <h5 class="f-14 text-center">Select Column Height Size</h5>
                        <div class="radio-img">
                            <div class="col-md-10 offset-md-1">
                                <label class="w-100 mb-3">
									<input id="ch-full" type="radio" name="radio2" value="7" checked="" 
									[(ngModel)]="meData.row"
									[ngModelOptions]="{standalone: true}">
                                    <img src="assets/images/grid_column-height/full_column-height.svg" width="100%" alt="Full Size">
                                </label>
                            </div>
                            <div class="col-md-10 offset-md-1">
                                <label class="w-100 mb-3">
									<input id="ch-half" type="radio" name="radio2" value="5" 
									[(ngModel)]="meData.row"
									[ngModelOptions]="{standalone: true}">
                                    <img src="assets/images/grid_column-height/half_column-height.svg" width="100%" alt="3-4 Size">
                                </label>
                            </div>
                            <div class="col-md-10 offset-md-1">
                                <label class="w-100 mb-3">
									<input id="ch-1-3" type="radio" name="radio2" value="3" 
									[(ngModel)]="meData.row"
									[ngModelOptions]="{standalone: true}">
                                    <img src="assets/images/grid_column-height/1-3_column-height.svg" width="100%" alt="1-3 Size">
                                </label>
                            </div>
                        </div>
                    </div>
                </div>     
            </div>
            <div class="modal-footer">
                <button class="btn btn-info btn-outline btn-lg w-100 mx-5" type="button" data-dismiss="modal" (click)="radioChange($event)"> Apply Size </button>
            </div>
        </div>
    </div>
</div>