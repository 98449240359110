<div>
  <div class="modal-body">
    <p class="text-center">Your session has expired. <br> Please re-log in</p>

    <div class="authentification">
      <form 
        [formGroup]="loginFormControl"
        [ngClass]="isApplication ? 'card auth_form signin-form-app' : 'card auth_form signin-form'"      
        autocomplete="off"
        (keydown.enter)="submitForm()"
      >
        <div class="body mx-3">
          <div class="form-group w-100">
            <input type="text" class="form-control form-bottomline" placeholder="Email" name="email" formControlName="email" required>
            <span matError *ngIf="isControlHasError('email', 'required')">
              <small class="text-danger">{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</small>
            </span>
          </div>
          <div class="form-group w-100">
            <div id="show_hide_password" class="input-group">
              <input 
                [type]="fieldTextType ? 'text' : 'password'"
                class="form-control form-bottomline" 
                placeholder="Password" 
                name="password" 
                formControlName="password" 
                required
              >
              <div class="input-group-append form-bottomline group-bottomline">
                <a href="javascript:void(0)" class="input-group-text border-0 pr-0">
                  <span class="input-group-addon">
                    <i
                      class="zmdi"
                      [ngClass]="{
                        'zmdi-eye-off': !fieldTextType,
                        'zmdi-eye': fieldTextType
                      }"
                      (click)="toggleFieldTextType()"
                    ></i>
                  </span>
                </a>
              </div>
            </div>
            <span matError *ngIf="isControlHasError('password', 'required')">
              <small class="text-danger">{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</small>
            </span>
          </div>
          <div class="row">
            <div class="form-group w-100">
              <input type="text" formControlName="captcha" class="form-control form-bottomline" placeholder="Captcha" name="captcha" required />
              <span matError *ngIf="isControlHasError('captcha', 'required')">
                <small class="text-danger">{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</small>
              </span>
            </div>
            <div class="col-md-6">
              <!-- start capcha -->

              <img [attr.src]="captchaBase64" alt="captcha" style="width: 50%; margin-bottom: 12px; margin-left: 4px; background-color: white; height: 40px" />
              <!-- end capcha -->
            </div>
            <div class="col-md-6">
              <small class="float-right mb-4" style="float: right"><a href="#" data-type="forgot-password">Forgot Password?</a></small>
            </div>
            <div>
              <small class="mb-4" >
                Wants new Captcha code? 
                <a href="javascript:;" (click)="refreshCaptcha()" data-type="forgot-password">Refresh</a>
              </small>
            </div>
          </div>
          <div class="jsdemo-notification-button">
            <button href="#" type="button" class="btn btn-warning btn-lg signin-btn btn-block waves-effect waves-light" data-placement-from="bottom" data-placement-align="center" data-animate-enter="" data-animate-exit="" data-color-name="bg-red" (click)="submitForm()">SIGN IN</button>
          </div>
          <div class="row mt-2 mx-1">
            <hr class="col-md-5 mt-3"/>
            <div class="col-md-2 text-center">OR</div>
            <hr class="col-md-5 mt-3"/>
          </div>

          <button href="#" type="button" class="btn btn-outline btn-lg signin-other-account btn-block" (click)="goToAuthPage()">Login with another account</button>
        </div>

        <div class="copyright text-center f-10">Copyright &copy; <script>document.write(new Date().getFullYear())</script><a href="https://paques.id/" target="_blank">PT Informatika Solusi Bisnis</a></div>
      </form>
    </div>
  </div>
</div>