import { ActionCreator, createReducer, on } from '@ngrx/store';
import _ from 'lodash';

import { DataProcessingState } from 'src/app/libs/store/states/dataprocessing.state';

import {
  SetQueryObject,
  GetQueryCommandsSucceed,
  ResetPqlString,
  SetPqlString,
  SetIsNodesModelToPql,
  SetIsQueryCommandItemRemoveTriggered,
  SetMessage,
  ResetMessage,
  SetIsDatasetListReloadRequestTriggered,
  SetIsWorkspaceResetIsTriggered,
  SetActiveTable,
  UpdateMetadata,
  SetMetadata,
  SetIsQueryRequestTriggeredSubscribed,
  ResetIsQueryRequestTriggeredSubscribed,
  SetIsQueryRequestFailed,
  RemoveToastrMessage,
  SetToastrMessage,
  SetIsLineRedrawingNeeded,
  UpdateQueryObject,
  SetScaleValue,
  SetIsZoomInOutTriggered,
  SetIsDragEnded,
  ResetMetadata,
  UpdateQueryObjectScheduler,
  SetDrawingDelay,
  SetIsResultExpanded,
  SetIsPanzoomResetTriggered,
  RemoveAllToastrMessage,
} from 'src/app/libs/store/actions/pds/dataprocessing.actions';
import { IMetadataDatasetWrapper, IQueryObject } from 'src/app/libs/types/dataprocessing';

const initialDataProcessingState: DataProcessingState = {
  libs: {
    queryCommands: null,
  },
  workspace: {
    pqlString: null,
    nodesModel: null,
    queryObject: null,
    metadatas: null,
    activeTable: null,

    isQueryRequestTriggeredSubscriber: null,

    isQueryRequestFailed: null,
    isQuerySaveSucceeded: null,
    isMetadataSaveSucceeded: null,

    isNodesModelToPql: null,
    isQueryCommandItemRemoveTriggered: null,
    isDataSourceListReloadRequestTriggered: null,
    isDatasetListReloadRequestTriggered: null,
    isWorkspaceResetIsTriggered: null,
    isLineRedrawingNeeded: null,
    isZoomInOutTriggered: null,
    isDragEnded: null,
    isResultExpanded: null,
    isPanzoomResetTriggered: null,

    scaleValue: 1,
    drawingDelay: 0,
  },
  message: {
    title: '',
    text: '',
  },
  toastrMessages: null,
};

export const dataProcessingReducer = createReducer(
  initialDataProcessingState,

  /*
   * LIBS
   */
  on(GetQueryCommandsSucceed, (state: DataProcessingState, props: any) => {
    const libs = { ...state.libs };

    libs.queryCommands = props.queryCommands;

    return {
      ...state,
      libs,
    };
  }),

  /*
   * WORKSPACE
   */
  on(SetPqlString, (state: DataProcessingState, props: any) => {
    const workspace = { ...state.workspace };
    workspace.pqlString = props.pql;

    return {
      ...state,
      workspace,
    };
  }),
  on(ResetPqlString, (state: DataProcessingState) => {
    const workspace = { ...state.workspace };
    workspace.pqlString = null;

    return {
      ...state,
      workspace,
    };
  }),
  on(ResetMetadata, (state: DataProcessingState) => {
    const workspace = { ...state.workspace };
    workspace.metadatas = null;
    return {
      ...state,
      workspace,
    };
  }),
  on(SetMetadata, (state: DataProcessingState, props: any) => {
    const workspace = { ...state.workspace };
    const { columnName, dataset, metadata } = props;

    let metadatas: IMetadataDatasetWrapper[] = [];
    metadatas = workspace.metadatas && workspace.metadatas.length > 0 ? [...workspace.metadatas] : [];
    const dsExist = metadatas.find((x) => x.dataset === dataset);
    if (dsExist) {
      metadatas = metadatas.map((md) => {
        if (md.dataset === dataset) {
          if (!md.columns.some((col) => col.columnName === columnName)) {
            return {
              ...md,
              columns: [...md.columns, { columnName, metadata }],
            };
          }
        }
        return { ...md };
      });
    } else {
      metadatas.push({
        dataset,
        columns: [{ columnName, metadata }],
      });
    }

    workspace.metadatas = metadatas;

    return {
      ...state,
      workspace,
    };
  }),
  on(UpdateMetadata, (state: DataProcessingState, props: any) => {
    const workspace = { ...state.workspace };

    const metadatas =
      workspace.metadatas && workspace.metadatas.length > 0
        ? workspace.metadatas.map((metadata: IMetadataDatasetWrapper) => {
            if (metadata.dataset === props.dataset) {
              const columns = metadata.columns.map((column) => {
                if (column.columnName === props.columnName) {
                  return {
                    ...column,
                    metadata: props.metadata,
                  };
                }

                return {
                  ...column,
                };
              });

              return {
                ...metadata,
                columns,
              };
            }

            return {
              ...metadata,
            };
          })
        : null;

    workspace.metadatas = metadatas;

    return {
      ...state,
      workspace,
    };
  }),
  on(SetIsNodesModelToPql, (state: DataProcessingState, props: any) => {
    const workspace = { ...state.workspace };
    workspace.isNodesModelToPql = props.isNodesModelToPql;

    return {
      ...state,
      workspace,
    };
  }),
  on(SetIsQueryCommandItemRemoveTriggered, (state: DataProcessingState, props: any) => {
    const workspace = { ...state.workspace };
    workspace.isQueryCommandItemRemoveTriggered = props.isQueryCommandItemRemoveTriggered;

    return {
      ...state,
      workspace,
    };
  }),
  on(SetIsQueryRequestTriggeredSubscribed, (state: DataProcessingState, props: any) => {
    const workspace = { ...state.workspace };

    const isQueryRequestTriggeredSubscriber =
      workspace.isQueryRequestTriggeredSubscriber && workspace.isQueryRequestTriggeredSubscriber.length > 0
        ? [...workspace.isQueryRequestTriggeredSubscriber]
        : [];

    if (isQueryRequestTriggeredSubscriber.indexOf(props.id) < 0) isQueryRequestTriggeredSubscriber.push(props.id);

    workspace.isQueryRequestTriggeredSubscriber = isQueryRequestTriggeredSubscriber;

    return {
      ...state,
      workspace,
    };
  }),
  on(ResetIsQueryRequestTriggeredSubscribed, (state) => {
    const workspace = { ...state.workspace };
    workspace.isQueryRequestTriggeredSubscriber = null;

    return {
      ...state,
      workspace,
    };
  }),
  on(
    { type: 'SetIsDataSourceListReloadRequestTriggered' } as ActionCreator,
    (state, props: { type: string; isDataSourceListReloadRequestTriggered: boolean }) => {
      const workspace = { ...state.workspace };
      workspace.isDataSourceListReloadRequestTriggered = props.isDataSourceListReloadRequestTriggered;

      return {
        ...state,
        workspace,
      };
    }
  ),
  on(SetIsDatasetListReloadRequestTriggered, (state: DataProcessingState, props: any) => {
    const workspace = { ...state.workspace };
    workspace.isDatasetListReloadRequestTriggered = props.isDatasetListReloadRequestTriggered;

    return {
      ...state,
      workspace,
    };
  }),
  on(SetIsQueryRequestFailed, (state: DataProcessingState, props: any) => {
    const workspace = { ...state.workspace };
    workspace.isQueryRequestFailed = props.isQueryRequestFailed;

    return {
      ...state,
      workspace,
    };
  }),
  on(SetIsWorkspaceResetIsTriggered, (state: DataProcessingState, props: any) => {
    const workspace = { ...state.workspace };
    workspace.isWorkspaceResetIsTriggered = props.isWorkspaceResetIsTriggered;

    return {
      ...state,
      workspace,
    };
  }),
  on(SetIsLineRedrawingNeeded, (state: DataProcessingState, props: any) => {
    const workspace = { ...state.workspace };
    workspace.isLineRedrawingNeeded = props.isLineRedrawingNeeded;

    return {
      ...state,
      workspace,
    };
  }),
  on(SetDrawingDelay, (state: DataProcessingState, props: any) => {
    const workspace = { ...state.workspace };
    workspace.drawingDelay = props.drawingDelay;

    return {
      ...state,
      workspace,
    };
  }),
  on(SetIsZoomInOutTriggered, (state: DataProcessingState, props: any) => {
    const workspace = { ...state.workspace };
    workspace.isZoomInOutTriggered = props.isZoomInOutTriggered;

    return {
      ...state,
      workspace,
    };
  }),
  on(SetIsDragEnded, (state: DataProcessingState, props: any) => {
    const workspace = { ...state.workspace };
    workspace.isDragEnded = props.isDragEnded;

    return {
      ...state,
      workspace,
    };
  }),
  on(SetIsResultExpanded, (state: DataProcessingState, props: any) => {
    const workspace = { ...state.workspace };
    workspace.isResultExpanded = props.isResultExpanded;

    return {
      ...state,
      workspace,
    };
  }),
  on(SetIsPanzoomResetTriggered, (state: DataProcessingState, props: any) => {
    const workspace = { ...state.workspace };
    workspace.isPanzoomResetTriggered = props.isPanzoomResetTriggered;

    return {
      ...state,
      workspace,
    };
  }),
  on(SetQueryObject, (state: DataProcessingState, props: any) => {
    const workspace = { ...state.workspace };
    workspace.queryObject = props.queryObject;

    return {
      ...state,
      workspace,
    };
  }),
  on(UpdateQueryObject, (state: DataProcessingState, props: any) => {
    const workspace = { ...state.workspace };

    const queryObject = {
      ID: props.queryObject.ID ? props.queryObject.ID : workspace.queryObject.ID,
      title: props.queryObject.title ? props.queryObject.title : workspace.queryObject.title,
      quid: props.queryObject.quid ? props.queryObject.quid : workspace.queryObject.quid,
      lastRun: props.queryObject.lastRun ? props.queryObject.lastRun : workspace.queryObject.lastRun,
    };

    workspace.queryObject = { ...workspace.queryObject, ...queryObject };

    return {
      ...state,
      workspace,
    };
  }),
  on(UpdateQueryObjectScheduler, (state: DataProcessingState, props: any) => {
    const workspace = { ...state.workspace };

    const queryObject: IQueryObject = {
      ...workspace.queryObject,
    };

    if (props.interval) {
      queryObject.refreshInterval = props.interval;
    }

    if (props.additionalIntervals) {
      queryObject.additionalRefreshInterval = props.additionalIntervals;
    }

    workspace.queryObject = queryObject;

    return {
      ...state,
      workspace,
    };
  }),
  on(SetActiveTable, (state: DataProcessingState, props: any) => {
    const workspace = { ...state.workspace };
    workspace.activeTable = props.activeTable;

    return {
      ...state,
      workspace,
    };
  }),
  on(SetScaleValue, (state: DataProcessingState, props: any) => {
    const workspace = { ...state.workspace };
    workspace.scaleValue = props.scaleValue;

    return {
      ...state,
      workspace,
    };
  }),
  on(SetMessage, (state: DataProcessingState, props: any) => {
    return {
      ...state,
      message: {
        title: props.title,
        text: props.text,
      },
    };
  }),
  on(ResetMessage, (state) => {
    return {
      ...state,
      message: {
        title: '',
        text: '',
      },
    };
  }),
  on(SetToastrMessage, (state: DataProcessingState, props: any) => {
    let toastrMessages = state.toastrMessages && state.toastrMessages.length > 0 ? [...state.toastrMessages] : null;
    if (!toastrMessages || (toastrMessages && toastrMessages.length === 0)) {
      toastrMessages = [];
    }
    toastrMessages.push(props.toastrMessage);

    return {
      ...state,
      toastrMessages,
    };
  }),
  on(RemoveToastrMessage, (state: DataProcessingState, props: any) => {
    const _toastrMessages = [...state.toastrMessages];
    const toastrMessages = _toastrMessages.filter((_toastrMessage, i) => i !== props.index);

    return {
      ...state,
      toastrMessages,
    };
  }),
  on(RemoveAllToastrMessage, (state: DataProcessingState, props: any) => {
    const toastrMessages = [];

    return {
      ...state,
      toastrMessages,
    };
  }),
);
