import { Cmd, CmdIface, CmdTypes, CmdUISetting, IntoLabelGenerator } from './command.model';
import { ChildConnector, ChildConnectorIface, ParentConnector, ParentConnectorIface } from './connection.model';
import { CmdSlots, Slot, SlotName, SlotType } from './query.model';

const cmdType: CmdTypes = 'CONFIG';

interface ConfigCmdProps {
  type: string;
  path: string;
  separator: string;
  columns?: string;
  head_pos?: number;
  trim?: boolean;
  validate?: boolean;
  datatypes?: string;
  force_replace?: boolean;
}

interface ConfigCmdState {
  node_type: CmdTypes;
  props: ConfigCmdProps;
}

class ConfigCmd extends Cmd implements CmdIface {
  private state: ConfigCmdState;

  constructor(state?: ConfigCmdState) {
    super(cmdType);

    if (state) {
      this.state = state;
    } else {
      this.state = {
        node_type: cmdType,
        props: {
          type: '',
          path: '',
          separator: '',
          columns: '',
          head_pos: 0,
          trim: false,
          validate: false,
          datatypes: '',
          force_replace: true,
        },
      };
    }
    super.SetNewProp(this.state.props);
  }

  SetIntoLabel(newLabel: string) {}

  GetInConnector(name: SlotName, label?: string): ChildConnectorIface {
    return null;
  }

  GetOutConnector(name: SlotName, label?: string): ParentConnectorIface {
    return null;
  }

  OnCmdDelete(): void {}
}

export { ConfigCmdState, ConfigCmd };
