import { Cmd, CmdIface, CmdTypes, IntoLabelGenerator } from './command.model';
import { ChildConnectorIface, ParentConnector, ParentConnectorIface } from './connection.model';
import { SlotName } from './query.model';

const cmdType: CmdTypes = 'SEARCH';

interface SearchCmdProps {
  search_str?: string;
  paths: string[];
  has_limit: boolean;
  has_offset: boolean;
  limit?: number;
  offset?: number;
  is_csv: boolean;
  is_gz: boolean;
  is_folder: boolean;
  include_header: boolean;
  csv_separator?: string;
  csv_columns?: string;
  label: string;
  datatypes?: string;
}

interface SearchCmdState {
  node_type: CmdTypes;
  props: SearchCmdProps;
}

class SearchCmd extends Cmd implements CmdIface {
  private state: SearchCmdState;
  private outConnector: ParentConnectorIface;

  constructor(state?: SearchCmdState) {
    super(cmdType);

    if (state) {
      this.state = state;
    } else {
      this.state = {
        node_type: cmdType,
        props: {
          search_str: null,
          paths: [],
          has_limit: false,
          has_offset: false,
          limit: null,
          offset: null,
          is_csv: false,
          is_gz: false,
          is_folder: false,
          include_header: false,
          csv_separator: null,
          csv_columns: null,
          datatypes: '',
          label: IntoLabelGenerator(cmdType),
        },
      };
    }
    super.SetNewProp(this.state.props);

    this.outConnector = new ParentConnector(
      'OUTPUT1',
      this.state.props.label,
      () => this.state.props.label,
      (newLabel: string) => {
        this.state.props.label = newLabel;
      },
      () => {
        this.Slots['OUTPUT1'].isChecked = true;
      },
      () => {
        this.Slots['OUTPUT1'].isChecked = false;
      },
      this
    );
  }

  SetIntoLabel(label: string) {
    if (this.outConnector.IsConnected()) {
      this.outConnector.SetIntoLabel(label);
    } else {
      this.state.props.label = label;
    }
  }

  GetInConnector(name: SlotName, label?: string): ChildConnectorIface {
    return null;
  }

  GetOutConnector(name: SlotName, label?: string): ParentConnectorIface {
    if (name === 'OUTPUT1') {
      return this.outConnector;
    }
    return null;
  }

  OnCmdDelete(): void {
    if (this.outConnector.IsConnected()) {
      this.outConnector.Disconnect();
    }
  }
}

export { SearchCmdState, SearchCmd, SearchCmdProps };
