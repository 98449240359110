import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/libs/services';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/libs/store/states';

import { SetToastrMessage } from 'src/app/libs/store/actions/pds/dataprocessing.actions';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

interface IFaceChangePassword {
  old_password: string;
  password: string;
  password_confirmation: string;
}
@Component({
  selector: 'pq-changepassword-dialog',
  templateUrl: './changepassword-dialog.component.html',
})
export class ModalChangePasswordComponent implements OnInit {
  public changePasswordFormGroup: FormGroup;
  public change_password_form: IFaceChangePassword;
  public loading: boolean = false;
  public message: string;
  constructor(
    private dbFormBuilder: FormBuilder,
    private apiService: ApiService,
    private store$: Store<AppState>,
    public activeModal: NgbActiveModal,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.changePasswordFormGroup = this.dbFormBuilder.group({
      old_password: [{ value: '', disabled: false }, Validators.compose([Validators.required])],
      password: [
        { value: '', disabled: false },
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/),
        ]),
      ],
      password_confirmation: [
        { value: '', disabled: false },
        Validators.compose([
          Validators.required,
          Validators.pattern(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/),
          Validators.minLength(8),
        ]),
      ],
    });
  }

  async saveChangePassword() {
    const controls = this.changePasswordFormGroup.controls;

    if (this.changePasswordFormGroup.status === 'INVALID') {
      Object.keys(controls).forEach((controlName) => {
        controls[controlName].markAsTouched();
        if (controls[controlName].hasError('required')) {
          controls[controlName].setErrors({
            additional: true,
            message:
              controlName === 'old_password'
                ? this.translate.instant('AUTH.VALIDATION.OP_V')
                : controlName === 'password'
                ? this.translate.instant('AUTH.VALIDATION.NP_V')
                : this.translate.instant('AUTH.VALIDATION.CP_V'),
          });
        }

        if (controls[controlName].hasError('minlength')) {
          controls[controlName].setErrors({
            additional: true,
            message: this.translate.instant('AUTH.VALIDATION.MIN_LENGTH_FIELD'),
          });
        }
        if (controls[controlName].hasError('pattern')) {
          controls[controlName].setErrors({
            additional: true,
            message: this.translate.instant('AUTH.VALIDATION.PATTERN'),
          });
        }
        if (controls[controlName].hasError('minlength') && controls[controlName].hasError('pattern')) {
          controls[controlName].setErrors({
            additional: true,
            message: `${this.translate.instant('AUTH.VALIDATION.MIN_LENGTH_FIELD')} ${this.translate.instant(
              'AUTH.VALIDATION.AND'
            )} ${this.translate.instant('AUTH.VALIDATION.PATTERN')}`,
          });
        }
      });
      return;
    }
    if (this.changePasswordFormGroup) {
      let base = null;
      this.loading = true;
      base = await this.apiService.postApi(
        'api/profile/password',
        {
          old_password: this.changePasswordFormGroup.value.old_password,
          password_confirmation: this.changePasswordFormGroup.value.password_confirmation,
          password: this.changePasswordFormGroup.value.password,
        },
        false
      );
      if (base.status) {
        this.store$.dispatch(
          SetToastrMessage({
            toastrMessage: {
              toastrType: 'info',
              message: 'Password changed successfully, you will be automatically logged out',
            },
          })
        );
        setTimeout(() => {
          this.loading = false;
          this.activeModal.close(true);
        }, 1000);
      } else {
        this.loading = false;
        this.store$.dispatch(
          SetToastrMessage({
            toastrMessage: {
              toastrType: 'error',
              message: base.result.message || 'Password failed to change',
            },
          })
        );
      }
    }
  }

  _close() {
    this.activeModal.close();
  }
}
