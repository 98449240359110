import { v4 } from 'uuid';
import { ChildConnectorIface, ParentConnectorIface } from './connection.model';
import { CmdSlots, Slot, SlotName } from './query.model';

type CmdTypes = "SELECTDB" | "SELECT" | "COPY" | "CONFIG" | "CREATEINDEX" | "DROP" | "EXPLODE" | "SEARCH" | "EXTRACT" | "IMPLODE"
  | "VIEW" | "FILTER" | "AGGREGATE" | "DEDUP" | "SORT" | "LIMIT" | "MERGE" | "CORRELATEREF" | "STORE";

interface CmdUISetting {
  buttonClass: string;
  image: string;
}

interface CmdBasicInfoIface {
  GetID(): string;
  GetCoordinate(): { x: number; y: number };
  Slots: { [key: string]: Slot };
  NodeType(): CmdTypes;
}

interface CmdIface extends CmdBasicInfoIface {
  SetID(ID: string): void;
  SetCoordinate(x: Number, y: Number): void;
  GetInConnector(name: SlotName, label?: string): ChildConnectorIface;
  GetOutConnector(name: SlotName, label?: string): ParentConnectorIface;
  SetIntoLabel(label: string): void;
  GetProps(): any;
  SetProp(key: string, value: string | number | boolean | Array<any> | Object | null): boolean;
  OnCmdDelete(): void
}

class Cmd implements CmdBasicInfoIface {
  private _uuid: string;
  private _props: any;
  private _coordinate: { x: number; y: number };

  public node_type: CmdTypes;
  public Slots: { [key: string]: Slot } = {};


  constructor(node_type: CmdTypes, props?: any) {
    this._uuid = v4();
    this._coordinate = { x: 0, y: 0 };
    this.node_type = node_type;
    this._props = props;
    for (let i = 0; i < CmdSlots[node_type]?.length; i++) {
      this.Slots[CmdSlots[node_type][i].name] = { ...CmdSlots[node_type][i] };
    }
  }

  SetNewProp(props: any) {
    this._props = props;
  }

  GetID(): string {
    return this._uuid;
  }

  SetID(ID: string) {
    this._uuid = ID;
  }

  GetCoordinate(): { x: number; y: number } {
    return this._coordinate;
  }

  SetCoordinate(x: number, y: number): void {
    this._coordinate.x = x;
    this._coordinate.y = y;
  }

  NodeType(): CmdTypes {
    return this.node_type;
  }

  GetProps(): any {
    return this._props;
  }

  SetProp(key: string, value?: string | number | boolean | Array<any> | Object | null): boolean {
    return SetCmdProp(this._props, key, value);
  }
}

type nextTypes = "Array" | "Object" | "Done";

function checktMatch(match: IteratorResult<RegExpMatchArray, any>): nextTypes {
  if (match.done) {
    return "Done";
  } else if (match.value[1]) {
    return "Object";
  } else {
    return "Array";
  }
}

const PropRe = /([a-zA-Z_]+((\-*[a-zA-Z_0-9]+)|([a-zA-Z_0-9]))*)+|\[([0-9]+)\]/g;
function SetCmdProp(prop: Object, key: string, value?: string | number | boolean | Array<any> | Object | null): boolean {
  const matches = key.matchAll(PropRe);
  let checked: any = prop;
  let match = matches.next();
  let nextMatch: IteratorResult<RegExpMatchArray, any>;
  let i = 0;
  while (!match.done) {
    nextMatch = matches.next();
    const nextDataType = checktMatch(nextMatch);
    const element = match.value;
    if (element[1]) {
      if (checked.constructor === Object) {
        switch (nextDataType) {
          case "Done":
            if (value === undefined) {
              delete checked[element[1]];
            } else {
              checked[element[1]] = value;
            }
            return true;
          case "Object":
            if (!checked[element[1]]) {
              checked[element[1]] = {};
            } else if (element[1] in checked && checked[element[1]].constructor !== Object) {
              return false;
            }
            break;
          case "Array":
            if (!checked[element[1]]) {
              checked[element[1]] = [];
            } else if (element[1] in checked && checked[element[1]].constructor !== Array) {
              return false;
            }
            break;
        }
        checked = checked[element[1]];
      } else {
        return false;
      }
    } else if (element[5]) {
      if (checked.constructor === Array) {
        const numb = parseInt(element[5]);
        switch (nextDataType) {
          case "Done":
            if (value === undefined) {
              (checked as Array<any>).splice(numb, 1);
            } else {
              checked[numb] = value;
            }
            return true;
          case "Object":
            if (!checked[numb]) {
              checked[numb] = {};
            } else if (checked[numb].constructor !== Object) {
              return false;
            }
            break;
          case "Array":
            if (!checked[numb]) {
              checked[numb] = [];
            } else if (checked[numb].constructor !== Array) {
              return false;
            }
            break;
        }
        checked = checked[element[1]];
      } else {
        return false;
      }
    }

    match = nextMatch;
  }
  return false;
}

function IntoLabelGenerator(cmd: CmdTypes): string {
    return cmd as string + "_" + Math.floor(Math.random() * 10000000000)
}

export { Cmd, CmdUISetting, CmdBasicInfoIface, CmdIface, SetCmdProp, CmdTypes, IntoLabelGenerator };
