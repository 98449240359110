import { Cmd, CmdIface, CmdTypes, IntoLabelGenerator } from './command.model';
import { ChildConnector, ChildConnectorIface, ParentConnector, ParentConnectorIface } from './connection.model';
import { SlotName } from './query.model';

const cmdType: CmdTypes = 'EXPLODE';

interface ExplodeCmdProps {
  distinct: string;
  column: string;
  delimiter: string;
  sort: string;
  from: string;
  into: string;
}

interface ExplodeCmdState {
  node_type: CmdTypes;
  props: ExplodeCmdProps;
}

class ExplodeCmd extends Cmd implements CmdIface {
  private state: ExplodeCmdState;
  private fromConnector: ChildConnectorIface;
  private intoConnector: ParentConnectorIface;

  constructor(state?: ExplodeCmdState) {
    super(cmdType);

    if (state) {
      this.state = state;
    } else {
      this.state = {
        node_type: cmdType,
        props: {
          distinct: '',
          column: '',
          delimiter: '',
          sort: '',
          from: '',
          into: IntoLabelGenerator(cmdType),
        },
      };
    }
    super.SetNewProp(this.state.props);

    this.fromConnector = new ChildConnector(
      'INPUT1',
      '',
      (parentLabel: string) => {
        this.state.props.from = parentLabel;
        this.Slots['INPUT1'].isChecked = true;
      },
      () => {
        this.state.props.from = '';
        this.Slots['INPUT1'].isChecked = false;
      },
      (parentLabel: string) => {
        this.state.props.from = parentLabel;
      },
      this
    );

    this.intoConnector = new ParentConnector(
      'OUTPUT1',
      '',
      () => this.state.props.into,
      (newLabel: string) => {
        this.state.props.into = newLabel;
      },
      () => {
        this.Slots['OUTPUT1'].isChecked = true;
      },
      () => {
        this.Slots['OUTPUT1'].isChecked = false;
      },
      this
    );
  }

  SetIntoLabel(newLabel: string) {
    if (this.intoConnector.IsConnected()) {
      this.intoConnector.SetIntoLabel(newLabel);
    } else {
      this.state.props.into = newLabel;
    }
  }

  GetInConnector(name: SlotName, label?: string): ChildConnectorIface {
    if (name === 'INPUT1') {
      return this.fromConnector;
    }
    return null;
  }

  GetOutConnector(name: SlotName, label?: string): ParentConnectorIface {
    if (name === 'OUTPUT1') {
      return this.intoConnector;
    }
    return null;
  }

  OnCmdDelete(): void {
    if (this.fromConnector.IsConnected()) {
      this.fromConnector.Disconnect();
    }

    if (this.intoConnector.IsConnected()) {
      this.intoConnector.Disconnect();
    }
  }
}

export { ExplodeCmdState, ExplodeCmd };
