<div class="dialog-common">
  <div class="dialog-common-wrapper">
    <div style="display: flex; flex-direction: column">
      <h1 *ngIf="!!dialogData.title" mat-dialog-title>{{ dialogData.title | translate }}</h1>

      <div style="display: flex; flex-direction: row; column-gap: 30px">
        <div *ngIf="dialogData?.config?.needIcon" style="width: 40px">
          <div *ngIf="dialogData?.config?.isAlert" class="w_icon orange">
            <i class="zmdi zmdi-alert-circle-o"></i>
          </div>
        </div>

        <div mat-dialog-content>{{ dialogData.description | translate }}</div>
      </div>

      <div mat-dialog-actions>
        <button *ngIf="dialogData?.config?.needCancel" mat-button cdkFocusInitial (click)="btnCancel()">
          {{ 'MODULE.DATA_PROCESSING.COMMON.CAN' | translate }}
        </button>
        <button mat-button (click)="btnOk()">
          {{ 'MODULE.DATA_PROCESSING.COMMON.OK' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
