interface VisualTypeListInterface {
  dist_bar: VisualObject;
  sunburst: VisualObject;
  histogram: VisualObject;
  horizontal_bar: VisualObject;
  box_plot: VisualObject;
  bubble: VisualObject;
  dual_line: VisualObject;
  line: VisualObject;
  filter_box: VisualObject;
  directed_force: VisualObject;
  map: VisualObject;
  country_map: VisualObject;
  osmmap: VisualObject;
  country_map2: VisualObject;
  markup: VisualObject;
  gauge: VisualObject;
  mix: VisualObject;
  big_number_total: VisualObject;
  pie: VisualObject;
  scatter: VisualObject;
  predictive: VisualObject;
  area: VisualObject;
  heatmap: VisualObject;
  treemap: VisualObject;
  table: VisualObject;
  pivot_table: VisualObject;
  time_table: VisualObject;
  word_cloud: VisualObject;
  table_comparison: VisualObject;
  sparkline: VisualObject;
  sparkline_table: VisualObject;
  map_density: VisualObject;
  map_pin_point: VisualObject;
  numerical_filter: VisualObject;
}

interface VisualObject {
  value: string;
  label: string;
  imageLg?: string;
  image?: string;
  display?: string;
}
interface VisualType {
  visual_type: Array<VisualObject>;
  visual_type_chart_list: VisualTypeListInterface;
}

export const visual_type_list: VisualTypeListInterface = {
  dist_bar: {
    value: 'dist_bar',
    label: 'Bar Chart',
    imageLg: '/assets/images/visualization_type_lg/bar_lg.svg',
  },

  sunburst: {
    value: 'sunburst',
    label: 'Sunburst',
    imageLg: './assets/images/visualization_type_lg/sunburst.svg',
  },
  histogram: {
    value: 'histogram',
    label: 'Histogram',
    imageLg: '/assets/images/visualization_type_lg/histogram_lg.svg',
  },
  horizontal_bar: {
    value: 'horizontal_bar',
    label: 'Horizontal Bar Chart',
    imageLg: '/assets/images/visualization_type_lg/bar_lg.svg',
  },
  box_plot: {
    value: 'box_plot',
    label: 'Box Plot',
    imageLg: '/assets/images/visualization_type_lg/box_lg.svg',
  },
  bubble: {
    value: 'bubble',
    label: 'Bubble Chart',
    imageLg: '/assets/images/visualization_type_lg/bubble_lg.svg',
  },
  dual_line: {
    value: 'dual_line',
    label: 'Dual Line Chart',
    imageLg: '/assets/images/visualization_type_lg/dualline_lg.svg',
  },
  line: {
    value: 'line',
    label: 'Line Chart',
    imageLg: '/assets/images/visualization_type_lg/dualline_lg.svg',
  },
  filter_box: {
    value: 'filter_box',
    label: 'Filter Box',
    imageLg: '/assets/images/visualization_type_lg/filter_lg.svg',
  },
  directed_force: {
    value: 'directed_force',
    label: 'Force-Directed Graph',
    imageLg: '/assets/images/visualization_type_lg/graph_lg.svg',
  },
  map: {
    value: 'map',
    label: 'Map ',
    imageLg: '/assets/images/visualization_type_lg/map_lg.svg',
  },
  country_map: {
    value: 'country_map',
    label: 'Country Map',
    imageLg: '/assets/images/visualization_type_lg/map_lg.svg',
  },
  osmmap: {
    value: 'osmmap',
    label: 'OSM Map',
    imageLg: '/assets/images/visualization_type_lg/map_lg.svg',
  },

  country_map2: {
    value: 'country_map2',
    label: 'Overlay Map',
    imageLg: '/assets/images/visualization_type_lg/map_lg.svg',
  },
  markup: {
    value: 'markup',
    label: 'Markup',
    imageLg: '/assets/images/visualization_type_lg/markup_lg.svg',
  },
  gauge: {
    value: 'gauge',
    label: 'Gauge Chart',
    imageLg: '/assets/images/visualization_type_lg/gauge_lg.svg',
  },
  mix: {
    value: 'mix',
    label: 'Mix Chart',
    imageLg: '/assets/images/visualization_type_lg/mix_lg.svg',
  },
  big_number_total: {
    value: 'big_number_total',
    label: 'Big Number',
    imageLg: '/assets/images/visualization_type_lg/number_lg.svg',
  },
  pie: {
    value: 'pie',
    label: 'Pie Chart',
    imageLg: '/assets/images/visualization_type_lg/pie_lg.svg',
  },
  scatter: {
    value: 'scatter',
    label: 'Scatter Plot',
    imageLg: '/assets/images/visualization_type_lg/area_lg.svg',
  },
  predictive: {
    value: 'predictive',
    label: 'Predictive Area',
    imageLg: '/assets/images/visualization_type_lg/area_lg.svg',
  },
  area: {
    value: 'area',
    label: 'Area',
    imageLg: '/assets/images/visualization_type_lg/area_lg.svg',
  },
  heatmap: {
    value: 'heatmap',
    label: 'Heatmap',
    imageLg: '/assets/images/visualization_type_lg/heatmap_lg.svg',
  },
  treemap: {
    value: 'treemap',
    label: 'Treemap',
    imageLg: '/assets/images/visualization_type_lg/treemap_lg.svg',
  },
  table: {
    value: 'table',
    label: 'Table',
    imageLg: '/assets/images/visualization_type_lg/table_lg.svg',
  },
  pivot_table: {
    value: 'pivot_table',
    label: 'Pivot Table',
    imageLg: '/assets/images/visualization_type_lg/table_lg.svg',
  },
  time_table: {
    value: 'time_table',
    label: 'Time Series Table',
    imageLg: '/assets/images/visualization_type_lg/linetable_lg.svg',
  },
  word_cloud: {
    value: 'word_cloud',
    label: 'Word Cloud',
    imageLg: '/assets/images/visualization_type_lg/word_lg.svg',
  },
  table_comparison: {
    value: 'table_comparison',
    label: 'Comparison Table',
    imageLg: './assets/images/visualization_type_icon/table-comparison.svg',
  },
  sparkline: {
    value: 'sparkline',
    label: 'Sparkline Big Number',
    imageLg: './assets/images/visualization_type_lg/spark-line-lg.svg',
  },
  sparkline_table: {
    value: 'sparkline_table',
    label: 'Sparkline Table',
    imageLg: '/assets/images/visualization_type_lg/table-sparkline-lg.svg',
  },
  map_pin_point: {
    value: 'map_pin_point',
    label: 'Map Pin Point',
    imageLg: './assets/images/visualization_type_icon/map-pin-point.svg',
  },
  map_density: {
    value: 'map_density',
    label: 'Density Map',
    imageLg: './assets/images/visualization_type_icon/map-density.svg',
  },
  numerical_filter: {
    value: 'numerical_filter',
    label: 'Numerical Filter',
    imageLg: '/assets/images/visualization_type_lg/filter_lg.svg',
  },
};

const visual_type: Array<VisualObject> = [
  {
    value: 'dist_bar',
    label: 'Bar Chart',
    image: './assets/images/visualization_type_icon/bar.svg',
    display: 'block',
  },
  {
    value: 'histogram',
    label: 'Histogram',
    image: './assets/images/visualization_type_icon/histogram.svg',
    display: 'block',
  },
  {
    value: 'box_plot',
    label: 'Box Plot',
    image: './assets/images/visualization_type_icon/box.svg',
    display: 'none',
  },
  {
    value: 'bubble',
    label: 'Bubble Chart',
    image: './assets/images/visualization_type_icon/bubble.svg',
    display: 'block',
  },
  {
    value: 'line',
    label: 'Line Chart',
    image: './assets/images/visualization_type_icon/dualline.svg',
    display: 'block',
  },
  {
    value: 'filter_box',
    label: 'Filter Box',
    image: './assets/images/visualization_type_icon/filter.svg',
    display: 'block',
  },
  {
    value: 'directed_force',
    label: 'Force-Directed Graph',
    image: './assets/images/visualization_type_icon/graph.svg',
    display: 'block',
  },
  {
    value: 'time_table',
    label: 'Time Series Table',
    image: './assets/images/visualization_type_icon/linetable.svg',
    display: 'none',
  },
  {
    value: 'country_map',
    label: 'Country Map',
    image: '/assets/images/visualization_type_icon/map.svg',
    display: 'block',
  },

  {
    value: 'markup',
    label: 'Markup',
    image: './assets/images/visualization_type_icon/markup.svg',
    display: 'block',
    imageLg: '/assets/images/visualization_type_lg/markup_lg.svg',
  },
  {
    value: 'mix',
    label: 'Mix Chart',
    image: './assets/images/visualization_type_icon/mix.svg',
    display: 'none',
  },
  {
    value: 'gauge',
    label: 'Gauge Chart',
    image: './assets/images/visualization_type_icon/gauge.svg',
    display: 'display',
  },
  {
    value: 'big_number_total',
    label: 'Big Number',
    image: './assets/images/visualization_type_icon/number.svg',
    display: 'block',
  },
  {
    value: 'pie',
    label: 'Pie Chart',
    image: './assets/images/visualization_type_icon/pie.svg',
    display: 'block',
  },
  {
    value: 'scatter',
    label: 'Scatter Plot',
    image: './assets/images/visualization_type_icon/scatter.svg',
    display: 'none',
  },
  {
    value: 'area',
    label: 'Area',
    image: './assets/images/visualization_type_icon/area.svg',
    display: 'none',
  },
  {
    value: 'heatmap',
    label: 'Heatmap',
    image: './assets/images/visualization_type_icon/heatmap.svg',
    display: 'block',
  },
  {
    value: 'treemap',
    label: 'Treemap',
    image: './assets/images/visualization_type_icon/treemap.svg',
    display: 'block',
  },
  {
    value: 'sunburst',
    label: 'Sunburst',
    image: './assets/images/visualization_type_icon/sunburst.svg',
    display: 'block',
  },
  {
    value: 'table',
    label: 'Table',
    image: './assets/images/visualization_type_icon/table.svg',
    display: 'block',
  },
  {
    value: 'word_cloud',
    label: 'Word Cloud',
    image: './assets/images/visualization_type_icon/word.svg',
    display: 'block',
    imageLg: '/assets/images/visualization_type_lg/word_lg.svg',
  },
  {
    value: 'table_comparison',
    label: 'Comparison Table',
    image: './assets/images/visualization_type_icon/table-comparison.svg',
    display: 'block',
  },
  {
    value: 'sparkline',
    label: 'Sparkline Big Number',
    image: './assets/images/visualization_type_icon/spark-line.svg',
    display: 'block',
  },
  {
    value: 'sparkline_table',
    label: 'Sparkline Table',
    image: './assets/images/visualization_type_icon/sparkline-table.svg',
    display: 'block',
  },
  {
    value: 'map_pin_point',
    label: 'Map Pin Point',
    image: './assets/images/visualization_type_icon/map-pin-point.svg',
    display: 'block',
  },
  {
    value: 'map_density',
    label: 'Density Map',
    image: './assets/images/visualization_type_icon/map-density.svg',
    display: 'block',
  },
  {
    value: 'numerical_filter',
    label: 'Numerical Filter',
    image: './assets/images/visualization_type_icon/filter.svg',
    display: 'block',
  },
];

const visual_type_id: VisualType = {
  visual_type: visual_type,
  visual_type_chart_list: visual_type_list,
};

export default visual_type_id;
