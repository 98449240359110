import { AggregateCmd, AggregateCmdState } from './aggregate.model';
import { CmdIface, CmdTypes } from './command.model';
import { ConfigCmd, ConfigCmdState } from './config.model';
import { ChildConnectorIface, ParentConnectorIface } from './connection.model';
import { CopyCmd, CopyCmdState } from './copy.model';
import { CorrelateRefCmd, CorrelateRefCmdState } from './correlateRef.model';
import { CreateIndexCmd, CreateIndexCmdState } from './create-index.model';
import { DedupCmd, DedupCmdState } from './dedup.model';
import { DropCmd, DropCmdState } from './drop.model';
import { ExplodeCmd, ExplodeCmdState } from './explode.model';
import { ExtractCmd, ExtractCmdState } from './extract.model';
import { FilterCmd, FilterCmdState } from './filter.model';
import { ImplodeCmd, ImplodeCmdState } from './implode.model';
import { LimitCmd, LimitCmdState } from './limit.model';
import { MergeCmd, MergeCmdState } from './merge.model';
import { SlotName } from './query.model';
import { SearchCmd, SearchCmdState } from './search.model';
import { SelectDbCmd, SelectDbCmdState } from './select-db.model';
import { SelectCmd, SelectCmdState } from './select.model';
import { SortCmd, SortCmdState } from './sort.model';
import { StoreCmd, StoreCmdState } from './store.model';
import { ViewCmd, ViewCmdState } from './view.model';

const primaryCmd: { [key: string]: null } = {
  SEARCH: null,
  CONFIG: null,
  SELECTDB: null,
};

interface CmdState {
  uuid: string;
  coordinate: { x: Number; y: Number };
  node_type: CmdTypes;
  props: any;

  fromSrcMap?: { [key: string]: Number };
  prevFromTable?: boolean;
  prevWhere?: boolean;
}

export interface WorkspaceState {
  cmds: CmdState[];
  connections: { [key: string]: string[][] };
}

export class Workspace {
  cmdMap: { [key: string]: CmdIface };
  queryString: string;

  constructor(wsState?: WorkspaceState) {
    this.cmdMap = {};
    if (wsState) {
      this.loadCmdFromState(wsState.cmds);

      for (const id in wsState.connections) {
        let cmd = this.cmdMap[id];
        for (let i = 0; i < wsState.connections[id].length; i++) {
          let connector = cmd.GetOutConnector(wsState.connections[id][i][0] as SlotName, wsState.connections[id][i][1]);
          let childCmd = this.cmdMap[wsState.connections[id][i][2]];
          let childConnector = childCmd.GetInConnector(
            wsState.connections[id][i][3] as SlotName,
            wsState.connections[id][i][4]
          );
          connector.Connect(childConnector);
        }
      }
    }
  }

  LoadFromPayload(data: { nodes: { [key: string]: any }; model: Object[] }) {
    this.cmdMap = {};
    let cmds: CmdState[] = [];
    for (const id in data.nodes) {
      const cmd: CmdState = {
        uuid: id,
        coordinate: data.nodes[id].coordinate,
        node_type: data.nodes[id].node_type as CmdTypes,
        props: data.nodes[id].props,
      };
      cmds.push(cmd);
    }
    this.loadCmdFromState(cmds);

    const models = data.model;
    let modelsMap = {};
    for (let i = 0; i < models.length; i++) {
      modelsMap[models[i]['id']] = models[i];
    }

    for (let i = 0; i < models.length; i++) {
      const model = models[i];
      if ('out' in model) {
        const node = this.cmdMap[model['id']];
        const isMultipleOut = (node.NodeType() == 'SELECT' && node.GetProps()['where']) || node.NodeType() == 'COPY' || node.NodeType() == 'FILTER' || node.NodeType() == 'CORRELATEREF';
        const outArr = model['out'] as Array<string | null>;
        for (let j = 0; j < outArr.length; j++) {
          if (outArr[j] === null) {
            continue;
          }

          const outIds = outArr[j].split('#');
          let outSlotName: SlotName = 'OUTPUT1';
          if (isMultipleOut && j == outArr.length - 1) {
            outSlotName = 'OUTPUT2';
          }

          const childNode = this.cmdMap[outIds[0]];
          const childModel = modelsMap[outIds[0]];
          const inArr = childModel['in'] as Array<string | null>;
          const inIDs = inArr[1]?.split('#');
          let inSlotName: SlotName = 'INPUT1';
          if (childNode.NodeType() === 'CORRELATEREF' && inIDs[0] === model['id']) {
            inSlotName = 'INPUT2';
          }

          node.GetOutConnector(outSlotName, outIds[1]).Connect(childNode.GetInConnector(inSlotName, outIds[1]));
        }
      }
    }
  }

  private loadCmdFromState(cmds: CmdState[]) {
    if (!cmds) return;
    
    for (let i = 0; i < cmds.length; i++) {
      let cmd: CmdIface;
      switch (cmds[i].node_type) {
        case 'SEARCH':
          let searchState: SearchCmdState = {
            node_type: cmds[i].node_type,
            props: cmds[i].props,
          };
          cmd = new SearchCmd(searchState);
          break;
        case 'SELECT':
          let selectState: SelectCmdState = {
            node_type: cmds[i].node_type,
            props: cmds[i].props,
            prevFromTable: !!cmds[i].prevFromTable,
            fromSrcMap: cmds[i].fromSrcMap ? cmds[i].fromSrcMap : {},
            prevWhere: !!cmds[i].prevWhere,
          };
          cmd = new SelectCmd(selectState);
          break;
        case 'SELECTDB':
          let selectDbState: SelectDbCmdState = {
            node_type: cmds[i].node_type,
            props: cmds[i].props,
          };
          cmd = new SelectDbCmd(selectDbState);
          break;
        case 'VIEW':
          let viewState: ViewCmdState = {
            node_type: cmds[i].node_type,
            props: cmds[i].props,
          };
          cmd = new ViewCmd(viewState);
          break;
        case 'EXTRACT':
          let extractState: ExtractCmdState = {
            node_type: cmds[i].node_type,
            props: cmds[i].props,
          };
          cmd = new ExtractCmd(extractState);
          break;
        case 'AGGREGATE':
          let aggregateState: AggregateCmdState = {
            node_type: cmds[i].node_type,
            props: cmds[i].props,
          };
          cmd = new AggregateCmd(aggregateState);
          break;
        case 'FILTER':
          let filterState: FilterCmdState = {
            node_type: cmds[i].node_type,
            props: cmds[i].props,
          };
          cmd = new FilterCmd(filterState);
          break;
        case 'SORT':
          let sortState: SortCmdState = {
            node_type: cmds[i].node_type,
            props: cmds[i].props,
          };
          cmd = new SortCmd(sortState);
          break;
        case 'LIMIT':
          let limitState: LimitCmdState = {
            node_type: cmds[i].node_type,
            props: cmds[i].props,
          };
          cmd = new LimitCmd(limitState);
          break;
        case 'COPY':
          let copyState: CopyCmdState = {
            node_type: cmds[i].node_type,
            props: cmds[i].props,
          };
          cmd = new CopyCmd(copyState);
          break;
        case 'CREATEINDEX':
          let createIndexState: CreateIndexCmdState = {
            node_type: cmds[i].node_type,
            props: cmds[i].props,
          };
          cmd = new CreateIndexCmd(createIndexState);
          break;
        case 'CORRELATEREF':
          let corrState: CorrelateRefCmdState = {
            node_type: cmds[i].node_type,
            props: cmds[i].props,
          };
          cmd = new CorrelateRefCmd(corrState);
          break;
        case 'DEDUP':
          let dedupState: DedupCmdState = {
            node_type: cmds[i].node_type,
            props: cmds[i].props,
          };
          cmd = new DedupCmd(dedupState);
          break;
        case 'EXPLODE':
          let explodeState: ExplodeCmdState = {
            node_type: cmds[i].node_type,
            props: cmds[i].props,
          };
          cmd = new ExplodeCmd(explodeState);
          break;
        case 'IMPLODE':
          let implodeState: ImplodeCmdState = {
            node_type: cmds[i].node_type,
            props: cmds[i].props,
          };
          cmd = new ImplodeCmd(implodeState);
          break;
        case 'MERGE':
          let mergeState: MergeCmdState = {
            node_type: cmds[i].node_type,
            props: cmds[i].props,
          };
          cmd = new MergeCmd(mergeState);
          break;
        case 'STORE':
          let storeState: StoreCmdState = {
            node_type: cmds[i].node_type,
            props: cmds[i].props,
          };
          cmd = new StoreCmd(storeState);
          break;
        case 'DROP':
          let dropState: DropCmdState = {
            node_type: cmds[i].node_type,
            props: cmds[i].props,
          };
          cmd = new DropCmd(dropState);
          break;
        case 'CONFIG':
          let configState: ConfigCmdState = {
            node_type: cmds[i].node_type,
            props: cmds[i].props,
          };
          cmd = new ConfigCmd(configState);
          break;
      }

      cmd.SetID(cmds[i].uuid);
      if (
        (cmds[i].coordinate?.x || cmds[i].coordinate?.x === 0) &&
        (cmds[i].coordinate?.y || cmds[i].coordinate?.y === 0)
      ) {
        cmd.SetCoordinate(cmds[i].coordinate?.x, cmds[i].coordinate?.y);
      }
      this.AddCmd(cmds[i].uuid, cmd);
    }
  }

  AddCmd(ID: string, cmd: CmdIface) {
    this.cmdMap[ID] = cmd;
  }

  GetCmdByID(ID: string): CmdIface {
    return this.cmdMap[ID];
  }

  RemoveCmd(ID: string) {
    if (this.cmdMap.hasOwnProperty(ID)) {
      this.cmdMap[ID].OnCmdDelete();
    }

    delete this.cmdMap[ID];
  }

  RemoveCmdAll() {
    for (let k in this.cmdMap) {
      this.RemoveCmd(k);
    }
  }

  NewSearchCmd(state?: SearchCmdState): SearchCmd {
    const cmd = new SearchCmd(state);
    this.AddCmd(cmd.GetID(), cmd);
    return cmd;
  }

  NewSelectCmd(state?: SelectCmdState): SelectCmd {
    const cmd = new SelectCmd(state);
    this.AddCmd(cmd.GetID(), cmd);
    return cmd;
  }

  NewViewCmd(state?: ViewCmdState): ViewCmd {
    const cmd = new ViewCmd(state);
    this.AddCmd(cmd.GetID(), cmd);
    return cmd;
  }

  NewExtractCmd(state?: ExtractCmdState): ExtractCmd {
    const cmd = new ExtractCmd(state);
    this.AddCmd(cmd.GetID(), cmd);
    return cmd;
  }

  NewSelectDbCmd(state?: SelectDbCmdState): SelectDbCmd {
    const cmd = new SelectDbCmd(state);
    this.AddCmd(cmd.GetID(), cmd);
    return cmd;
  }

  NewAggregateCmd(state?: AggregateCmdState): AggregateCmd {
    const cmd = new AggregateCmd(state);
    this.AddCmd(cmd.GetID(), cmd);
    return cmd;
  }

  NewFilterCmd(state?: FilterCmdState): FilterCmd {
    const cmd = new FilterCmd(state);
    this.AddCmd(cmd.GetID(), cmd);
    return cmd;
  }

  NewSortCmd(state?: SortCmdState): SortCmd {
    const cmd = new SortCmd(state);
    this.AddCmd(cmd.GetID(), cmd);
    return cmd;
  }

  NewLimitCmd(state?: LimitCmdState): LimitCmd {
    const cmd = new LimitCmd(state);
    this.AddCmd(cmd.GetID(), cmd);
    return cmd;
  }

  NewCreateindexCmd(state?: CreateIndexCmdState): CreateIndexCmd {
    const cmd = new CreateIndexCmd(state);
    this.AddCmd(cmd.GetID(), cmd);
    return cmd;
  }

  NewDedupCmd(state?: DedupCmdState): DedupCmd {
    const cmd = new DedupCmd(state);
    this.AddCmd(cmd.GetID(), cmd);
    return cmd;
  }

  NewExplodeCmd(state?: ExplodeCmdState): ExplodeCmd {
    const cmd = new ExplodeCmd(state);
    this.AddCmd(cmd.GetID(), cmd);
    return cmd;
  }

  NewImplodeCmd(state?: ImplodeCmdState): ImplodeCmd {
    const cmd = new ImplodeCmd(state);
    this.AddCmd(cmd.GetID(), cmd);
    return cmd;
  }

  NewMergeCmd(state?: MergeCmdState): MergeCmd {
    const cmd = new MergeCmd(state);
    this.AddCmd(cmd.GetID(), cmd);
    return cmd;
  }

  NewStoreCmd(state?: StoreCmdState): StoreCmd {
    const cmd = new StoreCmd(state);
    this.AddCmd(cmd.GetID(), cmd);
    return cmd;
  }

  NewCopyCmd(state?: CopyCmdState): CopyCmd {
    const cmd = new CopyCmd(state);
    this.AddCmd(cmd.GetID(), cmd);
    return cmd;
  }

  NewDropCmd(state?: DropCmdState): DropCmd {
    const cmd = new DropCmd(state);
    this.AddCmd(cmd.GetID(), cmd);
    return cmd;
  }

  NewCorrelateRefCmd(state?: CorrelateRefCmdState): CorrelateRefCmd {
    const cmd = new CorrelateRefCmd(state);
    this.AddCmd(cmd.GetID(), cmd);
    return cmd;
  }

  NewConfigCmd(state?: ConfigCmdState): ConfigCmd {
    const cmd = new ConfigCmd(state);
    this.AddCmd(cmd.GetID(), cmd);
    return cmd;
  }

  GetPayload(): { nodes: { [key: string]: any }; model: Object[] } {
    let nodes: { [key: string]: any } = {};
    let model: Object[] = [];
    let stateMap: {} = {};
    let primerCmd: CmdIface[] = [];

    for (const id in this.cmdMap) {
      if (isPrimaryCmd(this.cmdMap[id])) {
        primerCmd.push(this.cmdMap[id]);
      }
    }

    primerCmd.forEach(node => {
      this.visitNode(nodes, model, node, stateMap);
    });

    return { nodes: nodes, model: model };
  }

  visitNode(nodes: { [key: string]: any }, model: Object[], node: CmdIface, stateMap: {}) {
    nodes[node.GetID()] = {
      node_type: node.NodeType(),
      props: node.GetProps(),
    };

    let nodeModel = {
      id: node.GetID(),
    };

    if (!(node.GetID() in stateMap)) {
      model.push(nodeModel);
      stateMap[node.GetID()] = model.length - 1;
    } else {
      model[stateMap[node.GetID()]] = nodeModel;
    }

    for (let i in node.Slots) {
      if (node.Slots[i].type == 'IN') {
        if (!('in' in nodeModel)) {
          nodeModel['in'] = [];
        }

        let inLabels: string[] = [];
        if (node.NodeType() == 'MERGE') {
          const mergeNode: MergeCmd = node as MergeCmd;
          inLabels = mergeNode.getFromConnectorLabels();
        } else if (node.NodeType() == 'SELECT' && node.GetProps()['from_type'] === 'TABLE') {
          const selectNode: SelectCmd = node as SelectCmd;
          inLabels = selectNode.getFromConnectorLabels();
        } else {
          inLabels.push(null);
        }

        for (let x = 0; x < inLabels.length; x++) {
          const connector = node.GetInConnector(node.Slots[i].name, inLabels[x]);
          if (connector && connector.IsConnected()) {
            const parentID = connector.GetParentInfo().GetID();
            if (!(parentID in stateMap)) {
              break;
            }
            if (inLabels[x]) {
              nodeModel['in'].push(parentID + '#' + inLabels[x]);
            } else {
              nodeModel['in'].push(parentID);
            }
          } else {
            nodeModel['in'].push(null);
          }
        }

        continue;
      }

      if (!('out' in nodeModel)) {
        nodeModel['out'] = [];
      }

      let outLabels: string[] = [];
      if (node.NodeType() === 'SELECT' && node.GetProps()['from_type'] === 'TABLE' && node.Slots[i].name === 'OUTPUT1') {
        const selectNode: SelectCmd = node as SelectCmd;
        outLabels = selectNode.getFromConnectorLabels();
      } else {
        outLabels.push(null);
      }

      for (let x = 0; x < outLabels.length; x++) {
        const connector = node.GetOutConnector(node.Slots[i].name, outLabels[x]);
        if (connector && !connector.IsConnected()) {
          nodeModel['out'].push(null);
          continue;
        }

        if (connector) {
          if (outLabels[x]) {
            nodeModel['out'].push(connector.GetChildInfo().GetID() + '#' + outLabels[x]);
          } else {
            nodeModel['out'].push(connector.GetChildInfo().GetID());
          }
          const childNode = this.cmdMap[connector.GetChildInfo().GetID()];
          this.visitNode(nodes, model, childNode, stateMap);
        }
      }
    }
  }

  GenerateState(): WorkspaceState {
    let cmds = [];
    let connections = {};
    for (const id in this.cmdMap) {
      let cmd: CmdState = {
        uuid: this.cmdMap[id].GetID(),
        coordinate: this.cmdMap[id].GetCoordinate(),
        node_type: this.cmdMap[id].NodeType(),
        props: this.cmdMap[id].GetProps(),
      };

      if (this.cmdMap[id].NodeType() == 'SELECT') {
        let cmdItem = this.cmdMap[id] as SelectCmd;
        cmd.fromSrcMap = cmdItem.state.fromSrcMap;
      }

      let conns: string[][] = [];
      for (let i in this.cmdMap[id].Slots) {
        if (this.cmdMap[id].Slots[i].type == 'OUT') {
          let outLabels: string[] = [];
          if (this.cmdMap[id].NodeType() == 'SELECT' && this.cmdMap[id].Slots[i].name === 'OUTPUT1') {
            const selectNode: SelectCmd = this.cmdMap[id] as SelectCmd;
            if (selectNode.GetProps()['from_type'] === 'TABLE') {
              outLabels = selectNode.getFromConnectorLabels();
            } else {
              outLabels.push(selectNode.GetProps()['into']);  
            }
          } else {
            outLabels.push(null);
          }

          for (let x = 0; x < outLabels.length; x++) {
            const connector = this.cmdMap[id].GetOutConnector(this.cmdMap[id].Slots[i].name, outLabels[x]);
            if (connector.IsConnected()) {
              conns.push([
                connector.GetSlotName(),
                connector.GetLabel(),
                connector.GetChildInfo().GetID(),
                connector.GetOppSlotName(),
                connector.GetOppLabel(),
              ]);
            }
          }
        }
      }

      cmds.push(cmd);
      connections[id] = conns;
    }

    return { cmds: cmds, connections: connections };
  }
}

function isPrimaryCmd(cmd: CmdIface): boolean {
  if (cmd.NodeType() === 'SELECT') {
    let selectCmd = cmd as SelectCmd;
    return selectCmd.GetProps().from_type !== 'TABLE';
  }
  return cmd.NodeType() in primaryCmd;
}
