import { Injectable, QueryList } from "@angular/core";

import { CommandItemComponent } from "../../../../components/workspaces/data-processing-workspace/components/command-item/command-item.component";
import { ChildConnectorIface, ParentConnectorIface } from "../../../models/query/connection.model";
import { SelectCmd } from "../../../models/query/select.model";
import { BehaviorSubject } from "rxjs";
import { CmdBasicInfoIface } from "src/app/libs/models/query/command.model";
import { InSlotObject, LineObj, OutSlotObject } from "src/app/libs/types/dataprocessing";

declare var LeaderLine: any;
declare var window: any;

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

@Injectable({
  providedIn: 'root'
})
export class DataProcessingLineService {
  private _commandItems: QueryList<CommandItemComponent>;
  private _lines: LineObj = {};
  private _newLineKeySubject$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  public getNewLineKeySubject(): BehaviorSubject<string> {
    return this._newLineKeySubject$;
  }

  public getCmdItemIdAndSlot(key: string) {
    const lineObj = this._lines[key];
    const cmdOut = lineObj.outSlot.connector.GetInfo() as CmdBasicInfoIface;
    return {
      cmdItemKey: cmdOut.GetID(),
      slot: cmdOut.Slots[lineObj.outSlot.connector.GetSlotName()],
    };
  }

  public setCommandItems(_commandItems: QueryList<CommandItemComponent>) {
    this._commandItems = _commandItems;
  }

  public async redrawLine() {
    const _commandItemsKeyValues: { [key: string]: CommandItemComponent } = {};
    this._commandItems
      .toArray()
      .reverse()
      .forEach((_cmdItem: CommandItemComponent) => {
        _commandItemsKeyValues[_cmdItem.cmdItem.GetID()] = _cmdItem;
      });

    if (this._lines && Object.keys(this._lines).length > 0) {
      for (const lineKey in this._lines) {
        this._lines[lineKey].lineObj.remove();
      }

      this._lines = {};
    }

    let outSlotObj: OutSlotObject = null;
    let inSlotObj: InSlotObject = null;

    for (const cmdId in _commandItemsKeyValues) {
      const _commandItem: CommandItemComponent = _commandItemsKeyValues[cmdId];

      await new Promise<void>(async (resolve) => {
        if (_commandItem.cmdItem.NodeType() === 'SELECT' && _commandItem.cmdItem.GetProps()['from_type'] === 'TABLE') {
          const labels = (_commandItem.cmdItem as SelectCmd).getFromConnectorLabels();
          for (let i = 0; i < labels.length; i++) {
            const outConnector1: ParentConnectorIface = _commandItem.cmdItem.GetOutConnector('OUTPUT1', labels[i]);
            if (outConnector1 && outConnector1.GetOppositeInfo()) {
              const slotObj = _commandItem.slots.find((_slot) => _slot.slot.name === 'OUTPUT1');
              outSlotObj = {
                id: `${_commandItem.cmdItem.GetID()}-OUTPUT1`,
                connector: outConnector1,
                objNative: slotObj.theSlot.nativeElement,
              };

              const oppositeCmdItem: CommandItemComponent = _commandItemsKeyValues[outConnector1.GetOppositeInfo().GetID()];

              const inConnector1: ChildConnectorIface = oppositeCmdItem.cmdItem.GetInConnector('INPUT1');
              if (inConnector1) {
                const slotObj = oppositeCmdItem.slots.find((_slot) => _slot.slot.name === 'INPUT1');
                inSlotObj = {
                  id: `${oppositeCmdItem.cmdItem.GetID()}-INPUT1`,
                  connector: inConnector1,
                  objNative: slotObj.theSlot.nativeElement,
                };
              }

              const inConnector2: ChildConnectorIface = oppositeCmdItem.cmdItem.GetInConnector('INPUT2');
              if (inConnector2) {
                const slotObj = oppositeCmdItem.slots.find((_slot) => _slot.slot.name === 'INPUT2');
                inSlotObj = {
                  id: `${oppositeCmdItem.cmdItem.GetID()}-INPUT2`,
                  connector: inConnector2,
                  objNative: slotObj.theSlot.nativeElement,
                };
              }
            }

            if (outSlotObj && inSlotObj) {
              const lineObj = this.drawLine(outSlotObj, inSlotObj);
              this.addLine(`${outSlotObj.id}-${labels[i]}`, outSlotObj, inSlotObj, lineObj);

              inSlotObj = null;
              outSlotObj = null;
            }
          }
        } else {
          const outConnector1: ParentConnectorIface = _commandItem.cmdItem.GetOutConnector('OUTPUT1');
          if (outConnector1 && outConnector1.IsConnected()) {
            const slotObj = _commandItem.slots.find((_slot) => _slot.slot.name === 'OUTPUT1');
            outSlotObj = {
              id: `${_commandItem.cmdItem.GetID()}-OUTPUT1`,
              connector: outConnector1,
              objNative: slotObj.theSlot.nativeElement,
            };

            let oppositeCmdItemLabel = null;
            const oppositeCmdItem: CommandItemComponent = _commandItemsKeyValues[outConnector1.GetOppositeInfo().GetID()];
            const _props = _commandItem.cmdItem.GetProps();
            oppositeCmdItemLabel = _props.label ? _props.label : _props.into;

            const inConnector1 = oppositeCmdItem.cmdItem.GetInConnector('INPUT1', oppositeCmdItemLabel);
              if (inConnector1 && inConnector1.GetParentInfo().GetID() === _commandItem.cmdItem.GetID()) {
              const slotObj = oppositeCmdItem.slots.find((_slot) => _slot.slot.name === 'INPUT1');
              inSlotObj = {
                id: `${oppositeCmdItem.cmdItem.GetID()}-INPUT1`,
                connector: inConnector1,
                objNative: slotObj.theSlot.nativeElement,
              };
            }

            const inConnector2: ChildConnectorIface = oppositeCmdItem.cmdItem.GetInConnector('INPUT2', oppositeCmdItemLabel);
            if (inConnector2 && inConnector2.IsConnected() && inConnector2.GetParentInfo().GetID() === _commandItem.cmdItem.GetID()) {
              const slotObj = oppositeCmdItem.slots.find((_slot) => _slot.slot.name === 'INPUT2');
              inSlotObj = {
                id: `${oppositeCmdItem.cmdItem.GetID()}-INPUT2`,
                connector: inConnector2,
                objNative: slotObj.theSlot.nativeElement,
              };
            }
          }

          if (outSlotObj && inSlotObj) {
            const lineObj = this.drawLine(outSlotObj, inSlotObj);
            this.addLine(outSlotObj.id, outSlotObj, inSlotObj, lineObj);

            inSlotObj = null;
            outSlotObj = null;

            await sleep(10);

            try {
              lineObj.position();
            } catch (er: any) {}
          }
        }

        const outConnector2: ParentConnectorIface = _commandItem.cmdItem.GetOutConnector('OUTPUT2');
      if (outConnector2 && outConnector2.IsConnected() && outConnector2.GetSlotName() === 'OUTPUT2') {
          const slotObj = _commandItem.slots.find((_slot) => _slot.slot.name === 'OUTPUT2');
          outSlotObj = {
            id: `${_commandItem.cmdItem.GetID()}-OUTPUT2`,
            connector: outConnector2,
            objNative: slotObj.theSlot.nativeElement,
          };

          let oppositeCmdItemLabel = null;
          const oppositeCmdItem = _commandItemsKeyValues[outConnector2.GetOppositeInfo().GetID()];
          const _props = _commandItem.cmdItem.GetProps();
          oppositeCmdItemLabel = _props.label ? _props.label : _props.into;

          const inConnector1 = oppositeCmdItem.cmdItem.GetInConnector('INPUT1', oppositeCmdItemLabel);
        if (inConnector1 && inConnector1.GetParentInfo()?.GetID() === _commandItem.cmdItem?.GetID()) {
            const slotObj = oppositeCmdItem.slots.find((_slot) => _slot.slot.name === 'INPUT1');
            inSlotObj = {
              id: `${oppositeCmdItem.cmdItem.GetID()}-INPUT1`,
              connector: inConnector1,
              objNative: slotObj.theSlot.nativeElement,
            };
          }

          const inConnector2 = oppositeCmdItem.cmdItem.GetInConnector('INPUT2', oppositeCmdItemLabel);
          if (inConnector2 && inConnector2.GetParentInfo().GetID() === _commandItem.cmdItem.GetID()) {
            const slotObj = oppositeCmdItem.slots.find((_slot) => _slot.slot.name === 'INPUT2');
            inSlotObj = {
              id: `${oppositeCmdItem.cmdItem.GetID()}-INPUT2`,
              connector: inConnector2,
              objNative: slotObj.theSlot.nativeElement,
            };
          }
        }

        if (outSlotObj && inSlotObj) {
          const lineObj = this.drawLine(outSlotObj, inSlotObj);
          this.addLine(outSlotObj.id, outSlotObj, inSlotObj, lineObj);

          inSlotObj = null;
          outSlotObj = null;

          await sleep(10);

          try {
            lineObj.position();
          } catch (er: any) {}
        }

        resolve();
      });
    }
  }

  public drawLine(outSlot: any, inSlot: any) {
    let label = `${outSlot.connector && outSlot.connector.GetIntoLabel()}`;

    let lineObj = null;
    try {
      lineObj = new LeaderLine(outSlot.objNative, inSlot.objNative, {
        path: 'fluid',
        color: '#e8e8e8',
        size: 2,
        middleLabel: LeaderLine.pathLabel(label, {
          fontSize: 8,
        }),
        endPlugOutline: false,
        endPlugSize: 1,
        endPlug: 'arrow2',
        positionByWindowResize: false,
      });
    } catch (er: any) {}

    return lineObj;
  }

  public addLine(key: string, outSlot: OutSlotObject, inSlot: InSlotObject, lineObj: any) {
    this._lines[key] = {
      outSlot: outSlot,
      inSlot: inSlot,
      lineObj,
    };
    this._newLineKeySubject$.next(key);
  }

  public removeLine(id: string) {
    for (const lineKey in this._lines) {
      if (this._lines[lineKey].outSlot.connector.GetID() === id) {
        try {
          this._lines[lineKey].lineObj.remove();
        } catch (er: any) {}
        delete this._lines[lineKey];
      }
    }
  }

  public removeAllLines() {
    for (const lineKey in this._lines) {
      try {
        this._lines[lineKey].lineObj.remove();
      } catch (er: any) {}
      delete this._lines[lineKey];
    }
  }

  public updateAllLinePosition() {
    for (const lineKey in this._lines) {
      try {
        this._lines[lineKey].lineObj.position();
      } catch (er: any) {}
    }
  }
}
