import { Response } from './response';
import {TreeviewItem} from 'ngx-treeview';
import { RootType } from 'src/app/libs/consts';

// will be completed later for file type
enum FileType {
  Folder = 'folder',
  Unsupported = 'unsupported',
  CSV = 'csv',
  GZ = 'gz',
  GZIP = 'gzip',
  CSV_GZ = 'csv gz',
  CSV_GZIP = 'csv gzip',
  JSON = 'json',
  DbConnection = 'db-connection',
  Schema = 'schema',
  Table = 'table',
  Column = 'column',
  Parquet = 'parquet',
}

enum DatabaseType {
  Oracle = 'ORACLE',
  MySQL = 'MYSQL',
  Postgres = 'POSTGRES',
  MSSql = 'MSSQL',
  ClickHouse = 'CLICKHOUSE',
  DB2 = 'DB2',
}

interface DatabaseValue {
  database?: string;
  driver: string;
  dsn: string;
  host: string;
  password: string;
  port: number;
  query_string?: { [key: string]: string[] };
  username: string;
  sid?: string;
}

interface DatabaseConnection {
  created_at?: string;
  key: string;
  name: string;
  owner?: string;
  type: DatabaseType;
  updated_at?: string;
  value: DatabaseValue;
  filetype: FileType;
}

interface SchemaTables {
  schema: string;
  tables: string[];
}

interface Column {
  column_name: string;
  data_type: string;
}

interface HDFSConnection {
  id: string;
  name: string;
  node_name: string;
  port: number;
  token_string: string;
  timestamp: string;
  isDir: boolean;
  roottype: RootType.HDFS;
  testConnection: "success" | "failed";  
}

interface AWSConnection {
  id: string;
  name: string;
  bucket_name: string;
  secret_access_key: string;
  access_key_id: string;
  region_name: string;
  timestamp: string;
  isDir: boolean;
  roottype: RootType.AWS;
  testConnection: "success" | "failed";  
}

export interface Item {
  name: string;
}

interface Explorer {
  id: string;
  name: string;
  location: string;
  isDir: boolean;
  filetype: FileType;
  roottype: RootType;
  connectionName: string;
}

interface TreeViewItemExplorer extends TreeviewItem{
  value: Explorer;
}
type ExplorerResponse = Response<Explorer>;
type ExplorerResponseList = Response<Explorer[]>;
type DeleteResponse = Response<boolean[]>;
type DatabaseConnectionResponse = Response<DatabaseConnection>;
type DatabaseConnectionResponseList = Response<DatabaseConnection[]>;
type SchemaTablesResponseList = Response<SchemaTables[]>;
type ColumnResponseList = Response<Column[]>;
type HDFSConnectionResponse = Response<HDFSConnection[]>;
type HDFSTestResponse = Response<null>;
type AWSConnectionResponse = Response<AWSConnection[]>;
type AWSTestResponse = Response<null>;

export {
  DatabaseType,
  Explorer,
  ExplorerResponse,
  ExplorerResponseList,
  DeleteResponse,
  FileType,
  DatabaseConnection,
  DatabaseConnectionResponse,
  DatabaseConnectionResponseList,
  SchemaTables,
  SchemaTablesResponseList,
  Column,
  ColumnResponseList,
  HDFSConnectionResponse,
  HDFSTestResponse,
  HDFSConnection,
  AWSConnectionResponse,
  AWSTestResponse,
  AWSConnection,
  TreeViewItemExplorer
};