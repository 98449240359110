import { Cmd, CmdIface, CmdTypes, CmdUISetting, IntoLabelGenerator } from './command.model';
import {
    ChildConnector,
    ChildConnectorIface,
    ParentConnector,
    ParentConnectorIface,
} from './connection.model';
import { CmdSlots, Slot, SlotName, SlotType } from './query.model';

const cmdType: CmdTypes = 'FILTER';

interface FilterCmdProps {
    condition: string;
    from: string;
    into: string;
}

interface FilterCmdState {
    node_type: CmdTypes;
    props: FilterCmdProps;
}

class FilterCmd extends Cmd implements CmdIface {
    private state: FilterCmdState;
    private fromConnector: ChildConnectorIface;
    private out1Connector: ParentConnectorIface;
    private out2Connector: ParentConnectorIface;

    constructor(state?: FilterCmdState) {
        super(cmdType);

        if (state) {
            this.state = state;
        } else {
            this.state = {
                node_type: cmdType,
                props: {
                    condition: '',
                    from: '',
                    into: IntoLabelGenerator(cmdType),
                },
            };
        } 
        super.SetNewProp(this.state.props);

        this.fromConnector = new ChildConnector(
            "INPUT1",
            "",
            (parentLabel: string) => {
                this.state.props.from = parentLabel;
                this.Slots["INPUT1"].isChecked = true;
            },
            () => {
                this.state.props.from = '';
                this.Slots["INPUT1"].isChecked = false;
            },
            (parentLabel: string) => {
                this.state.props.from = parentLabel;
                this.out1Connector.SetIntoLabel(parentLabel);
            },
            this
        );

        this.out1Connector = new ParentConnector(
            "OUTPUT1",
            "",
            () => this.state.props.from,
            (newLabel: string) => {},
            () => {
                this.Slots["OUTPUT1"].isChecked = true;
            },
            () => {
                this.Slots["OUTPUT1"].isChecked = false;
            },
            this
        );

        this.out2Connector = new ParentConnector(
            "OUTPUT2",
            "",
            () => this.state.props.into,
            (newLabel: string) => {
                this.state.props.into = newLabel;
            },
            () => {
                this.Slots["OUTPUT2"].isChecked = true;
            },
            () => {
                this.Slots["OUTPUT2"].isChecked = false;
            },
            this
        );
    }

    SetIntoLabel(newLabel: string) {
        if (this.out2Connector.IsConnected()) {
            this.out2Connector.SetIntoLabel(newLabel);
        } else {
            this.state.props.into = newLabel;
        }
    }

    GetInConnector(name: SlotName, label?: string): ChildConnectorIface {
        if (name === 'INPUT1') {
            return this.fromConnector;
        }
        return null;
    }

    GetOutConnector(name: SlotName, label?: string): ParentConnectorIface {
        if (name === 'OUTPUT1') {
            return this.out1Connector;
        } else if (name === 'OUTPUT2') {
            return this.out2Connector;
        }
        return null;
    }

    OnCmdDelete(): void {
        if (this.fromConnector.IsConnected()) {
            this.fromConnector.Disconnect();
        }

        if (this.out1Connector.IsConnected()) {
            this.out1Connector.Disconnect();
        }

        if (this.out2Connector.IsConnected()) {
            this.out2Connector.Disconnect();
        }
    }
}

export { FilterCmdState, FilterCmd };
