import { Injectable } from '@angular/core';
import { catchError, mergeMap, map, switchMap, tap, withLatestFrom, take } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { AuthenticationService, DataProcessingWorkspaceService } from 'src/app/libs/services';
import { User } from 'src/app/libs/models/common';
import {
  Login,
  LoginSucceed,
  LoginSucceedRedirect,
  LoginFailed,
  Logout,
  LogoutSucceed,
  LogoutFailed,
  LogoutSucceedRedirect,
  Captcha,
  CaptchaSucceed,
} from '../actions/authentication.actions';
import { Router } from '@angular/router';
import { ResetWorkspace } from '../actions/pds/dataprocessing.actions';
import { getURLParameter } from '../../helpers/data-visualization-helper';
import { Store, select } from '@ngrx/store';
import { AppState } from '../states';
import { ModalDialogService, DataProcessingLineService } from 'src/app/libs/services';
import { CookieService } from 'ngx-cookie-service';
import { PROGRESS_STATE } from '../../consts';
import { DataProcessingWorkspaceComponent } from 'src/app/components/workspaces/data-processing-workspace/data-processing-workspace.component';

@Injectable()
export class AuthenticationEffects {
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Login),
      switchMap((prop: any) =>
        this.authenticationService.login(prop.userCredential).pipe(
          map((result: ApiResult) => {
            const authUser = new User();
            authUser.transform(result.response.profile);

            return LoginSucceed({
              user: authUser,
              authToken: result.response.access_token,
              refreshToken: result.response.refresh_token,
            });
          }),
          catchError((error: any) => of(LoginFailed({ error: error })))
        )
      )
    )
  );

  loginSucceed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginSucceed),
      map((userInfo) => {
        this.cookieService.set('token', userInfo.authToken, { path: '/', sameSite: 'Lax' });
        this.cookieService.set('refresh_token', userInfo.refreshToken, { path: '/', sameSite: 'Lax' });
        localStorage.setItem('user', JSON.stringify(userInfo.user));
        
        if (!this.dataProcessingWorkspaceService.getActiveWorkingSessionStatus()) {
          this.modalDialogService.closeAllModal();
        }
        
        if (this.authenticationService.getNeedReloginSubject().getValue()) {
          this.authenticationService.resetNeedRelogin();
          this.authenticationService.updateNeedReloginStateSubject(PROGRESS_STATE.DONE);
        }
        
        return ResetWorkspace({ needToastr: false })
      })
    )
  );

  loginSucceedRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoginSucceedRedirect),
        tap(() => {
          this.router.navigate(['/home']);
        })
      ),
    { dispatch: false }
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Logout),
      mergeMap(() => {
        // console.log('Effects - Logout');
        return this.authenticationService.logout().pipe(
          catchError(() =>
            of({
              status: 'success',
            })
          )
        );
      }),
      switchMap((result: ApiResult) => {
        if (result.status === 'success') {
          return [
            LogoutSucceed(),
            // LogoutSucceedRedirect()
          ];
        }

        return [LogoutFailed()];
      })
    )
  );

  logoutSucceedRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LogoutSucceedRedirect),
        tap(() => this.handleLogoutCondition())
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private authenticationService: AuthenticationService,
    private cookieService: CookieService,
    private router: Router,
    private modalDialogService: ModalDialogService,
    private store: Store<AppState>,
    private dataProcessingLineService: DataProcessingLineService,
    private dataProcessingWorkspaceService: DataProcessingWorkspaceService,
  ) {}

  handleLogoutCondition = () => {
    this.dataProcessingLineService.removeAllLines();
    
    if (window.location.href.includes('app_preview')) {
      this.router.navigate(['/auth/application_login'], {
        queryParams: { slug: getURLParameter('link') },
      });
    } else {
      return this.router.navigateByUrl('/auth/login');
    }
  };

  captcha$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Captcha),
      mergeMap(() => this.authenticationService.getCaptcha()),
      switchMap((result: ApiResult) => {
        if (result.status === 'success') {
          // return [CaptchaSucceed(result.response), LoginSucceedRedirect()];
          return [CaptchaSucceed(result.response)];
        }

        return [LoginFailed({ error: result })];
      })
    )
  );
}
